body {
	font-family: "Open Sans","Helvetica Neue",Helvetiva,Arial,sans-serif;
	font-weight: $font-weight-regular;
	color: #333;
	font-size: 17px;
	line-height: 27px;
}

h1,
h2,
h3,
p,
table,
ul,
ol
{
	margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
{
	position: relative;
	padding-bottom: .5em;
	font-size: 16px;
	line-height: 1.1;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
}

h1
{
	padding-bottom: 1rem;
	font-size: 36px;

	&.color-green-inverted {
		text-transform: uppercase;
	}
}

h2 {
	font-size: 26px;
}

h2,
h3
{
	margin-top: 1rem;
	padding: 0 0 1rem;
	text-transform: none;
}

p
{
	padding: 0 0 1rem;
	font-size: 17px;
	line-height: 1.6rem;

	&.teaser
	{
		padding-bottom: 1.5rem;
	}

	&.left-align
	{
		margin: 0;
	}
}

div.text-large
{
	p
	{
		font-size: 22px;
	}
}

div.text-small
{
	p
	{
		font-size: 14px;
	}
}

.ce-textpic
{
	width: 100%;
	flex-wrap: wrap;
	flex-direction: column;
	& > div
	{
		float: none  !important;
		flex: 1;
	}
}

.ce-gallery + .ce-bodytext
{
	padding-top: 20px;
}

hr
{
	display: block;
	margin: 20px 0 60px;
	padding: 0;
	width: 50px;
	height: 6px;
	border: 0;
	outline: 0;
	background: $color-green;
}

strong, b
{
	font-weight: 600;
}

.align-left, .text-left {text-align:left !important;}
.align-center, .text-center {text-align:center !important;}
.align-right, .text-right {text-align:right !important;}

.color-green
{
	color: $color-green;
}

.color-blue
{
	color: $color-blue;
}

.color-green-inverted
{
	color: #FFF !important;
	padding: 10px !important;
	line-height: 1.55 !important;
	display: inline-block;
	width: auto;
	text-align: center;
	text-transform: none;

	span {
		background: $color-green !important;
		padding: 0.45rem;

		box-decoration-break: clone;
		-webkit-box-decoration-break: clone;
	}
}

.content-wrap
{
	header h1, h2, h3, p
	{
		&:last-child
		{
			//padding-bottom: 0;
		}
	}

	ol,
	ul:not([class])
	{
		margin-bottom: 1rem;
		padding-left: 2rem;
	}
}

table
{
	margin: 0 0 1.7rem;
	width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	font-size: 1.2rem;
	word-wrap: break-word;

	@include media("<tablet")
	{
		font-size: 0.8rem;
	}

	th,
	td
	{
		padding: 6px;
		border-bottom: 1px solid #000;
		vertical-align: top;
		@include media("<tablet")
		{
			padding-left: 0;
			padding-right: 0;
		}
	}

	thead
	{
		th,
		td
		{
			text-align: right;
			color: $color-cyan;
			font-weight: $font-weight-regular;

			&:first-child
			{
				font-weight: $font-weight-semibold;
				text-align: left;
				text-transform: uppercase;
			}
		}
	}
	tbody
	{
		th,
		td
		{
			text-align: right;
			font-weight: $font-weight-semibold;

			&:first-child
			{
				text-align: left;
			}
		}
	}
	tfoot
	{
		th,
		td
		{
			text-align: right;
			font-weight: $font-weight-semibold;
			border-bottom: 0;
		}
	}
}

@include media("<desktop") {
	body {
		font-size: 16px;
		line-height: 26px;
	}
}

@include media("<tablet")
{
	body ,
	p {
		font-size: 15px;
		line-height: 25px;
	}

	h1,
	h3,
	h4,
	h5,
	h6{
		font-size: 13px;
		line-height: 21px;
	}

	h2 {
		font-size: 21px;
	}
}
