/*
@font-face
{
	font-family: "Icons";
	src:url(#{$path-fonts}icomoon.eot);
	src:url(#{$path-fonts}icomoon.eot?#iefix) format('embedded-opentype'), url(#{$path-fonts}icomoon.woff) format('woff'), url(#{$path-fonts}icomoon.ttf) format('truetype'), url(#{$path-fonts}icomoon.svg#robotoicomoon) format('svg');
	font-weight: normal;
	font-style: normal;
}*/

@font-face {
	font-family: 'paricons';
	src: url('#{$path-fonts}paricons.eot?66640400');
	src: url('#{$path-fonts}paricons.eot?66640400#iefix') format('embedded-opentype'),
	url('#{$path-fonts}paricons.woff2?66640400') format('woff2'),
	url('#{$path-fonts}paricons.woff?66640400') format('woff'),
	url('#{$path-fonts}paricons.ttf?66640400') format('truetype'),
	url('#{$path-fonts}paricons.svg?66640400#paricons') format('svg');
	font-weight: normal;
	font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'paricons';
    src: url('#{$path-fonts}paricons.svg?66640400#paricons') format('svg');
  }
}
*/

[class^="icon-"]:before, [class*=" icon-"]:before {
	display: inline-block;
	position: relative;
	top: -0.083333333em;
	margin-right: 0.166666667em;

	font-family: "paricons";
	font-style: normal;
	font-weight: normal;
	speak: none;

	text-decoration: inherit;
	width: auto;
	text-align: center;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-Attention_1:before { content: '\e900'; } /* '' */
.icon-Attention_2:before { content: '\e901'; } /* '' */
.icon-Calendar:before { content: '\e902'; } /* '' */
.icon-Check_1:before { content: '\e903'; } /* '' */
.icon-Check_2:before { content: '\e904'; } /* '' */
.icon-Close:before { content: '\e905'; } /* '' */
.icon-Contact:before { content: '\e906'; } /* '' */
.icon-Down_1:before { content: '\e907'; } /* '' */
.icon-Edit:before { content: '\e908'; } /* '' */
.icon-Email:before { content: '\e909'; } /* '' */
.icon-Euro:before { content: '\e90a'; } /* '' */
.icon-Facebook:before { content: '\e90b'; } /* '' */
.icon-Filter:before { content: '\e90c'; } /* '' */
.icon-Info_1:before { content: '\e91c'; } /* '' */
.icon-Info_2:before { content: '\e91d'; } /* '' */
.icon-Instagram:before { content: '\e91e'; } /* '' */
.icon-Left_1:before { content: '\e91f'; } /* '' */
.icon-Left_2:before { content: '\e920'; } /* '' */
.icon-Location:before { content: '\e921'; } /* '' */
.icon-Lock:before { content: '\e922'; } /* '' */
.icon-LogIn:before { content: '\e923'; } /* '' */
.icon-Menu:before { content: '\e924'; } /* '' */
.icon-Message:before { content: '\e925'; } /* '' */
.icon-Person:before { content: '\e926'; } /* '' */
.icon-Phone:before { content: '\e927'; } /* '' */
.icon-Plus_1:before { content: '\e928'; } /* '' */
.icon-Plus_2:before { content: '\e929'; } /* '' */
.icon-Plus_3:before { content: '\e92a'; } /* '' */
.icon-Price:before { content: '\e92b'; } /* '' */
.icon-Radius:before { content: '\e92c'; } /* '' */
.icon-Reight_1:before { content: '\e92d'; } /* '' */
.icon-Right_2:before { content: '\e92e'; } /* '' */
.icon-Search:before { content: '\e92f'; } /* '' */
.icon-Share:before { content: '\e930'; } /* '' */
.icon-Shopping:before { content: '\e931'; } /* '' */
.icon-Sort:before { content: '\e932'; } /* '' */
.icon-Tag:before { content: '\e933'; } /* '' */
.icon-Trash:before { content: '\e934'; } /* '' */
.icon-Twitter:before { content: '\e935'; } /* '' */
.icon-Up_1:before { content: '\e936'; } /* '' */
.icon-Warning:before { content: '\e937'; } /* '' */
