@include media(">desktop")
{
	.nav__main
	{
		position: relative;
		z-index: 70;
		display: block;
		margin: 0;
		padding: 0;
		height: 110px;
		text-align: right;

		a
		{
			display: inline-block;
			padding: 0 10px;
		}

		& > ul
		{
			justify-content: flex-end;
		}

		ul
		{
			max-width: none;
		}

		li
		{
			display: inline-block;

			&.active
			{
				&>a
				{
					color: #333;
				}
			}

			&:hover
			{
				& > .container-inner
				{
					position: absolute;
					top: 120px;
					left: 0;
					right: 0;
					display: block;
					padding: 50px 0;
					background: $color-light-blue;

					&:before
					{
						position: absolute;
						top: 0;
						left: -2000px;
						right: -2000px;
						bottom: 0;
						background: $color-light-blue;
						content: "";
					}
				}
			}
			
			&.shopping
			{
				a span.description
				{
					display: none;
				}
			}
		}

		ul.l1 > li
		{
			& > a
			{
				padding: 47px 11px 60px;
				text-transform: uppercase;
			}

			&:hover
			{
				& > a
				{
					color: $color-green;
					font-weight: 900;
				}
			}

			&.active
			{
				& > a
				{
					font-weight: 900;
				}
			}
		}

		ul.l1 > li.sub
		{
			.nav__main__trigger_l2
			{
				position: relative;
				top: -4px;
				display: inline-block;

				&:after
				{
					font-family: "paricons";
					content: '\e907';
					font-size: 6px;
				}
			}
		}

		ul.l2
		{
			position: relative;
			flex-wrap: wrap;
			justify-content: flex-start;
			margin: 0 -20px;
			li
			{
				flex: 0 1 25%;
				padding: 0;
				text-align: left;
				max-width: 25%;

				.texticon,
				.svgicon
				{
					position: relative;
					overflow: hidden;
					display: flex;
					margin: 10px;
					padding: 0;

					height: 100px;

					align-content: center;
					align-items: center;
					justify-content: center;
				}

				a
				{
					position: relative;
					overflow: hidden;
					display: flex;
					margin: 10px;
					padding: 0;

					white-space: normal;

					align-content: center;
					align-items: center;
					justify-content: center;
				}

				.texticon
				{
					padding: 0 0px;
					a
					{
						border-radius: 24px;
						background: $color-green;
						color: #FFF;
						text-transform: uppercase;
						width: 100%;
						text-align: center;
						font-weight: 900;
					}
					.title-wrap
					{
						padding: 15px;

						-ms-word-break: break-all;
						word-break: break-all;

						word-break: break-word;

						-webkit-hyphens: auto;
						-moz-hyphens: auto;
						hyphens: auto;
					}
				}

				.svgicon
				{
					background: #FFF;

					.title-wrap
					{
						flex: 1 0;
						padding: 0 0 0 10px;
					}

					.icon-wrap-outer
					{
						flex: 0 1 30%;
					}

					.icon-wrap-inner
					{
						display: flex;
						width: 100%;
						height: 100%;
						align-content: center;
						justify-content: center;
					}

					svg
					{
						display: block;
						max-width: 60%;
						max-height: 60%;
						fill: $color-green;
					}

					&:hover
					{
						background: $color-green;
						box-shadow: 4px 4px 12px rgba(0, 0, 0, .27);
						color: #FFF;
						svg
						{
							fill: #CBE7B9;
						}
						a
						{
							color: #FFF;
							font-weight: 900;
						}
					}

				}
			}
		}

		.container-inner
		{
			display: none;
		}
	}
}

@include media("<desktop")
{
	.nav__main
	{
		display: none;
	}
}
