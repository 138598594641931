.nav__logo
{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 72;
	display: block;
	padding: 26px 10px;
	width: 272px;
	text-align: center;

	img
	{
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;

	}

	@include media("<tablet")
	{
		width: 166px;
		padding: 5px;
	}
}