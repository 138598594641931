.topic
{
	h2
	{
		font-size: 16px;
		font-weight: 600;
		margin-bottom: 20px;
		text-transform: none;
	}
	.topic__tiles
	{
	}
	.topic__tiles__tile
	{
		padding: 20px 10px;

		border-bottom: 1px solid #CCC;

		.row > .column:first-child
		{
			flex: 0 0 55px;
			img
			{
				position: relative;
				top: 5px;
			}
		}

		.row > .column:last-child
		{
			flex: 1 1 auto;
			padding-left: 0;
		}

		h3,
		h4,
		p
		{
			margin: 0;
			padding: 0;
			font-size: 18px;
			line-height: 27px;
		}

		h3,
		h4
		{
			font-weight: 600;
			text-transform: none;
		}
		h3
		{
			color: $color-green;
		}
		h4
		{
			color: #333;
		}
		p
		{
			font-weight: 400;
		}
	}
}