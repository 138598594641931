header#top,
header#top + .page__divider
{
	&:before
	{
		display: block;
		padding-top: 35%;
		content: '';
	}
}

header#top
{
	position: fixed;
	top: 120px;
	left: 0;
	right: 0;
	z-index: 1;
	overflow: hidden;
	background: $color-lightest-green;

	.container-inner
	{
		width: 100%;
		height: 100%;
		max-width: none;
		position: relative;
	}

	h1,
	h2,
	h3
	{
		font-size: 36px;
		font-weight: 900;
	}
}

.page__divider
{
	position: static;
	margin-top: 120px;
}

header#top + .page__divider
{
	display: block;
}

.header__inner
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
	text-align: center;

	.content-wrap
	{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		height: 100%;
		flex-direction: row;
		align-content: center;

		.frame {
			position: relative;
			width: 100%;
		}
	}
}


@include media("<tablet")
{
	header#top,
	header#top + .page__divider
	{
		&:before
		{
			padding-top: 75%;
		}
	}
	.page__divider
	{
		margin-top: 50px;
	}
	header#top
	{
		position: fixed;
		top: 50px;

		.content-wrap
		{
			padding: 0 20px;
		}

		h1,
		h2,
		h3
		{
			font-size: 21px;
		}
	}
	.header__inner .content-wrap .searchform
	{
		margin: 0;
		width: auto;
	}
}

@include media("<=tabletWide") {
	header#top {
		.frame {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}
