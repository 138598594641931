.container
{
	position: relative;
	padding: 100px 0;

	&:before
	{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		content: "";
	}

	&.bg-light-gray:before
	{
		background: $color-almost-lightest-gray;
	}
	&.bg-light-blue:before
	{
		background: $color-lightest-blue;
	}
	&.bg-light-green:before
	{
		background: $color-lightest-green;
	}

	&.padding-top-less
	{
		padding-top: 30px;

		@include media("<phoneWide")
		{
			padding-top: 15px;
		}
	}
	&.padding-top-0
	{
		padding-top: 0;
	}
	&.padding-bottom-less
	{
		padding-bottom: 30px;

		@include media("<phoneWide")
		{
			padding-bottom: 15px;
		}
	}
	&.padding-bottom-0
	{
		padding-bottom: 0;
	}
	&.padding-lr-less
	{
		padding-left: 30px;
		padding-right: 30px;


		@include media("<phoneWide")
		{
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	&.padding-lr-0
	{
		padding-left: 0;
		padding-right: 0;
	}

	&.container--text-color-white
	{
		color: $color-white;
	}
}

.container__background,
.container__background__img
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	img
	{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		@include object-fit(cover);
	}
}

.container--background
{
	color: #FFF;
}

.container__background__img
{
	&:after
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		content: "";
		background: rgba(0,0,0,.49);
	}
}

.container
{
	&.container--text-align-center:last-of-type
	{
		text-align: center;

		p,ul,ol,table
		{
			text-align: center;
		}
	}
	&.container--text-align-left:last-of-type
	{
		text-align: left;

		p,ul,ol,table
		{
			text-align: left;
		}
	}

	&.container--inner-1280px
	{
		.content-wrap {max-width: 1280px;}
	}

	&.container--inner-960px
	{
		.content-wrap {max-width: 960px;}
	}

	&.container--inner-780px
	{
		.content-wrap {max-width: 780px;}
	}
}

.container-inner
{
	max-width: 960px;
}

@include media("<desktop")
{
	.container
	{
		padding: 60px 30px;

		.container
		{
			padding-left: 0;
			padding-right: 0;
		}
	}
}
@include media("<phoneWide")
{
	.container
	{
		padding: 30px 10px;

		.container
		{
			padding-left: 0;
			padding-right: 0;
		}
	}
}
