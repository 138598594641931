.share
{
	margin: 0 auto;

	.row {
		justify-content: space-between;

		margin: 0;

		border-top: 1px solid #ccc;

		padding-top: 20px;
	}
	.sharelink,
	.backlink {
		flex-basis: auto;

		padding: 0;
	}
	.column.sharelink .link
	{
		text-align: right;
		justify-content: flex-end;
	}
	.link--icon {
		.link__icon {
			border-color: #bbb;
			fill: $color-light-gray;
		}
		.link__label
		{
			font-size: 0.875em;
			color: $color-light-gray;
		}

		&:hover,
		&:focus
		{
			cursor: pointer;

			.link__label {
				color: $color-green;
			}
			.link__icon {
				background: $color-green;
				fill: #FFF;
				border: 1px solid $color-green;
				box-shadow: 0 2px 4px rgba(0,0,0,.16);
			}
		}
	}

	&.light {
		.row {
			border-top: none;
			padding-top: 0;
		}
	}
}

.share__actions
{
	z-index: 90;
	position: absolute;
	top: -90px;
	right: 13px;
	display: none;
	padding: 5px 10px;
	width: 154px;
	background: #FFF;
	box-shadow: 6px 6px 16px rgba(0,0,0,.18);
	border-radius: 10px;
	line-height: 42px;
	text-align: center;
	white-space: nowrap;

	&:before
	{
		position: absolute;
		right: 15px;
		bottom: -5px;
		z-index: 90;
		display: block;
		width: 20px;
		height: 20px;
		background: #FFF;
		transform: rotate(45deg);
		content: "";
	}

	a.button-icon
	{
		position: relative;
		z-index: 91;
		color: #BBB;
		display: inline-block;
		width: 42px;
		height: 42px;
		border-radius: 100%;
		font-size: 14px;
		font-weight: normal;

		&:hover
		{
			color: #FFF;
			background: $color-green;
			box-shadow: 0 2px 4px rgba(0,0,0,.16);
		}
	}
}

.share input:checked + .share__actions
{
	display: block;
}
.share input:checked
{
	background: $color-green;
	border-color: $color-green;
}
.share input
{
	position: absolute;
	left: -9999px;
}

@include media("<phoneWide") {
	.share {
		.link__label {
			display: none;
		}
	}
}
