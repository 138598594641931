* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

:focus {
	//border: 0;
	outline: 0;
}
