.tooltip
{
	display: inline-block;
	position: relative;
	top: -10px;
	z-index: 10;
}
.tooltip__trigger
{
	color: #999;
}
.tooltip__content
{
	position: absolute !important;
	top: 0;
	left: 40px;
	padding: 10px;
	max-width: 200px;
	height: auto;
	transform: translateY(-50%);
	background: $color-white;
	box-shadow: 0 0 24px rgba(0,0,0,.18);
	display: none;

	&:before
	{
		position: absolute;
		left: -10px;
		top: 50%;
		z-index: -1;
		display: block;
		margin-top: -10px;
		width: 20px;
		height: 20px;
		background: $color-white;
		box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
		transform: rotate(45deg);
		content: "";
	}
}

.tooltip.active,
.tooltip:hover
{
	.tooltip__trigger
	{
		color: $color-green;
	}
	.tooltip__content
	{
		display: block;
	}
}

@include media("<tablet") {
	.tooltip__content {
		transform: translate(-50%,100%);

		top: auto;
		bottom: -10px;
		left: auto;

		&:before {
			transform: rotate(135deg);

			top: 0;
			left: 50%;
		}
	}
}
