.nav__mobile
{
	display: none;

	overflow-y: auto;
}

@include media("<desktop")
{
	.force-show-nav__mobile
	{
		.nav__mobile
		{
			display: block;
		}

		&:before
		{
			position: fixed;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 100;
			background: rgba(0,0,0,.6);
			content: "";
		}
	}

	.nav__mobile
	{
		position: fixed;
		top: 10px;
		right: 0;
		max-height: 100%;
		max-width: 50ex;
		z-index: 300;
		margin: 0;
		padding: 50px 0 0;
		text-align: left;
		background: #FFF;
		box-shadow: 6px 6px 20px rgba(0,0,0,.18);

		&:before
		{
			position: absolute;
			top: 0;
			display: block;
			width: 100%;
			height: 6px;
			content: " ";
			background: url(#{$path-images}par-layout-border-top@2x.png) left center;
			background-size: contain;
		}

		a
		{
			display: flex;
			flex-direction: row;
			align-items: center;
			padding: 11px 0;
			font-size: 13px;
			font-weight: 400;
			text-transform: uppercase;
		}

		ul
		{
			display: block;
			max-width: none;
		}

		ul.l2
		{
			display: none;
		}

		.container-inner
		{
			max-width: none;
		}

		.nav__main,
		.nav__second,
		.nav__meta,
		.footer__social,
		.footer__copyright
		{
			position: static;
			display: block;
		}

		.nav__main,
		.nav__second
		{
			display: block;
			padding: 20px;

			a
			{
				color: #333;
			}

			li.active > a
			{
				color: #333;
				font-weight: 900;
			}
			a:hover
			{
				color: $color-green;
			}

			li
			{
				display: block;
				list-style: none;
				border-bottom: 1px solid #DDD;
			}

			li.shopping
			{
				margin-top: 40px;
				border-top: 1px solid #DDD;

				i
				{
					width: 2em;
					display: inline-block;
				}
			}
		}

		.nav__main
		{
			padding-bottom: 0;
		}
		.nav__second
		{
			padding-top: 0;
		}

		.nav__main ul li:first-child
		{
			border-top: 1px solid #DDD;
		}

		.nav__second
		{
			background: transparent;

			a:before
			{
				font-family: 'paricons';
				content: '\e923 ';
				width: 2em;
				display: inline-block;
			}
		}

		.nav__meta
		{
			padding: 10px 20px 20px;
			li
			{
				display: block;
				height: auto;
				list-style: none;
				border-bottom: 1px solid #444;
			}
		}

		.nav__meta,
		.footer__social
		{
			display: block;
			background: #333;
			color: #DDD;

			a
			{
				color: #FFF;
			}
		}

		.footer__social
		{
			ul
			{
				padding-bottom: 20px;
				justify-content: flex-start;
				li
				{
					align-items: center;
				}
				li:first-child
				{
					display: none;
				}
			}

			.footer__social__icon {
				justify-content: center;
			}
			.footer__social__label {
				span {
					display: inline-block;
				}
			}
		}

		.footer__copyright
		{
			display: block;
			background: $color-green;
			text-align: left;

			a
			{
				@include media("<=tabletExtraWide") {
					padding-top: 0;
					padding-bottom: 0;
				}
				color: #FFF;
			}
		}


		ul.l1 > li.sub
		{
			position: relative;

			.nav__main__trigger_l2
			{
				position: absolute;
				top: 0;
				right: 0;
				width: 40px;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				&:after
				{
					display: inline-block;
					font-family: "paricons";
					content: '\e907';
					font-size: 6px;
				}
			}

			&.hover
			{
				& > a
				{
					font-weight: 900;
				}
				.nav__main__trigger_l2:after
				{
					transform: rotate(180deg);
				}

				& ~ li a
				{
					font-weight: 400 !important;
				}
			}
		}

		ul.l2
		{
			position: relative;

			li
			{
				.svgicon
				{
					display: block;
					overflow: hidden;
					.icon-wrap-outer,
					.title-wrap
					{
						//float: left;
						display: inline-block;
					}
					.icon-wrap-outer
					{
						width: 25px;
						margin-right: 10px;

						svg
						{
							width: 25px;
							max-height: 25px;
							fill: $color-green
						}

						.icon-wrap-inner {
							display: flex;
							flex-direction: row;
							align-items: center;
						}
					}
				}
				.texticon
				{

				}

				&:last-child
				{
					border-bottom: 0;
				}
			}
		}

		ul.l1 > li.sub.hover ul.l2
		{
			display: block;
		}
	}
}
