.slider-wrap
{
	position: relative;
	margin: 0 auto 20px;
	padding: 0;
	height: 100%;
	width: 100%;
	max-width: 960px;

	.parakm-ce-image
	{
		margin: 0 !important;
	}

	.slick-slider
	{
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;
	}

	.slick-list
	{
		position: relative;
		display: block;
		overflow: hidden;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
	}
	.slick-slide
	{
		position: relative;
		overflow: hidden;
		display: none;
		float: left;
		margin: 0 !important;
		height: 100%;
		min-height: 1px;
	}
}

.slick-list:focus
{
	outline: none;
}
.slick-list.dragging
{
	cursor: pointer;
	cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}

.slick-track
{
	position: relative;
	top: 0;
	left: 0;
	display: block;
	height: 100%;
}
.slick-track:before,
.slick-track:after
{
	display: table;

	content: '';
}
.slick-track:after
{
	clear: both;
}
.slick-loading .slick-track
{
	visibility: hidden;
}

.slick-slide.slick-loading img
{
	display: none;
}
.slick-slide.dragging img
{
	pointer-events: none;
}
.slick-initialized .slick-slide
{
	display: block;
}
.slick-loading .slick-slide
{
	visibility: hidden;
}
.slick-vertical .slick-slide
{
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}

.slick-dots
{
	position: absolute;
	bottom: 12px;
	right: 12px;
	z-index: 99;
	list-style: none !important;
	display: inline-block;
	text-align: center;
	padding: 0 !important;
	margin: 0 !important;
	li
	{
		position: relative;
		display: inline-block;
		margin: 0;
		padding: 8px 8px;
		cursor: pointer;
		color: #FFF;

		&:before
		{
			display: none;
		}
		button
		{
			border: 0;
			background: transparent;
			display: block;
			outline: none;
			color: transparent;
			padding: 0;
			cursor: pointer;
			line-height: 0;

			&:hover, &:focus
			{
				outline: none;
			}
			&:before
			{
				display: block;
				content: " ";
				width: 12px;
				height: 12px;
				background: #FFF;
				border-radius: 100%;
				transition: all ease-in;
			}
		}
		&.slick-active button:before
		{
			background: $color-green;
		}
	}
}
.slick-arrow
{
	position: absolute;
	top: 50%;
	z-index: 12;
	border: 0;
	background: transparent;
	display: block;
	width: 0px;
	height: 0px;
	outline: none;
	color: #999;
	padding: 0;
	opacity: 0.8;
	cursor: pointer;
	font-size: 19px;
	line-height: 25px;

	@include media("<=desktop")
	{
		width: 24px;
		height: 44px;
	}

	@include media("<=tablet")
	{
		color: #FFF;
	}

	&.slick-prev
	{
		left: -61px;
		@include media("<=desktop")
		{
			left: -25px;
		}
		@include media("<=tablet")
		{
			left: 20px;
		}
	}

	&.slick-next
	{
		right: -50px;
		@include media("<=desktop")
		{
			right: -36px;
		}
		@include media("<=tablet")
		{
			right: 8px;
		}
	}

	&:hover
	{
		opacity: 1;
	}
}