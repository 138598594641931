section.shopping-cart-course-list {
	margin-top: $grid-margin-large;
	margin-bottom: $grid-margin-large;

	background-color: $color-white;

	font-size: .875rem;
	line-height: 1.5em;

	& > * {
		padding: 1.5625rem 1.3125rem;
	}

	header {
		margin: 0;

		background-color: $color-green;
		color: $color-white;

		h1, h2, h3, h4, h5, h6 {
			margin: 0;

			text-align: left;

			font-weight: $font-weight-bold;
		}

		h1 {
			padding-bottom: 0;

			font-size: .875rem;
			line-height: 1.5em;
		}
	}

	.shopping-cart-course-list {
		padding-top: 0;
		padding-bottom: 0;

		div:last-child .shopping-cart-course-list-item-content {
			border-bottom: 0;
			margin-bottom: 0;
		}
	}

	.shopping-cart-course-list-item {
		padding-top: 0;
		padding-bottom: 0;

		.shopping-cart-course-list-item-content {
			display: flex;
			flex-wrap: wrap;

			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;

			border-bottom: 1px $color-lighter-gray solid;

			padding-bottom: 1.5625rem;

			.shopping-cart-course-list-item-title {
				flex: 2 1 66.66%;

				h1, h2, h3 {
					padding-bottom: 0;

					font-size: .875rem;
					line-height: 1.5em;

					text-align: left;
				}

				h3 {
					font-weight: normal;
				}
			}

			.shopping-cart-course-list-item-prices {
				flex: 1 1 33.34%;

				@include media(">tablet") {
					text-align: right;
				}

				b {
					font-weight: bold;
				}
			}

			.shopping-cart-course-list-item-info {
				flex: 1 1 100%;

				margin-top: 1rem;

				background-color: transparent;

				@include media("<tablet") {
					display: flex;
					flex-direction: column;
				}

				& > * {
					display: inline-block;

					padding-right: 2.85714285714em;
				}

				i {
					color: $color-lighter-gray;
				}
			}
		}
	}


	footer {
		margin: 0;

		background-color: $color-lightest-gray;
		color: $color-black;

		line-height: 1.6875rem;

		.summary {
			margin-bottom: 0;

			border-top: none;

			text-align: right;
		}

		.sums {
			margin-bottom: 0;
			padding: 0;

			.total {
				font-size: 1.25rem;

				font-weight: bold;
			}
		}
	}
}
