.checkout {
	h1, h2, h3, h4, h5, h6,
	div.checkout-subtitle.checkout-subtitle {
		text-align: center;
	}

	.subtitle.subtitle.subtitle {
		text-align: center;
	}

	p {
		.link {
			display: inline-flex;
		}
	}

	div.alternative-address {
		margin-bottom: 20px;

		border-top: 1px solid rgba(0, 0, 0, 0.08);
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);

		padding: 1.285714286em 0;

		p,
		fieldset{
			margin: 0;
			padding: 0;
		}

		p {
			padding: 0 1.785714286em;
		}
		fieldset {
			padding: 1.285714286em 0 0 0;
		}
	}

	.steps {
		width: 80%;
		margin: auto;
		font-size: 16px;
	}

	.steps-top {
		display: flex;
		justify-content: space-around;
		position: relative;
	}
	.steps-bottom {
		display: flex;
	}

	.line {
		position: absolute;
		top: 49%;
		left: 15%;
		right: 15%;
		border-top: 3px $color-dark-gray-transparent solid;
	}

	.step {
		display: flex;
		flex: 1 1 33.33%;
		align-items: center;
		z-index: 1;
	}

	.graphic,
	.text {
		display: flex;
	}

	.graphic {
		z-index: 10;
		height: 2.875rem;
		width: 2.875rem;
		margin: auto;
		border-radius: 50%;
		background-color: $color-lightest-blue;
	}

	.graphic .graphic {
		height: .375rem;
		width: .375rem;
		margin: auto;
		border-radius: 50%;
		background-color: $color-dark-gray-transparent;
	}

	.text {
		flex: 1 1 33.33%;
		align-items: top;
		font-weight: bold;
		text-transform: uppercase;
		color: $color-gray;

		&.current-step {
			color: $color-dark-gray;
		}

		&.text.text p {
			width: 100%;
			font-size: .875rem;
			text-align: center;
		}
	}

	.current-step {
		& > .graphic {
			height: 6.5rem;
			width: 6.5rem;
			background-color: $color-white;
			border: 1.25rem $color-lightest-blue solid;

			.graphic {
				background-color: $color-green;
			}
		}
	}

	.step-completed {
		& > .graphic {
			height: 6.5rem;
			width: 6.5rem;

			border: 1.25rem $color-lightest-blue solid;

			background-color: $color-green;

			.graphic {
				background-color: $color-white;
			}
		}
	}

	fieldset.geschlecht {
		margin-left: $grid-margin-extra-small;

		label {
			display: inline-block;
			margin-right: $grid-margin-micro;
		}
	}

	section.shopping-cart-course-list {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.warnungpflichtfelder-header {
		color: $color-red;
	}
}

.anmeldeformular_but {
	display: inline-block;
	margin-right: 20px;
}

@include media("<tablet") {
	.checkout {
		.row--50-50 {
			flex-direction: column;
		}

		.steps {
			width: 90%;
		}

		.current-step {
			& > .graphic {
				height: 5.25rem;
				width: 5.25rem;
				border-width: .75rem;
			}
		}

		.step-completed {
			& > .graphic {
				height: 5.25rem;
				width: 5.25rem;

				border: .75rem $color-lightest-blue solid;

				background-color: $color-green;
			}
		}
		.step-number {
			display: none;
		}
	}
}
