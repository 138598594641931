.nav__second
{
	position: fixed;
	top: 0;
	right: 0;
	z-index: 103;
	padding: 6px;
	background: #F8F8F8;

	a
	{
		font-size: 12px;
	}

	@include media("<desktop")
	{
		display: none;
	}
}