.nav__meta
{
	ul
	{
		display: flex;
		word-spacing: 0.1rem;
	}

	li
	{
		display: flex;
		padding: 0;
		//height: 30px;
		list-style: none;
		align-content: flex-end;
	}

	a
	{
		padding: 20px 10px;
		text-transform: uppercase;

		&:hover
		{
			color: $color-green;
		}
	}

	@include media("<=tablet")
	{
		a {
			padding: 2px 10px;
		}
	}
	@include media("<tabletWide") {
		ul {
			flex-direction: column;
		}
	}
}
