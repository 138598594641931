.icon_circle
{
	@include circle();

	svg {
		display: block;
		width: 1rem;
		height: 1rem;

		fill: $color-white;
	}

	@at-root {
		span#{&} {
			margin-right: 0.625rem;
		}
	}
}
