.pager {
	display: flex !important;
	flex-direction: row;

	margin-top: 2rem;

	.pagination {
		display: inline-flex;
		flex-direction: row;

		list-style: none;
		margin-left: 0 !important;

		li {
			@include circle($font-weight: normal);

			margin-right: 0.5rem;

			&.active {
				background: $color-black;

				font-weight: bold;

				a {
					cursor: default;
				}
			}
		}

		.blaetternindex {
			display: inline-block;

			width: 100%;
			height: 100%;

			border-radius: 50%;

			line-height: 2.625rem; // matches width / height of circle() mixin
		}
	}

	.paginatbutton {
		@include circle();

		i::before {
			position: static;
			margin-right: 0;
		}
	}

	.blaetterntasten_rueckwaerts,
	.blaetterntasten_vorwaerts,
	.blaetterntasten_first {
		margin-right: 10px;
	}

	.blaetterntasten_rueckwaerts,
	.blaetterntasten_vorwaerts {
		@include media(">=phoneWide") {
			i {
				display: none;
			}
		}
		@include media("<phoneWide") {
			a {
				width: 2.625rem;
				height: 2.625rem;

				border-radius: 50%;

				padding: 0;

				i {
					margin-left: .25em;
				}
			}

			span {
				display: none;
			}
		}
	}
}
