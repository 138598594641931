.tiles
{
	position: relative;
	margin: 0 0 60px;
	.tiles__tile__content
	{
		position: relative;
	}
}

.tiles__tile
{
	position: relative;
	z-index: 1;
	display: flex;
	height: 100%;

	.tiles__tile__content
	{
		width: 100%;
		height: 100%;
		background: #FFF;

		&:hover
		{
			z-index: 2;
			box-shadow: 6px 6px 20px rgba(0, 0, 0, .18);
		}
	}
	.tiles__tile__image
	{
		img
		{
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include object-fit(cover);
		}
	}
	p a
	{
		text-decoration: none;
	}
	&:hover
	{
		p a
		{
			text-decoration: underline;
		}
	}
}

.tiles__tile__target
{
	@include linkOverlay;
}

.tiles__tile__subtitle
{
	display: inline-block;
	padding-right: 20px;
}

.tiles__tile__link
{
	text-transform: uppercase;
	color: $color-green;
	font-size: 13px;
	font-weight: 600;
	display: inline-block;
	padding-left: 0;
}

.tiles--icon
{
	svg
	{
		display: block;
		max-width: 100%;
		max-height: 80%;
		margin: 0 auto;
		fill: $color-green;

		@include media("<=phoneWide") {
			max-height: 35px;
		}
	}

	.row,
	.row.breakOnMobile2 {
		flex-wrap: wrap;

		margin: 0;

		.column
		{
			flex: 1 1 auto;

			max-width: 31.333333333333332%;

			margin: 0 1% 1.25rem;
			padding: 0;

			background-color: $color-white;

			&:before {
				content: "";

				display: block;

				float: left;

				padding: 66% 0 0 0;
			}

			@include media("<=phoneWide") {
				flex: 1 1 48%;

				max-width: 48%;

				margin-bottom: .3125rem;
			}

			.ratio {
				display: flex;

				height: 100%;

				padding-top: 0;

				&:before {
					display: none;
				}

				.ratio__content {
					position: relative;
				}
			}
		}
	}

	.tiles__tile
	{
		display: flex;
		flex-direction: column;
		flex: 1 1;
		width: 100%;
		height: 100%;
		color: #333;

		.tiles__tile__icon
		{
			display: block;
			height: 60%;
			padding: 20% 15% 20px 15%;

			@include media("<=phoneWide") {
				height: auto;

				padding: 20px 13px 13px;
			}
		}

		.tiles__tile__label
		{
			height: 40%;
			display: block;
			font-weight: 400;
			padding: 7.5% 20px;

			@include media("<=phoneWide") {
				height: 5em;

				padding: 0 13px 13px;

				line-height: 1.5em;

				font-size: .8125rem;
			}
		}

		.tiles__tile__content
		{
			align-items: center;
			justify-content: center;
			flex: 1 1;
			display: flex;
			flex-direction: column;

			padding: 0 10px;

			@include media("<=phoneWide") {
				justify-content: flex-start;
			}
			@include media("<tablet") {
				padding: 0 5px !important;
			}
		}

		&:hover .tiles__tile__content
		{
			background: $color-green;
			font-weight: 900;
			color: #FFF;
			svg
			{
				fill: #c4e3b0;
			}
		}
	}

	@include media("<tablet")
	{
		.row
		{
			margin: 0 -5px !important;
			.column
			{
				//padding: 0 5px !important;
			}
		}
		.row .column:nth-child(n+3)
		{
			//margin-top: 10px;
		}
	}
}

.tiles--image--text
{
	display: flex;

	&.tiles--image--text--cols
	{
		flex-wrap: wrap;
		justify-items: flex-start;
		margin: 0 -10px;
		.tiles__tile
		{
			flex: 0 1 33.33%;
			margin: 0 0 20px;
			padding: 0 10px;
			height: auto;
			background: transparent;
			min-width: 33.33%;

			@include media("<tabletWide")
			{
				flex: 0 1 50% !important;
			}
			@include media("<phoneWide")
			{
				flex: 0 1 100% !important;
			}
		}

		&[data-items='2']
		{
			.tiles__tile
			{
				flex: 0 1 50%;
			}
		}

		h2
		{
			font-size: 16px;
			font-weight: 900;
			line-height: 24px;
			color: $color-green;
		}

		h3
		{
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}

		p
		{
			padding-bottom: 0;
		}

		&.tiles--image--text--news
		{
			flex-wrap: wrap;

			.tiles__tile
			{
				flex: 1 0 50%;
				margin: 0 0 20px;

				h2
				{
					padding-bottom: 10px;
					font-size: 14px;
					font-weight: 400;
					color: #333;
				}

				h3
				{
					font-size: 18px;
					font-weight: 600;
					line-height: 27px;
				}
			}
		}
	}

	&.tiles--image--text--rows
	{
		flex-direction: column;

		.tiles__tile
		{
			flex: 0 1 100%;
			display: flex;
			margin: 0 0 20px;

			.tiles__tile__content
			{
				display: flex;
				flex-wrap: wrap;
				padding: 0;

				h2,
				h3
				{
					text-transform: none;
					padding: 0;
					font-size: 18px;

				}
				h2
				{
					color: $color-green;
					padding-bottom: 3px;
				}
				h3
				{
					padding-bottom: 0px;
					font-weight: 600;
				}
				p
				{
					padding: 20px 0 0;
					font-size: 14px;
					line-height: 23px;
				}
			}

			.tiles__tile__image
			{
				flex: 0 1 33.33%;
			}

			.tiles__tile__text
			{
				flex: 1 1;
				padding: 0;
			}

			.tiles__tile__text__inner
			{
				padding: 20px 20px 15px 20px;
			}

			&:hover
			{
				background: #f3ffe5;
			}
		}

		@include media("<tablet")
		{
			display: flex;
			flex-direction: column;
			flex: 1 1;
			width: 100%;
			height: 100%;
			color: #333;

			.tiles__tile__image
			{
				display: block;
				flex: 1 1 100% !important;
				padding: 0;
				width: 100%;
			}

			.tiles__tile__content
			{
				align-items: center;
				justify-content: center;
				flex: 1 1;
				display: flex;
				flex-direction: column;

				.tiles__tile__text__inner
				{
					padding: 10px 10px 5px 10px;
				}
			}
		}
	}

	.tiles__tile__content
	{
		flex: 1;

		h2,
		h3
		{
			text-transform: none;
			padding: 0;
			font-size: 16px;
		}
		h2
		{
			color: $color-green;
			font-weight: 900;
		}
		p
		{
			font-size: 14px;
			line-height: 23px;
		}
	}
	.tiles__tile__image
	{

	}
	.tiles__tile__text
	{
		padding: 20px;
		p a
		{
			text-decoration: underline;
		}
		@include media("<tablet")
		{
			padding: 10px;
		}
	}
}


.bg-light-green .tiles--image--text--rows .tiles__tile,
.bg-light-green .tiles--image--text--rows .tiles__tile:hover
{
	background: #FFF;
}

.tiles--image-hover
{
	.row {
		flex-wrap: wrap;
		justify-items: flex-start;
	}
	.column
	{
		flex: 1 1 50%;
		max-width: 50%;
		margin: 0 0 30px 0;

		@include media("<tablet")
		{
			flex: 1 1 100%;
		}
	}
	.tiles__tile
	{
		height: 100%;
		color: #FFF;
		.tiles__tile__image,
		.tiles__tile__text
		{
			position: absolute;
			width: 100%;
			height: 100%;
		}
		.tiles__tile__image
		{
			overflow: hidden;
			img
			{
				width: 100%;
				height: 100%;
				object-fit: cover;
				@include object-fit(cover);
			}
			.tiles__tile__label
			{
				position: absolute;
				left: 10px;
				bottom: 10px;
				max-width: 80%;
				max-height: 80%;
				border-left: 35px solid $color-green;
				padding: 5px 0 7px;

				strong
				{
					background: $color-green;
					display: inline;
					width: auto;
					padding: 10px 0;
				}
				span
				{
					position: relative;
					left: -20px;
					white-space: normal;
					width: auto;
					word-break: normal;
					font-size: 18px;
					line-height: 30px;

					@include media("<=phoneWide") {
						font-size: .8125rem;
					}
				}
			}
		}
		.tiles__tile__text
		{
			max-height: 100%;
			overflow: hidden;
		}
		.tiles__tile__text
		{
			position: relative;
			display: none;
			padding: 30px;
			background: #6BBA38;
			font-size: 17px;
			line-height: 26px;
			box-shadow: 6px 6px 16px rgba(0, 0, 0, .18);
			h2
			{
				padding-bottom: 20px;
				font-size: 18px;
				font-weight: 900;
				text-transform: none;
			}
			.tiles__tile__link
			{
				color: #FFF;
			}
		}

		.tiles__tile__text__inner
		{
			position: relative;
			display: block;
			overflow: hidden;
			max-height: 87%;
		}

		.tiles__tile__link
		{
			position: absolute;
			right: 30px;
			bottom: 30px;
		}

		&:hover .tiles__tile__text
		{
			display: block;

			@include media("<=tabletExtraWide") {
				display: flex;
				justify-content: center;
				align-items: center;

				padding: 0;

				h2,
				p {
					display: none;
				}

				.tiles__tile__link {
					position: static;
				}
			}

			i {
				display: inline-block;
				padding: 0 0 0 10px;
				color: #c4e3b0;
			}
		}
	}
}

.slide__inner .tiles.tiles--image-hover .tiles__tile__image
{
	border: 3px solid rgb(255, 255, 255);
}

.tiles--text
{
	display: flex;
	flex-direction: column;
	.tiles__tile
	{
		.tiles__tile__content
		{
			flex-direction: column;
			margin: 0 0 60px;
			padding: 20px;
		}
		h2,
		h3
		{
			text-transform: none;
			padding: 0;
			font-size: 18px;
			font-weight: 600;
		}
		h2
		{
			color: $color-green;
			padding-bottom: 10px;
		}
		p
		{
			padding: 20px 0 0;
			font-size: 17px;
		}
	}
}

.tiles--topic
{
	.tiles__tile
	{

	}
}

@include media("<tablet")
{
	.tiles__tile h2,
	.tiles__tile h3,
	.tiles__tile p
	{
		font-size: 13px !important;
		line-height: 21px !important;
	}
	.tiles--text .tiles__tile .tiles__tile__content
	{
		margin-bottom: 20px;
	}
	.tiles--image--text.tiles--image--text--cols
	{
		margin: 0 -5px;
	}
	.tiles--image--text.tiles--image--text--cols .tiles__tile
	{
		margin: 0 0 10px;
		padding: 0 5px;
	}
	.tiles--image--text.tiles--image--text--cols .tiles__tile
	{
		flex: 0 1 50%;
	}
}
