.container-teaser
{
	display: flex;
	align-items: center;
	.row
	{
		margin: 0 auto;
		max-width: 900px;

	}
	.row--reverse
	{
		flex-direction: row-reverse;

		.teaser__image img
		{
			transform: rotate(5deg);
		}
	}
	.column
	{
		flex: 1 1;
	}
	.teaser__image
	{

	}
	.column.image
	{

	}
	.teaser__image
	{
		position: absolute;
		top: -40%;
		right: 0;
		bottom: -40%;
		z-index: 9999;
		width: 150%;
		text-align: right;
		img
		{
			display: inline-block;
			width: auto;
			max-height: 100%;
			transform: rotate(-5deg);
			background: none;
			box-shadow: 6px 6px 20px rgba(0,0,0,.18);
		}
	}

	.column.text
	{
		padding: 1rem 20px 0 40px;
		h2, p
		{
			padding-bottom: .15rem;
		}
	}

	.column.text,
	.column.target
	{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.column.target
	{
		flex: 0 1 auto;
		align-items: flex-end;
	}

	&.container-teaser--programm
	{
		padding: 0 10px 10px;
		@include media("<tablet")
		{
			display: block;
			.row
			{
				display: block;
				&:after
				{
					content: ".";
					clear: both;
					display: block;
					visibility: hidden;
					height: 0;
				}
			}
			.column.image
			{
				display: block;
				float: left;
				flex: 0 !important;
				width: 50%;
				.teaser__image
				{
					position: relative;
					top: inherit;
					left: inherit;
					right: inherit;
					bottom: inherit;
					width: 100%;
					text-align: center;
					img
					{
						width: 70%;
					}
				}
			}
			.column.text,
			.column.target
			{
				flex: 1;
				padding: 0;
			}
			.column.text
			{
				float: right;
				padding: 1rem 0 0;
				width: 50%;
			}
			.column.target
			{
				float: right;
				width: 50%;
				padding: 1rem 0 1rem;
				text-align: right;
				align-items: flex-start;
			}


		}
	}

	&.container-teaser--blog
	{
		.container-inner
		{
			max-width: 700px;
		}

		.teaser__image
		 {
			 bottom: 0;
		 }

		.column.text p
		{
			font-size: 15px;

			@include media("<tablet")
			{
				font-size: 13px;
			}
		}

		.column.text p a
		{
			color: $color-green;
			font-size: 13px;
			font-weight: 600;
			text-decoration: none;
			&:after
			{
				display: inline-block;
				padding: 0 0 0 10px;
				font-family: "paricons";
				content: '\e92e';
			}
		}

		@include media("<tablet")
		{
			display: block;
			.teaser__image
			{
				top: -20%;
			}
			.column.text
			{
				padding: 0 40px 0 10px;
				@include media("<tablet")
				{
					padding-right: 80px;
				}
			}
		}
	}
}