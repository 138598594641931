$font-weight-light: 100;
$font-weight-regular: 400;
$font-weight-semibold: 600;
$font-weight-bold: 900;

@font-face
{
	font-family: "Open Sans";
	src: url(#{$path-fonts}opensans-bold-webfont.woff2) format('woff2'), url(#{$path-fonts}opensans-bold-webfont.woff) format('woff'), url(#{$path-fonts}opensans-bold-webfont.ttf) format('truetype'), url(#{$path-fonts}opensans-bold-webfont.svg#robotoopensans-bold-webfont) format('svg');
	font-weight: $font-weight-bold;
	font-style: normal;
}

@font-face
{
	font-family: "Open Sans";
	src: url(#{$path-fonts}opensans-semibold-webfont.woff2) format('woff2'), url(#{$path-fonts}opensans-semibold-webfont.woff) format('woff'), url(#{$path-fonts}opensans-semibold-webfont.ttf) format('truetype'), url(#{$path-fonts}opensans-semibold-webfont.svg#robotoopensans-semibold-webfont) format('svg');
	font-weight: $font-weight-semibold;
	font-style: normal;
}

@font-face
{
	font-family: "Open Sans";
	src: url(#{$path-fonts}opensans-regular-webfont.woff2) format('woff2'), url(#{$path-fonts}opensans-regular-webfont.woff) format('woff'), url(#{$path-fonts}opensans-regular-webfont.ttf) format('truetype'), url(#{$path-fonts}opensans-regular-webfont.svg#robotoopensans-regular-webfont) format('svg');
	font-weight: $font-weight-regular;
	font-style: normal;
}

@font-face
{
	font-family: "Open Sans";
	src: url(#{$path-fonts}opensans-light-webfont.woff2) format('woff2'), url(#{$path-fonts}opensans-light-webfont.woff) format('woff'), url(#{$path-fonts}opensans-light-webfont.ttf) format('truetype'), url(#{$path-fonts}opensans-light-webfont.svg#robotoopensans-light-webfont) format('svg');
	font-weight: $font-weight-light;
	font-style: normal;
}
