@mixin circle($background-color: $color-green, $background-color-hover: $color-green-hover, $color: $color-white, $color-hover: $color-white-transparent, $font-weight: $font-weight-bold) {
	display: flex;
	justify-content: center;
	align-items: center;

	flex-shrink: 0;

	width: 2.625rem;
	height: 2.625rem;

	border-radius: 50%;

	background-color: $background-color;

	text-align: center;
	color: $color;
	font-weight: $font-weight;

	&:hover {
		box-shadow: 0 2px 4px rgba(0,0,0,.16);
		background-color: $background-color-hover;
		color: $color-hover;
	}
}

@mixin linkOverlay() {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 2;
	text-decoration: none;
}

@mixin iconOutOfFlow() {
	position: absolute;
	left: 0;
	width: 1.142857143em;
	font-size: 1.142857143em;
	text-align: center;
}

@mixin icon() {
	&:before {
		left: 1ex;
	}
}

@mixin iconHover() {
	@include icon;
	color: $color-white-transparent;
}

@mixin centerContentVertically() {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@mixin borderRoundedRight($x: 1.5rem, $y: 1.5rem) {
	border-radius: 0 $x $y 0;
}

@mixin fancyList() {
	display: block;

	margin: 0;
	padding: 0;

	list-style-type: none;

	li {
		display: flex;
		justify-content: space-between;
		align-items: center;

		min-height: 3.75rem;

		border-bottom: 1px solid $color-lighterer-gray;

		padding-right: 1.1764705882352942rem;
		padding-left: 1.1764705882352942rem;

		&:last-of-type {
			border-bottom: 0;
		}
	}
}

@mixin textWithIcon() {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-grow: 1;

	i {
		margin-left: 2ex;

		font-size: .5625rem;
	}

}

// same as predefined .padding-lr-less
@mixin paddingLrLess() {
	padding-right: 30px;
	padding-left: 30px;

	@include media("<phoneWide") {
		padding-right: 15px;
		padding-left: 15px;
	}
}

@mixin paddingXyZero() {
	padding-top: 0;
	padding-bottom: 0;
}

