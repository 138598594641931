.ratio
{
	position: relative;
	&:before
	{
		display: block;
		padding-top: 100%;
		content: '';
	}
	&.ratio--outer
	{
		height: 100%;
	}
	&.ratio--outer:before
	{
		display: none;
	}

	&.ratio--1-2:before
	{
		padding-top: 200%;
	}

	&.ratio--2-1:before
	{
		padding-top: 50%;
	}

	&.ratio--2-3:before
	{
		padding-top: 56.522%;
	}

	&.ratio--2-3:before
	{
		padding-top: 66.66%;
	}

	&.ratio--4-3:before
	{
		padding-top: 75%;
	}

	&.ratio--3-4:before
	{
		padding-top: 133.33%;
	}

	&.ratio--16-9:before
	{
		padding-top: 56.25%;
	}
}
.ratio__content
{
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}