$default-margin:	20px;
$default-padding: 	20px;

$containerWidth:	1280;

$phoneWidth:		320px;
$phoneBigWidth:		375px; // Apple iPhone X
$phoneWideWidth:	480px;
$tabletWidth:		768px;
$tabletWideWidth:	960px;
$tabletExtraWideWidth:	1024px; // Apple iPad pro
$desktopWidth:		1200px;
$desktopWideWidth:	1600px;

$column: 			90px;
$gutter: 			20px;
$grid-columns: 		12;

/*
$desktop-wide: 1600px;
$desktop: 1200px;
$tablet-wide: 960px;
$tablet: 768px;
$phone-wide: 480px;
$phone: 320px;
$mobile: 320px;
*/

//$screen: new-breakpoint(max-width $desktop);
//$tablet-wide: new-breakpoint(max-width $tablet-wide);
$phone: new-breakpoint(max-width $phoneWidth);
$phoneBig: new-breakpoint(max-width $phoneBigWidth);
$phoneWide: new-breakpoint(max-width $phoneWideWidth);
$tablet: new-breakpoint(max-width $tabletWidth);
$tabletWide: new-breakpoint(max-width $tabletWideWidth);
$tabletExtraWide: new-breakpoint(max-width $tabletExtraWideWidth);
$desktop: new-breakpoint(max-width $desktopWidth);
$desktopWide: new-breakpoint(max-width $desktopWideWidth);

$breakpoints: ('phone': 320px, 'phoneBig': 375px, 'phoneWide': 480px, 'tablet': 768px, 'tabletWide': 960px, 'tabletExtraWide': $tabletExtraWideWidth, 'desktop': 1200px, 'desktopWide': 1600px) !global;
