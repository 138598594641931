img,
video
{
	display: block;
	max-width: 100%;
	height: auto;

}

.map img
{
	display: inherit;
	width: inherit;
	max-width: inherit;
	height: inherit;
	max-height: inherit;
}

.parakm-ce-image
{
	& + div
	{
		margin-top: 20px;
	}
}

.parakm-ce-image img
{
	width: 100%;
}

.parakm-ce-image .parakm-ce-image__background.ratio__content
{
	transition: all .25s;
}

.parakm-ce-image__background
{
	img
	{
		object-fit: cover;
		@include object-fit(cover);
		height: 100%;

	}
	&.parakm-ce-image__image--cover img
	{
		object-fit: cover;
		@include object-fit(cover);
	}
	&.parakm-ce-image__image--contain img
	{
		object-fit: contain;
		@include object-fit(cover);
	}
}

.parakm-ce-image__text
{
	color: #FFF;
	display: flex;
	padding: 30px;
	align-items: flex-end;
	justify-content: center;

	.parakm-ce-image__text__wrap
	{
		position: relative;
	}

	@include media("<tablet")
	{
		padding: 10px;
	}
}

.parakm-ce-image--gradient .parakm-ce-image__text:before
{
	position: absolute;
	right: 0px;
	bottom: 0px;
	left: 0px;
	height: 55%;
	background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .9));
	content: " ";
}

.parakm-ce-image__caption
{
	position: absolute;
	bottom: 20px;
	left: 20px;
	right: 20px;
	padding: 5px 0 4px;
	font-size: 13px;
	font-weight: 600;
	border-left: 20px solid $color-green;
	color: #FFF;
	strong
	{
		background: $color-green;
		display: inline;
		width: auto;
		padding: 6px 0;
	}
	span
	{
		position: relative;
		left: -9px;
		white-space: normal;
		width: auto;
		word-break: normal;
		font-size: 13px;
		line-height: 21px;
	}
}

.parakm-ce-image__text__wrap
{
	width: auto;
	h1,
	h2,
	h3,
	p
	{
		margin: 0;
		padding: 0;
	}
}

.parakm-ce-image__text--left
{
	justify-content: flex-start;
	h1, h2, h3, p
	{
		text-align: left;
	}
}

.parakm-ce-image__text--right
{
	justify-content: flex-end;
	h1, h2, h3, p
	{
		text-align: right;
	}
}

.parakm-ce-image__text__wrap
{
	border: 1px solid #FFF;
	border-width: 1px 0;
	padding: 10px 0;
}

.parakm-ce-image__link
{
	z-index: 9;
}

.video-youtube,
.video-vimeo
{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 0;
	height: 0;
	overflow: hidden;
	iframe
	{
		border: 0;
		outline: 0;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.video-player
{

}

.video-player__mobile
{
	display: none;
}

@media (orientation: portrait) and (max-width: 600px)
{
	.video-player--mobile-optimized
	{
		.video-player__default
		{
			display: none !important;
		}
		.video-player__mobile
		{
			display: block !important;
		}
	}
}

iframe
{
	border: 0;
	outline: 0;
	width: 100%;
	height: 100%;
}

.section-inner
{
	.video-player
	{
		position: relative;
		overflow: hidden;
		margin-bottom: 40px;
		&:before
		{
			display: block;
			padding-top: 56.25%;
			content: '';
		}
	}
	.video-player__default
	{
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.compat-object-fit
{
	background-size: cover;
	background-position: center;
}

.compat-object-fit img
{
	opacity: 0;
}
