.nav__main
{
	.shopping {
		& > a {
			color: $color-darker-gray;
		}

		&:hover > a {
			color: $color-green-hover;
		}

		@include media(">=desktop") {
			a:first-of-type {
				position: relative;

				span {
					@include circle;
					display: inline-block;

					position: relative;
					top: -1.55em;
					left: -1.8em;

					width: 1.6em;
					height: 1.6em;

					padding: 0.4425em 0.22175em;

					line-height: 0.715em;
					font-size: 0.715em;
				}

				&:hover {
					span {
						background: $color-green-hover;
					}
				}

				// reset correction of icon positioning, since the correction is only needed if text is next to it.
				& > i::before {
					top: auto;
					margin-right: 0;
				}
			}
		}

		@include media("<=tabletWide") {
			.counter {
				display: none;
			}
		}
	}
}
