#seminarangebote_details {
	.statusmeldung {
		display: none;
	}
	.page-header {
		z-index: 10;

		.row > .column {
			padding-right: 3.4375em;
		}
	}

	h2 {
		text-transform: uppercase;
	}

	.course-details {
		h2 {
			i {
				@include iconOutOfFlow;

				margin-right: .625em;

				color: $color-lighter-gray;
			}
		}

		.row {
			margin: 0;

			& > .column {
				padding: 0;
			}
		}
		.course-overview-similar {
			display: none;
		}

		.column-main {
			flex: auto;
		}

		.column-side {
			flex-shrink: 0;
			flex-grow: 0;

			min-width: 27ex;
		}
	}

	// course description
	.container-outer {
		padding: 0;

		&.danger {
			background-color: #e3e3e3;
		}
		&.disabled {
			.basic-information {
				.tutors {
					margin-bottom: 0;
					border-bottom: 0;
					padding-bottom: 0;

					a {
						font-size: 0.823529412em;
					}
				}
			}
		}
	}
	.container--header {
		.container-inner {
			padding: 0;
		}
		h1 {
			font-weight: 800;
			text-transform: none;
		}
		h2 {
			font-weight: 300;
			text-transform: none;
		}
		h3 {
			font-size: 1em;
		}
	}
	.container--content {
		&.container-outer {
			padding-top: 50px;

			&:before {
				background-color: #fff;
			}
		}
		&.container-inner {
			padding: 0;
		}
	}

	.course-list-header {
		.tag-cooperation {
			margin-bottom: .875em;
		}
		.course-list-item-categories {
			margin-bottom: 2em;
		}
	}

	a {
		&.regular {
			color: $color-green;
			text-decoration: none;
		}
		&.button {
			&.checkout {
				margin-bottom: 1.25em;
			}
		}

		&[href^="mailto"] {
			text-decoration: underline;
		}

	}

	.basic-information {
		h2 {
			margin-top: 2.15625em;
			border-top: 1px solid #ccc;
			padding-top: 2.28125em;
			font-size: 1.1em;
		}
		.tutors {
			margin-bottom: 2.28125em;
			border-bottom: 1px solid #ccc;
			padding-bottom: 2.15625em;

			h3 {
				padding: 0;
				font-weight: 400;
			}
			a {
				font-size: 0.823529412em;
			}
		}
		.actions {
			display: inline-flex;
			flex-direction: column;
		}
	}
	.detailed-information-column {
		position: absolute;
	}
	.detailed-information {
		background-color: #fff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
		padding: 1px 0 0;

		a {
			word-break: break-all;

			/* Non standard for WebKit */
			word-break: break-word;
		}

		div,
		h1, h2, h3, h4, h5, h6 {
			line-height: 1.642857143em;
		}

		& > div {
			border-top: 1px solid #ddd;

			&:first-of-type {
				border-top-size: 0;
			}
		}
		h2 {
			padding-left: 1.857142857em;
			font-size: 0.875em;
			font-weight: $font-weight-bold;
			text-transform: uppercase;

			span {
				font-weight: 400;
				text-transform: none;
			}
		}

		.content {
			padding: 0 1.857142857em;
			font-size: 0.875em;
		}
		.dates {
			h2 {
				position: relative;

				span.deadline {
					display: inline-block;
					position: absolute;
					top: 0;
					right: -2.25em;
					border: 1px white solid;
					padding: 0.5em 0.75em;
					background-color: #005da9;
					line-height: 1.2em;
					font-size: 0.857142857em;
					font-weight: 900;
					text-transform: uppercase;
					color: #fff;
				}
			}

			.content > a {
				display: inline-block;
				margin-top: 1.142857143em;
			}
		}
		.contacts {
			a {
				display: inline-block;
			}

			.contact-person {
				margin-bottom: 1.571428571em;
				
				img.portrait {
					margin-bottom: 1.25ex;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
			.name {
				font-weight: 900;
				color: $color-green;
			}
		}
		.location {
			a {
				display: inline-block;

				&.regular {
					text-decoration: underline;
					color: $color-dark-gray;
				}
			}
		}
		.actions {
			.button {
				display: inline-block;
				width: 100%;
			}
		}
		.accordion-toggle {
			.more {
				@include circle($color-white, $color-white);

				display: inline-block;

				width: 1.285714286em;
				height: 1.285714286em;

				margin-left: 0.5rem;
				border: .071428571em solid $color-green;
				padding: .071428571em;

				line-height: 1em;

				color: $color-green;

				&:hover {
					background-color: $color-green;
					color: $color-white;
				}
			}

			&.accordion-open {
				.label {
					text-decoration: underline;
				}
				.more {
					background-color: $color-green;
					color: $color-white;

					&:hover {
						border-color: $color-green-hover;
						color: $color-green-hover;
					}
				}
			}
		}
	}
	.course-number {
		margin-top: 1.375em;
		text-align: center;
		font-size: 0.75em;
		color: $color-gray;
	}
	.similar-courses {
		.page-header,
		.page-content,
		.course-not-available {
			display: none;
		}
	}
	.detailed-dates {
		ul {
			margin: 1.428571429em -2.857142857em 0;

			list-style: none;

			.alt1,
			.alt2 {
				padding: .571428571em 3.357142857em .642857143em;
			}

			.alt1 {
				background-color: $color-lightest-gray;
			}
		}
	}

	.column.mobile .detailed-information-column {
		position: static;

		.detailed-information {
			box-shadow: none;

			& > div:first-of-type {
				border-top-width: 1px;
			}
		}
	}
}




@include media("<=phoneBig") {
	#seminarangebote_details {
		.basic-information {
			.actions {
				flex-wrap: wrap;
			}

			a.button,
			a.download,
			button.button,
			input.button {
				width: 100%;
			}
		}
	}
}

@include media(">phoneBig") {
	#seminarangebote_details {
		.basic-information {
			.actions {
				.button.checkout {
					margin-right: 1rem;
				}
			}
		}
	}
}

@include media("<=phoneWide") {
	#seminarangebote_details {
		.basic-information {
			.actions {
				justify-content: space-between;

				.button.checkout,
				.button.cart {
					flex: 1 0 auto;
				}
			}
		}
	}
}

@include media(">phoneWide") {
	#seminarangebote_details {
		.basic-information {
			.actions {
				justify-content: flex-start;

				.button.checkout,
				.button.cart {
					flex: 0 0 auto;
				}
			}
		}
	}
}

@include media("<tablet") {
	#seminarangebote_details {
		.page-header {
			h1 {
				font-size: 21px;
			}
			h2 {
				font-size: 16px;
			}
		}
		.page-content .row {
			flex-direction: column-reverse;
		}
		.column-side {
			display: none;
		}
		.basic-information {
			.actions {
				display: flex;
				flex-direction: row;
			}
		}
	}
}

@include media(">=tablet") {
	.course-list-header {
		padding-right: 3rem;
	}

	#seminarangebote_details {
		.basic-information {
			.actions {
				.button.checkout {
					margin-right: 0;
				}
			}
		}

		.column.mobile .detailed-information-column {
			display: none;
		}
	}
}
