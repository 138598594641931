.page {
	position: relative;
	overflow-x: hidden;
	z-index: 1;
	height: 100%;
}

main
{
	position: relative;
	z-index: 20;
	margin: 0;
	min-height: calc(100vh - 228px);
	background: #FFF;
}

body
{
	.section-inner
	{
		position: relative;
		overflow: hidden;
		height: 100%;
		padding: 0;
	}
}
.container-outer {
	position: relative;
	width:100%;
	height:100%;
	margin:0px;
}
.container-inner {
	position: relative;
	width:100%;
	max-width: 1200px;
	margin:0 auto;
	padding:0;
}
.content-wrap {
	position:relative;
	z-index:9;
	margin:0 auto;

	/* prevent childrens vertical margins to affect siblings of their parent */
	padding-top: 0.001rem;
	padding-bottom: 0.001rem;
}
.container-inner > .container-inner {
	position:relative;
	overflow:hidden;
	padding-left:0;
	padding-right:0;
}
.container-inner.headline {
	display:block;
}
.container-inner.headline > div {
	flex:0;
}
.container-inner.headline h1, .container-inner.headline h2 {
	padding-bottom:0;
}

.page__icon
{
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: -72px;
	left: 30%;
	z-index: 80;
	width: 145px;
	height: 145px;
	background: #FFF;
	border-radius: 100%;

	svg
	{
		width: 60%;
		height: 60%;
		fill: $color-green;
	}
}

.no-header .page__icon
{
	display: none;
}

@include media("<tablet")
{
	.page__icon
	{
		display: none;
	}
}
