.page-386 {
	@include media(">=desktop") {
		padding-top: 60px; // mimick height of breadcrumb
	}

	background-color: $color-light-blue;

	form#schnellsuche {
		display: block;

		@include media("<tablet") {
			& > h1 {
				text-align: left !important;
				padding-left: 20px;
			}
		}
	}
}

.anzahlbereich {
	padding-left: 20px;

	@include media("<phoneWide") {
		.separator {
			display: none;
		}
	}
}
.column .sortierungsbereich.sortierungsbereich {
	display: inline-block;
	float: right;

	width: auto;

	padding-right: .625rem;
	padding-left: .625rem;

	@include media("<tablet") {
		display: none;
	}
}
span.kursanzahl {
	color: #6BBA38;
}

.column.column-erweiterte-suche {
	min-width: 17.25rem;

	@include media("<tablet") {
		display: none;
	}
}
.column-erweiterte-suche-section {
	background: $color-lightest-gray;
}

.erweiterte-suche-header h2 {
	padding: 0.9375rem;

	font-size: 0.875rem;
	font-weight: bold;

	text-transform: uppercase;
}

.erweiterte-suche-filter {
	padding: 15px;
	position: relative;

	border-top: 1px solid $color-lighterer-gray;

	font-size: 0.875rem;

	ul:first-of-type {
		list-style: none;
	}

	&.borderunten {
		border-top: 0 none;
		border-bottom: 1px solid $color-lighterer-gray;

		padding-top: 0;
	}

	&.noborder {
		border-top: 0 none;

		padding-top: 0;
	}

	input[type="text"] {
		border-radius: 30px;
		border-color: $color-lighter-gray;

		margin-bottom: 0;
		padding-top: .5rem;
		padding-right: 20px;
		padding-bottom: .5rem;
		padding-left: 20px;

		color: $color-lighter-gray;

		&:hover {
			border-color: $color-lighter-gray;
		}
	}

	// custom styled checkbox using ::after element
	label {
		//display: block;
		//content: '';
		//
		//width: 1rem;
		//height: 1rem;
		//
		//border: 1px solid #ddd;
		//border-radius: 20%;
		//
		//background-color: white;
		//background-image: url();
	}

	&.kategs {
		padding: 0;
	}
	&.kennz {
		padding: 0;
	}
	&.veranart {
		padding-right: 0;
	}
}

.kw-katexceptkennzlist,
.kw-katkategorielist,
.kw-katveranartlist {
	li {
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;

		padding-right: 0.9375rem;

		&.selected {
			background-color: $color-lightest-green;
		}

		label {
			display: flex;
			align-items: center;
		}

		svg {
			flex: 1 1 100%;

			max-width: 1.875rem;
			max-height: 1.875rem;

			margin-right: 0.9375rem;

			fill: $color-green;
		}
	}
}

.kw-katexceptkennzlist {
	li {
		padding: 0.9375rem;
	}
}

.kw-katkategorielist {
	li {
		min-height: 3.75rem;

		border-bottom: 1px solid $color-lighterer-gray;

		padding-left: 0.9375rem;

		&:last-child {
			border-bottom: none;
		}
	}

	label {
		width: 100%;
	}

	input {
		margin-left: 3em;
	}
}

.kw-katveranartlist {
	li {
		&.selected {
			background-color: transparent;
		}
	}
}

#schnellsuchemobil {
	padding: 0 !important;
}

.searchform-sort-mobile {
	h2 {
		padding-left: 1.1764705882352942rem;

		font-size: 12px;
		line-height: 17px;
	}
	ul {
		@include fancyList();

		li {
			a {
				@include textWithIcon();

				font-size: 13px;
				line-height: 23px;
			}
		}
	}
}
