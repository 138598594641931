.page-33 {
	.statusmeldung {
		display: none;
	}

	h1 {
		margin-bottom: 2.555555556em;
		padding-bottom: 0;
	}
	.course-list {
		margin-bottom: 3.5625em;
	}
	.course {
		margin-bottom: 1.6875em;

		.actions {
			padding-left: 1.25em;

			line-height: 1em;

			.remove {
				font-size: 0.75em;

				color: $color-gray;

				&:hover {
					color: $color-lighter-red;
				}
			}
		}
	}
	.summary {
		border-top: 1px solid $color-black-transparent-lightest;
		margin-bottom: 2.0625em;

		text-align: right;

		.actions {
			margin-bottom: 5.625em;

			.button {
				padding-right: 5.230769231em;
				padding-left: 5.230769231em;
			}
		}
	}
	.sums {
		margin-bottom: 1.875em;
		padding: .8125em;

		.total {
			font-size: 1.125em;
			font-weight: $font-weight-bold;
		}
		.total-discounted {
			font-size: .875em;
		}
	}
	.share {
		border: none;
		margin: 0;
	}
}

