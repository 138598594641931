$grid-margin-large: 100px;
$grid-margin-medium: 75px;
$grid-margin-small: 50px;
$grid-margin-extra-small: 25px;
$grid-margin-micro: 10px;

.row
{
	display: table;
	display: flex;
	margin: 0 -10px;
	padding: 0 0;
	table-layout: fixed;

	&.row--75-25
	{
		.column:first-child
		{
			flex:1 1 75%;
		}
		.column:last-child
		{
			flex:1 1 25%;
		}
	}
	&.row--68-32
	{
		.column:first-child {
			flex:1 1 68.75%;
		}
		.column:last-child {
			flex:1 1 31.25%;
		}
	}
	&.row--25-75
	{
		.column:first-child
		{
			flex:1 1 25%;
		}
		.column:last-child
		{
			flex:1 1 75%;
		}
	}

	&.row--50-25-25
	{
		.column:first-child
		{
			flex:1 1 50%;
			padding-right: 25px;
		}
		.column:nth-child(2n)
		{
			padding-left: 0px;
			padding-right: 20px;
			flex:1 1 25%;
		}
		.column:last-child
		{
			padding-left: 5px;
			flex:1 1 25%;
		}
	}

	&.row--25-50-25
	{
		.column:first-child
		{
			flex:1 1 25%;
			padding-right: 5px;
		}
		.column:nth-child(2n)
		{
			flex:1 1 50%;
			padding-left: 20px;
			padding-right: 20px;
		}
		.column:last-child
		{
			padding-left: 5px;
			flex:1 1 25%;
		}
	}

	&.row--25-25-50
	{
		.column:first-child
		{
			flex:1 1 25%;
			padding-right: 5px;
		}
		.column:nth-child(2n)
		{
			flex:1 1 25%;
			padding-left: 20px;
			padding-right: 0px;
		}
		.column:last-child
		{
			flex:1 1 50%;
			padding-left: 25px;
		}
	}

	&.row--75
	{
		& > .column
		{
			flex: 0 0 75%;

			margin-right: auto;
			margin-left: auto;

			padding-right: 5px;

			@include media("<=tablet") {
				flex: 0 0 100%;
			}
		}
	}

}

.column
{
	position: relative;
	flex: 1 1 50%;
	padding: 0 10px;

	&>.container-inner
	{
		height: 100%;
	}
}

.column > div:not(.row)
{
	display: block;
	width: 100%;
	flex: 0 0 0;
}

@include media("<tablet")
{
	.row.breakOnMobile, .row.breakOnMobile .col, .row.breakOnMobile .column {
		display: block;
		flex: 0;
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.row.breakOnMobile2
	{
		flex-wrap: wrap;
		.col, .column {
			flex: 0 1 50%;
		}
	}
}

.row.gutter-5px
{
	margin:0 -5px;
	&>.column
	{
		padding:10px 5px;
	}

	@include media("<tablet")
	{
		margin:0;
		&>.column
		{
			padding:5px 0px;
		}
	}
}

.row.no-gutter
{
	margin:0;
	&>.column
	{
		padding:0;
	}
}


/*
	Spacing

	The .frame-space* classes are from Typo3
*/
.m0 {
	margin: 0 !important;
}
.mt0 {
	margin-top: 0 !important;
}
.mr0 {
	margin-right: 0 !important;
}
.mb0 {
	margin-bottom: 0 !important;
}
.ml0 {
	margin-left: 0 !important;
}


.m1 {
	margin: 1em !important;
}
.mt1,
.frame-space-before-extra-small {
	margin-top: 1em !important;
}
.mr1 {
	margin-right: 1em !important;
}
.mb1,
.frame-space-after-extra-small
{
	margin-bottom: 1em !important;
}
.ml1 {
	margin-left: 1em !important;
}

.m2 {
	margin: 2em !important;
}
.mt2,
.frame-space-before-small {
	margin-top: 2em !important;
}
.mr2 {
	margin-right: 2em !important;
}
.mb2,
.frame-space-after-small {
	margin-bottom: 2em !important;
}
.ml2 {
	margin-left: 2em !important;
}

.m3 {
	margin: 3em !important;
}
.mt3,
.frame-space-before-medium {
	margin-top: 3em !important;
}
.mr3 {
	margin-right: 3em !important;
}
.mb3,
.frame-space-after-medium {
	margin-bottom: 3em !important;
}
.ml3 {
	margin-left: 3em !important;
}

.m4 {
	margin: 4em !important;
}
.mt4,
.frame-space-before-large {
	margin-top: 4em !important;
}
.mr4 {
	margin-right: 4em !important;
}
.mb4,
.frame-space-after-large {
	margin-bottom: 4em !important;
}
.ml4 {
	margin-left: 4em !important;
}

.m5 {
	margin: 5em !important;
}
.mt5,
.frame-space-before-extra-large {
	margin-top: 5em !important;
}
.mr5 {
	margin-right: 5em !important;
}
.mb5,
.frame-space-after-extra-large {
	margin-bottom: 5em !important;
}
.ml5 {
	margin-left: 5em !important;
}




.p0 {
	padding: 0 !important;
}
.pt0 {
	padding-top: 0 !important;
}
.pr0 {
	padding-right: 0 !important;
}
.pb0 {
	padding-bottom: 0 !important;
}
.pl0 {
	padding-left: 0 !important;
}

.p1 {
	padding: 1em !important;
}
.pt1 {
	padding-top: 1em !important;
}
.pr1 {
	padding-right: 1em !important;
}
.pb1 {
	padding-bottom: 1em !important;
}
.pl1 {
	padding-left: 1em !important;
}

.p2 {
	padding: 2em !important;
}
.pt2 {
	padding-top: 2em !important;
}
.pr2,
.padding-lr-less {
	padding-right: 2em !important;
}
.pb2 {
	padding-bottom: 2em !important;
}
.pl2,
.padding-lr-less{
	padding-left: 2em !important;
}

.p3 {
	padding: 3em !important;
}
.pt3 {
	padding-top: 3em !important;
}
.pr3 {
	padding-right: 3em !important;
}
.pb3 {
	padding-bottom: 3em !important;
}
.pl3 {
	padding-left: 3em !important;
}

.p4 {
	padding: 4em !important;
}
.pt4 {
	padding-top: 4em !important;
}
.pr4 {
	padding-right: 4em !important;
}
.pb4 {
	padding-bottom: 4em !important;
}
.pl4 {
	padding-left: 4em !important;
}

.p5 {
	padding: 5em !important;
}
.pt5 {
	padding-top: 5em !important;
}
.pr5 {
	padding-right: 5em !important;
}
.pb5 {
	padding-bottom: 5em !important;
}
.pl5 {
	padding-left: 5em !important;
}


@include media("<=phoneWide") {
	.m2 {
		margin: .5em !important;
	}
	.mt2,
	.frame-space-before-small {
		margin-top: .5em !important;
	}
	.mr2 {
		margin-right: .5em !important;
	}
	.mb2,
	.frame-space-after-small {
		margin-bottom: .5em !important;
	}
	.ml2 {
		margin-left: .5em !important;
	}

	.m3 {
		margin: 1em !important;
	}
	.mt3,
	.frame-space-before-medium {
		margin-top: 1em !important;
	}
	.mr3 {
		margin-right: 1em !important;
	}
	.mb3,
	.frame-space-after-medium {
		margin-bottom: 1em !important;
	}
	.ml3 {
		margin-left: 1em !important;
	}

	.mt4,
	.frame-space-before-large {
		margin-top: 1.5em !important;
	}
	.mb4,
	.frame-space-after-large {
		margin-bottom: 1.5em !important;
	}



	.pt2 {
		padding-top: .5em !important;
	}
	.pr2 {
		padding-right: .5em !important;
	}
	.pb2 {
		padding-bottom: .5em !important;
	}
	.pl2 {
		padding-left: .5em !important;
	}

	.p3 {
		padding: 1em !important;
	}
	.pt3 {
		padding-top: 1em !important;
	}
	.pr3 {
		padding-right: 1em !important;
	}
	.pb3 {
		padding-bottom: 1em !important;
	}
	.pl3 {
		padding-left: 1em !important;
	}

	.pt4 {
		padding-top: 1.5em !important;
	}
	.pb4 {
		padding-bottom: 1.5em !important;
	}
}

@include media("<=tablet") {
	.m2 {
		margin: 1em !important;
	}
	.mt2,
	.frame-space-before-small {
		margin-top: 1em !important;
	}
	.mr2 {
		margin-right: 1em !important;
	}
	.mb2,
	.frame-space-after-small {
		margin-bottom: 1em !important;
	}
	.ml2 {
		margin-left: 1em !important;
	}

	.m3 {
		margin: 1.5em !important;
	}
	.mt3,
	.frame-space-before-medium {
		margin-top: 1.5em !important;
	}
	.mr3 {
		margin-right: 1.5em !important;
	}
	.mb3 {
		margin-bottom: 1.5em !important;
	}
	.ml3 {
		margin-left: 1.5em !important;
	}

	.mt4,
	.frame-space-before-large {
		margin-top: 2em !important;
	}
	.mb4,
	.frame-space-after-large {
		margin-bottom: 2em !important;
	}



	.pt2 {
		padding-top: 1em !important;
	}
	.pr2 {
		padding-right: 1em !important;
	}
	.pb2 {
		padding-bottom: 1em !important;
	}
	.pl2 {
		padding-left: 1em !important;
	}

	.p3 {
		padding: 1.5em !important;
	}
	.pt3 {
		padding-top: 1.5em !important;
	}
	.pr3 {
		padding-right: 1.5em !important;
	}
	.pb3 {
		padding-bottom: 1.5em !important;
	}
	.pl3 {
		padding-left: 1.5em !important;
	}

	.pt4 {
		padding-top: 2em !important;
	}
	.pb4 {
		padding-bottom: 2em !important;
	}
}
