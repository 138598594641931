.tutor-details {

	.tutor-name {
		text-transform: uppercase !important;
	}

	.column {
		&.back_link {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		}

		&.portrait {
			display: flex;
			flex-direction: row;
			justify-content: center;
		}
	}
}
