.nav__breadcrumps
{
	position: absolute;
	top: 130px;
	left: 0;
	right: 0;
	z-index: 51;
	padding: 10px 0 0;
	font-size: 12px;
	font-weight: 400;

	& > .container-inner {
		max-width: 1280px;
		padding-left: 10px;
	}

	a
	{
		font-size: 12px;
	}

	.nav__breadcrumps__label,
	ul,
	li,
	a
	{
		display: inline-block;
		margin: 0;
		padding: 0;
	}

	.nav__breadcrumps__label,
	a
	{
		padding-right: 10px;
	}

	li:after
	{
		display: inline-block;
		padding-right: 10px;
		content: " > ";
	}

	li:last-child:after
	{
		display: none;
	}
}

header#top + .page__divider .nav__breadcrumps
{
	color: #DDD;
	a
	{
		color: #DDD;
	}
}

.nav__breadcrumps--dark
{
	color: #333 !important;
	a
	{
		color: #333 !important;
	}
}


@include media("<desktop")
{
	.nav__breadcrumps
	{
		display: none;
	}
}
