.nav
{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 130px;
	background: #FFF;
	z-index: 50;
	overflow: visible;
	a
	{
		color: #333;
		font-size: 14px;
		font-weight: 400;
		text-decoration: none;
		//transition: all .25s ease-in-out;
	}
	ul, li
	{
		display: flex;
		list-style: none;
	}


	& > .container-inner
	{
		max-width: 1280px;
	}
}

.nav__divider
{
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 10px;
	background: $color-green;
	&:before
	{
		display: block;
		width: 100%;
		height: 6px;
		content: " ";
		background: url(#{$path-images}par-layout-border-top@2x.png) center;
		background-size: cover;
	}
}
/*
@include media(">tablet-wide")
{
	body.page-1
	{
		nav:before
		{
			display: none;
		}
	}
}

@include media(">tablet-wide")
{
	.mainnav ul.left,
	.mainnav ul.right
	{
		display: none;
	}
}
*/
@include media("<desktop")
{
	/*
	nav
	{
		overflow: visible;
		.mainnav
		{
			//position: fixed;
			//top: 0;
			//bottom: 0;
			width: 100%;
			z-index: 3;
			overflow: hidden;
			overflow-y: auto;
			display: block;
			margin: 0;
			padding:100px 50px 50px 100px;
			background: #000;
			transform: translateX(-100%);
			transition: transform .25s ease-in-out;

			ul:first-child
			{
				display: none;
			}
		}

		.socialnav
		{
			position: fixed;
			top: 0;
			right: 0;
			z-index: 103;
			padding: 6px;
		}
	}

	.show-mainnav,
	.force-show-mainnav
	{
		nav .mainnav
		{
			transform: translateX(0);
		}
	}

	.page
	{
		.mainnav
		{
			padding-bottom:120px;
		}
	}
	*/
}
@include media("<tablet")
{
	.nav
	{
		height: 50px;
	}
	/*.nav__divider
	{
		position: absolute;
		top: 100%;
	}*/
}