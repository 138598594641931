.cc-window {
	background-color: $color-blue;
	color: $color-white;

	span.cc-message {
		flex: 1 1 auto;
		//max-width: 50%;
	}

	.cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
		flex: 1 1 auto;
	}

	a.button {
		height: auto;
	}

	.cc-btn {
		border-width: 0;
		white-space: nowrap;
	}
}
