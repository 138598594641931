.frame-type-textpic {
	.ce-outer,
	.ce-inner,
	.ce-column {
		// reset ridiculous presets
		float: none;
		right: auto;
	}

	.ce-outer,
	.ce-row {
		// use flex to position it
		display: flex;
		justify-content: flex-start;
	}

	.ce-center .ce-outer {
		justify-content: center;
	}

	.ce-right {
		.ce-outer,
		.ce-row {
			justify-content: flex-end;
		}
	}

	.ce-border {
		figure {
			padding: .5rem;
		}
		img {
			border: none;
			box-shadow: $box-shadow-images;
		}
	}

	// picture in text - not for small devices though
	@include media(">=phoneWide") {
		.ce-intext {
			&.ce-right,
			&.ce-left {
				.ce-gallery {
					display: inline-block;
				}
			}

			&.ce-left {
				.ce-gallery {
					float: left !important; // yeah, right - override another !important...
				}
			}
			&.ce-right {
				.ce-gallery {
					float: right !important; // yeah, right - override another !important...
				}
			}
		}
	}
}
