.accordion
{
	.accordion-toggle {
		display: inline-flex;
		align-items: center;

		width: auto;

		cursor: pointer;

		&:hover .icon_circle {
			background-color: $color-green-hover;
		}

		.icon_circle {
			width: 1em;
			height: 1em;

			margin-left: 1ex;

			&:before {
				font-size: 0.5em;
			}
		}
	}
	.accordion-content {
		display: none;
	}
	.accordion-content.default {
		display: block;
	}

	/* multi element accordion */
	.accordion-entry {
		margin-bottom: 10px;

		&:hover {
			box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.27);
		}

		&.entry-open {
			margin-bottom: 20px;
		}

		dt,
		dd {
			padding: 20px;

			@media (max-width: 768px) {
				padding: .8461538461538461em 1.1538461538461537em;
			}
		}

		dt {
			position: relative;
			cursor: pointer;
			color: #fff;
			font-size: 1.1em;
			text-align: left;

			i {
				display: inline-block;
				position: absolute;
				top: 2em;
				right: 2.4em;
				font-size: 0.5625rem;

				@media (max-width: 768px) {
					top: 1.5em;
					right: 2em;
				}
			}

			h3 {
				font-size: inherit;
				margin: 0 1.8em 0 0;
				padding: 0;
			}

			&.bg-green {
				background-color: #6BBA38;
			}

			&.bg-yellow {
				background-color: #F8AA00;
			}

			&.bg-blue-light {
				background-color: #31B4E4;
			}

			&.bg-orange {
				background-color: #EB6414;
			}

			&.bg-purple {
				background-color: #B17BAF;
			}

			&.bg-red-dark {
				background-color: #AE1C41;
			}

			&.bg-red-light {
				background-color: #EE868E;
			}

			&.bg-blue-dark {
				background-color: #0070A0;
			}
		}

		dt i.icon-Up_1 {
			display: none;
		}

		&.entry-open dt i.icon-Up_1 {
			display: inline-block;
		}

		&.entry-open dt i.icon-Down_1 {
			display: none;
		}

		dd {
			background-color: #F8F8F8;

			p:last-child {
				padding-bottom: 0;
			}

			.bg-light-gray &,
			.bg-light-green &,
			.bg-light-blue & {
				background-color: #fff;
			}
		}
	}
}
