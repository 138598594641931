.link
{
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.link__icon
{
	@include circle($background-color: transparent, $background-color-hover: $color-green, $color: $color-light-gray, $color-hover: $color-white);

	border: 1px solid $color-light-gray;

	&:hover,
	&:focus
	{
		fill: $color-white;
		border: 1px solid $color-green;
	}

	svg
	{
		display: block;
		width: 1rem;
		height: 1rem;
	}
}

.link
{

}

.link__icon + .link__label
{
	margin-left: 10px;
}
.link__label + .link__icon
{
	margin-left: 10px;
}
