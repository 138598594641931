a
{
	cursor: pointer;
	color:inherit;
	text-decoration: none;
}

p a
{
	text-decoration: underline;
	&:hover
	{
		color: $color-green;
	}
}

a.link--green
{
	display: inline-block;
	padding: 0 10px;
	color: $color-green;
}

a.button,
a.download,
button.button,
input.button,
button.btn-primary
{
	display: inline-block;
	margin: 0;
	padding: 0 24px;
	width: auto;
	height: 42px;
	background: $color-green;
	border-radius: 24px;
	border: none;
	color: $color-white;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	line-height: 42px;
	font-size: 13px;
	font-weight: 800;

	overflow: hidden;
	text-overflow: ellipsis;

	cursor: pointer;

	&:hover
	{
		color: $color-white;
		background: $color-green-hover;
	}
}

a.button--outlined
{
	display: inline-block;
	width: auto;
	border: 1px solid $color-green;
	border-radius: 24px;
	background: transparent;
	font-weight: 600;
	color: $color-green;

	&:hover
	{
		border-color: $color-green-hover;
		background: $color-green-hover;
		color: $color-white;
	}

	&.disabled {
		border-color: $color-green-transparent;
		background: transparent;
		color: $color-green-transparent;
		user-select: none;			/* CSS Basic User Interface Module Level 4 */

		&:hover {
			cursor: default;
		}
	}
}

a.button--blue,
button.button--blue
{
	background: $color-blue;

	&:hover
	{
		color: $color-white;
		background: $color-blue-hover;
	}
}

.link-target
{
	color: $color-green;
	text-transform: uppercase;
}

@include media("<=tabletWide") {
	a[href^="tel"] {
		text-decoration: underline;
	}
}
