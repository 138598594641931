.carousel-wrap
{
	position: relative;
	margin: 0 auto;
	padding: 0;
	width: 100%;
	max-width: 960px;
	display: block;

	.carousel
	{
		margin: 0 -20px 40px -20px !important;
		padding: 20px 0 20px 0 !important;
		display: flex;
		box-sizing: border-box;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		-webkit-touch-callout: none;
		-khtml-user-select: none;
		-ms-touch-action: pan-y;
		touch-action: pan-y;
		-webkit-tap-highlight-color: transparent;

		@include media("<tablet")
		{
			padding: 0 !important;
		}
	}

	.slick-list
	{
		position: relative;
		overflow: hidden;
		margin: 0 20px 0 0 !important;
		padding: 20px 10px 20px 10px !important;
		min-width: 100%;
	}

	.slick-track
	{
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		display: flex;
		flex-direction: row;
	}

	.slick-slide
	{
		position: relative;
		flex: 1 1;
		min-height: 1px;
		opacity: .7;

		&.slick-active,
		&:hover
		{
			opacity: 1;
		}

		.slide__inner
		{
			margin: 0 10px;
			height: 100%;
			background: #F8F8F8;
		}

		.slide__inner
		{
			.slide__inner__text
			{
				padding: 0 20px;
			}

			.tiles
			{
				position: relative;
				margin: 0;
			}

			.tiles__tile .tiles__tile__content
			{
				background: #F8F8F8;

				&:hover
				{
					background: #F3FFE5;
				}
			}
		}
	}

	.carousel--image-text
	{
		h2
		{
			font-size: 16px;
			font-weight: 900;
			line-height: 24px;
			color: $color-green;
		}

		h3
		{
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
		}
	}

	.carousel--image-text .slick-slide .slide__inner:hover
	{
		background: #FFF;
		box-shadow: 6px 6px 20px rgba(0,0,0,.18);
	}
}


.bg-light-blue .carousel-wrap .carousel--image-text .slick-slide .slide__inner:hover,
.bg-light-blue .carousel-wrap .carousel--image-text .slick-slide .slide__inner:hover .tiles__tile__content
{
	background: #f3ffe5;
}

/*
@include media("<desktop")
{
	.carousel-wrap .carousel
	{
		//margin: 0 -20px 40px -20px !important;
		//padding: 20px 0px 20px 0px !important;
	}
}
*/

@include media("<phoneWide")
{
	.carousel-wrap .slick-list
	{
		margin: 0 20px 0 0 !important;
		padding: 20px 40px 20px 40px !important;
	}

	.carousel-wrap .carousel--image-text h2,
	.carousel-wrap .carousel--image-text h3,
	.carousel-wrap .carousel--image-text p
	{
		font-size: 13px;
		line-height: 21px;
	}
}