.searchform
{
	position: relative;
	width: 100%;
	height: 60px;

	form
	{
		margin: 0;
		padding: 0;
	}

	input,
	button
	{
		margin: 0;
		padding: 0;
		border: 0 !important;
		outline: 0 !important;
		background: none;

		i
		{
			display: inline-block;
			margin-right: 10px;
		}
	}

	div
	{
		position: relative;
	}

	div.short
	{
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		height: 48px;
	}

	div.sword,
	div.submit
	{
		display: flex;
		flex: 1;
		align-items: center;
	}

	div.sword
	{
		flex: 1 1;
		background: $color-white;
		border-radius: 1.5rem 0 0 1.5rem;
		border: 1px solid $color-lighter-gray;
		padding: 0 20px;
	}

	div.submit
	{
		flex: 0 1 130px;

		text-align: center;

		@include media("<tablet") {
			flex: 0 1 3rem;

			span {
				display: none;
			}
		}

		button
		{
			width: 100%;
			height: 100%;

			margin: 0 auto;

			@include borderRoundedRight();

			border-color: $color-green;

			background: $color-green;

			font-weight: 900;
			font-size: 13px;

			text-transform: uppercase;

			color: $color-white;
		}
	}

	div.details
	{
		display: none;

		position: absolute;
		top: 100%;
		left: -20px;
		right: -20px;
		background: $color-white;
		color: #666;

		label
		{
			display: block;
			padding: 20px;
			text-align: left;

			input[type='checkbox']
			{
				display: inline-block;
				width: auto;
			}
		}
	}

	&.extended,
	&.active {
		@include media(">=tabletWide") {
			height: auto;
			padding: 30px;
			background-color: $color-white;
			box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16);
		}

		.details {
			@include media(">=tabletWide") {
				display: block;

				position: static;

				margin-top: 20px;

				select {
					width: 100%;
				}
			}
		}
	}
	&.extended {
		width: 100%;
	}
	&.active {
		box-shadow: 0 4px 8px rgba(0, 0, 0, 0.48);
	}

	&.startpage {
		width: 640px;
	}
}

@include media("<=phoneWide") {
	header#top .searchform {
		margin-bottom: 0.5rem;
	}
}
