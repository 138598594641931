
::-webkit-input-placeholder{text-transform:none;font-size:13px;}
::-moz-placeholder {text-transform:none;font-size:13px;}
:-ms-input-placeholder {text-transform:none;font-size:13px;}
:-moz-placeholder {text-transform:none;font-size:13px;}

input,
textarea {
	color: $color-gray;
}

input[type='text'],
input[type='email'],
textarea
{
	display: block;
	margin: 0 0 2rem;
	padding: .5rem;
	width: 100%;
	background: $color-white;
	border: 1px solid #AAA;
	border-top-color: $color-white;
	font-size: 14px;
	font-family: "Open Sans","Helvetica Neue",Helvetiva,Arial,sans-serif;

	@at-root {
		.pflicht#{&} {
			//border-color: $color-red;
		}
	}

	&:hover,
	&:focus
	{
		border: 1px solid #333;
	}
}
textarea
{
	height:10rem;
}
input[type='submit']
{
	outline:0;
	display: inline-block;
	margin:0 auto;
	width: auto;
	cursor:pointer;
	padding: 10px;
	background: $color-green;
	border:1px solid $color-green;
	border-radius: 4px;
	color: $color-white;
	text-transform: uppercase;
	transition: all .5s;

	&:hover
	{
		background: #666;
		border: 1px solid #666;
	}
}
fieldset
{
	border:0;
	outline:0;
	margin-bottom: 20px;
}
input[type='text'],
input[type='email'],
textarea {
	margin: 0;
	padding: 1.285714286em 1.785714286em;

	&:first-of-type {
		border-top-color: #AAA;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;

		&:hover, &:focus {
			border-top-color: #333;
		}
	}
	&:last-of-type {
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
	}
}

fieldset.submit
{
	text-align: center;

	input[type='text'],
	input[type='email']
	{
		position:absolute;
		top:-9876px;
		left:-9876px;
	}
}

// errors on validation
.pflicht {
	input[type='text'],
	input[type='email'],
	input[type='text']:first-of-type,
	input[type='email']:first-of-type,
	input[type='text']:first-of-type:focus,
	input[type='email']:first-of-type:hover,
	textarea {
		border-color: $color-red;
		color: $color-red;
	}
}

// textbox with error messages
.warnungpflichtfelder {
	display: none;
}
h3.warnungpflichtfelder {
	display: block;
}

div.errors
{
	padding:0 0 2rem;
}

div.errors ul
{
	margin:0 0 0 1rem;
}

img.tx-srfreecap-pi1-image
{
	width: auto;
}

fieldset.anrede {
	input[type='radio'] {
		display: inline-block;

		width: auto;
	}
}

// jQuery UI Datepicker Widget
.ui-datepicker-calendar {
	thead th:first-child {
		text-align: center;
	}
}

// default HTML-Dropdown: reverse :focus-setting
select {
	padding: .5rem;
}

// Kufer Fake select
$kuferFakeSelectToggleHeight: 1.6470588235294117rem;
$kuferFakeSelectToggleBorderWidth: 0.058823529411764705rem;
$kuferFakeSelectTogglePadding: 0.5rem;

.erweiterte-suche-fake-select {
	position: relative;

	min-width: 30ex;

	&.rounded {
		.erweiterte-suche-fake-select-content.open {
			top: $kuferFakeSelectToggleHeight + 2 * $kuferFakeSelectToggleBorderWidth + 2 * $kuferFakeSelectTogglePadding;
		}
	}
}

.erweiterte-suche-fake-select-toggle {
	// transparent style, default
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	width: 100%;
	height: $kuferFakeSelectToggleHeight; // 28px @ 17px font size, 27px line height + 1 px border bottom

	border-bottom: $kuferFakeSelectToggleBorderWidth solid $color-lighter-gray; // 1px @ 17px font size

	padding-right: 0.5em;
	padding-bottom: 0;
	padding-left: 0.5em;

	color: #666;

	cursor: pointer;

	i {
		margin-left: 2ex;

		font-size: 0.5625rem;
	}

	// input field style with rounded sides
	&.fake-select-toggle-rounded {
		height: $kuferFakeSelectToggleHeight + 2 * $kuferFakeSelectToggleBorderWidth + 2 * $kuferFakeSelectTogglePadding;

		border: $kuferFakeSelectToggleBorderWidth solid $color-lighter-gray;

		border-radius: 30px;

		margin-bottom: 0;
		padding: $kuferFakeSelectTogglePadding 20px;

		background: $color-white;
		color: $color-lighter-gray;
	}
}

.erweiterte-suche-fake-select-content {
	display: none !important;
	width: 100%;

	&.open {
		display: block !important;
		position: absolute;
		z-index: 999;

		top: $kuferFakeSelectToggleHeight + $kuferFakeSelectToggleBorderWidth;

		min-width: 30ex;

		border: 1px solid transparent;
		border-radius: 4px;
		box-shadow: $box-shadow-overlays;

		background: $color-white;
	}

	ul {
		@include fancyList();

		max-height: 324px;

		overflow-y: auto;

		li a {
			@include textWithIcon();
		}
	}
}

// checkboxes
.kw-hideable {
	flex-direction: row-reverse;
	justify-content: space-between;

	&,
	input,
	label {
		cursor: pointer;
	}

	input {
		margin-left: .5rem;
	}
}

form#kontaktformular {
	button {
		@extend .mt2;
	}
}
