.footer__social
{
	ul
	{
		display: flex;
		justify-content: flex-end;
		align-content: center;
	}

	li
	{

		display: flex;
		padding: 20px 0;
		list-style: none;
		align-content: center;

		@include media("<tablet")
		{
			padding: 10px 0;
		}
	}

	li a
	{
		img,
		svg
		{
			width: 20px;
			height: 20px;
			max-width: inherit;
			max-height: inherit;
		}
	}
}

.footer__social__icon
{
	display: inline-block;
	width: 42px;
	height: 42px;
	border-radius: 100%;
	color: #FFF;
	text-align: center;
	font-size: 14px;
}

.toggle-box.active .footer__social__icon,
.footer__social__icon:hover
{
	color: #FFF;
	background: $color-green;
	box-shadow: 0 2px 4px rgba(0,0,0,.16);
}

.footer__social
{
	label
	{
		position: relative;
	}
}

.footer__social__label
{
	padding-left: 20px;
	span
	{
		display: inline-block;
		padding-right: 10px;

		@include media("<tablet") {
			display: none;
		}
		@include media("<=tabletExtraWide") {
			color: $color-light-gray;
		}
	}
	@include media("<=tabletExtraWide") {
		padding-left: 0;
	}
}

.footer__social__share
{
	position: absolute;
	display: none;
}

.footer__social input
{
	position: absolute;
	left: -9999px;
}

.footer__social__share
{
	z-index: 100;
	top: -90px;
	right: -4px;
	display: none;
	padding: 5px 10px;
	width: 154px;
	background: #FFF;
	box-shadow: 6px 6px 16px rgba(0,0,0,.18);
	border-radius: 10px;
	text-align: center;
	line-height: 42px;
	white-space: nowrap;

	&:before
	{
		position: absolute;
		right: 15px;
		bottom: -5px;
		z-index: 90;
		display: block;
		width: 20px;
		height: 20px;
		background: #FFF;
		transform: rotate(45deg);
		content: "";
	}

	a.button-icon
	{
		position: relative;
		z-index: 91;
		color: #BBB;
		display: inline-block;
		width: 42px;
		height: 42px;
		border-radius: 100%;
		font-size: 14px;
		font-weight: normal;

		&:hover
		{
			color: #FFF;
			background: $color-green;
			box-shadow: 0 2px 4px rgba(0,0,0,.16);
		}
	}
}
.footer__social input:checked + .footer__social__share
{
	display: block;
}
.footer__social input:checked
{
	background: $color-green;
	border-color: $color-green;
}
