.course-list-item {
	position: relative;
	display: flex;
	width: 100%;
	margin-bottom: 10px;

	background-color: $color-white;

	@include media("<=tablet") {
		flex-direction: column;
	}

	&:hover {
		box-shadow: $box-shadow;

		@include media(">=desktop") {
			.course-list-item-content.course-list-item-content { // increase selector specificity through class repetition
				background-color: $color-light-green-transparent;
			}

		}

		.course-list-item-info .course-list-item-info-overlay {
			display: flex;
		}

		.course-list-item-badge {
			box-shadow: $box-shadow;
		}
	}

	.course-list-item-content {
		flex: 1 1 68%;
		padding: $default-padding;
		background-color: #fff;

		@include media("<=tablet") {
			padding: .8461538461538461em 3.5em 0 1.1538461538461537em;
		}

		.course-list-item-title {
			margin-bottom: 14px;
			line-height: 1.6875em;
			font-weight: 400;

			@include media("<=tablet") {
				margin-bottom: 0.35em;
				font-size: 0.8125rem;
				font-weight: bold;
				line-height: 1.35em;
			}

			span {
				&.title {
					font-size: 1.15em;

					@include media("<=tablet") {
						font-size: 1em;
					}
				}
				&.subtitle {
					@include media("<=tablet") {
						display: none;
					}
				}
			}
		}
	}

	.course-list-item-info {
		display: flex;
		flex: 1 1 32%;
		flex-direction: column;
		justify-content: start;
		padding: $default-padding;
		font-size: 14px;

		@include media(">tablet") {
			position: relative;
			background-color: $color-green;
		}
		@include media("<=tablet") {
			padding: 0 3.5em .8461538461538461em 1.1538461538461537em;

			font-size: .8125rem;
			line-height: 1.6153846153846154em;
		}

		.course-list-item-info-overlay {
			@include linkOverlay;
			z-index: 1;
			@include centerContentVertically;
			padding: 0;
			background-color: $color-green-hover;

			@include media(">tablet") {
				display: none;
			}
			@include media("<=tablet") {
				left: auto;

				width: 3.075em;

				background-color: $color-green;
			}

			p {
				padding: 0;
				text-align: center;

				text-transform: uppercase;
				color: $color-white;
				font-size: 13px;
				font-weight: 900;

				span {
					@include media("<=tablet") {
						display: none;
					}
				}

				i[class^="icon-"] {
					@include iconHover;

					@include media("<=tablet") {
						&:before {
							left: 0;
						}
					}
				}
			}
		}

		.course-list-item-time,
		.course-list-item-location {
			@include media(">tablet") {
				font-weight: 900;

				color: $color-white;
			}

			span {
				font-weight: 400;
			}
		}

		.course-list-item-price {
			@include media(">tablet") {
				font-weight: 900;
			}

			span {
				font-weight: 400;
			}
		}
		
		& > div {
			position: relative;
			padding-left: 1.714285714em;
			
			& > i {
				@include iconOutOfFlow;

				color: $color-white-transparent;

				@include media("<=tablet") {
					color: $color-lighter-gray;
				}
			}
		}
	}

	.course-list-item-badge {
		position: absolute;
		top: -5px;
		right: -5px;
		z-index: 3;

		@include media("<=tablet") {
			top: auto;
			bottom: .8461538461538461em;
		}

		.link-wrapper {
			position: relative;
		}
	}

	&.danger {
		.course-list-item-content {
			background-color: #E3E3E3;
			color: #555555;
		}
		.course-list-item-info {
			background-color: #C6C6C6;

			@include media("<=tablet") {
				background-color: #e3e3e3;
			}

			.course-list-item-time,
			.course-list-item-location,
			.course-list-item-price,
			.course-list-item-duration,
			.course-list-item-discount {
				color: $color-gray;

				i {
					color: $color-light-gray;
				}

				@include media("<=tablet") {

				}
			}
		}
	}

	& > a {
		@include linkOverlay;
	}
}

.course-list-item-categories {
	display: flex;
	flex-wrap: wrap;

	.course-list-item-category {
		z-index: 3;
		display: inline-block;
		margin-right: $default-margin;
		color: $color-green;
		font-size: 14px;

		@include media("<=tablet") {
			display: none;
		}

		.icon-Tag {
			color: $color-light-gray;
		}
	}
}

.course-list-item-badge {
	display: inline-block !important;
	width: auto !important;
	padding: 6px 9px;
	background-color: #F8AA00;
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	line-height: 1em;
	text-transform: uppercase;
}

.danger {
	.course-list-item-info {
		.course-list-item-info-overlay {
			@include media("<=tablet") {
				background-color: #c6c6c6;
			}

			p i[class*=icon-] {
				color: $color-light-gray;

				@include media("<=tablet") {
					color: $color-gray;
				}
			}
		}
	}
	.course-list-item-badge {
		background-color: #CD1316;
	}
}

// add border around badge in listings only
.course-list-item {
	.course-list-item-badge {
		border-bottom: 2px solid #fff;
		border-left: 2px solid #fff;

		@include media("<=tablet") {
			font-size: .625rem;
		}
	}
}

.tag-cooperation {
	padding: 0;
	font-size: .75em;
	font-weight: $font-weight-bold;
	text-transform: uppercase;
	color: $color-blue;
}
