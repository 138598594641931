.nav__button {display: none}

@include media("<desktop")
{
	.nav__button
	{
		position: fixed;
		top: 0;
		right: 0;
		z-index: 305;
		display: block;
		padding: 35px 10px;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		color: #888;

		.nav__button__icon
		{
			display: inline-block;
			float: left;
			padding: 2px 0 0 0;
			white-space: nowrap;
			span
			{
				height: 2px;
				width: 24px;
				display: block;
				background: #888;
				margin-bottom: 5px;
				transition: all 0.2s ease-out;

				&:last-child
				{
					margin-bottom: 0;
				}
			}
		}
		.nav__button__label
		{
			display: none;
			font-size: 13px;
			line-height: 20px;
			font-weight: 600;
			letter-spacing: 0.15em;
			color: #FFF;
			float: left;
			padding: 0 12px;
			text-transform: uppercase;
		}
	}

	body.force-show-nav__mobile{overflow: hidden;}

	//body.show-mainnav__button,
	//body.force-show-nav__mobile
	body.show-mainnav__button
	{
		.nav__button
		{
			display: block;
		}
	}

	//body.show-mainnav.show-mainnav__button,
	//body.force-show-nav__mobile
	body.show-mainnav.show-mainnav__button
	{
		.nav__button
		{
			top: 0 !important;
			right: 7px !important;
		}

		.nav__button__icon span:first-child
		{
			transform: translateY(7px) rotate(-45deg);
		}

		.nav__button__icon span:nth-child(2)
		{
			opacity: 0;
		}

		.nav__button__icon span:last-child
		{
			transform: translateY(-7px) rotate(45deg);
		}
	}
}

//@include media("<tablet")
//{
//	.nav__button
//	{
//		padding: 10px;
//	}
//	.force-show-nav__mobile
//	{
//		.nav__button
//		{
//			padding-top: 35px;
//		}
//	}
//}
