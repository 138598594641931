footer
{
	position: relative;
	z-index: 20;
	background: #333;
	color: #FFF;
	font-size: 14px;
	line-height: 42px;
	font-weight: normal;

	.container-inner
	{
		max-width: 1280px;
	}

	a
	{
		color: #FFF;
		text-decoration: none;
	}

	.skiplink
	{
		position: absolute;
		top: -70px;
		right: 10px;
		z-index: 99;
	}

	.footer__nav
	{
		.container-inner
		{

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-content: center;
			padding: 0;
			font-size: 15px;

			& > a,
			& > div
			{
				flex: 1 1;
			}
		}
	}

	.footer__copyright
	{
		padding: 0;
		background: $color-green;
		text-align: right;
		font-size: 12px;

		.container-inner
		{
			padding: 0 20px;
		}
	}
}
