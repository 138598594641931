// non-colors
$color-black:			#000;
$color-white:			#fff;
$color-gray:			#777;

$color-darker-gray:     #888;

$color-dark-gray:		#333;
$color-dark-gray-transparent: rgba($color-dark-gray, .13);

$color-light-gray:		#999;
$color-lighter-gray:	#bbb;
$color-lighterer-gray:	#ddd;
$color-almost-lightest-gray: #f8f8f8;
$color-lightest-gray:	#fbfbfb;

$color-black-transparent-lightest: rgba($color-black, .08);
$color-black-transparent: rgba($color-black, .27);
$color-white-transparent: rgba($color-white, .45);


// colors
$color-dark-blue:		#005DAF;

$color-cyan:			#097DBD;
$color-orange:			#F8AA00;
$color-green:			#6BBA38;
$color-blue:			#005DAF;
$color-red: 			#CD1316;

$color-light-blue:		#E3F2FD;
$color-light-green:		#B2FF59;
$color-light-green-transparent: rgba($color-light-green, 0.15);

$color-lighter-red:		#E23F30;

$color-lightest-green:	#F3FFE5;
$color-lightest-blue:	#e5f2f8;
$color-lightest-red:	#fceee9;

$color-green-hover:     #89C860;
$color-blue-hover:		#2776B6;

$color-green-transparent: rgba($color-green, .66);

// shadows
$box-shadow: 4px 4px 12px 0 $color-black-transparent;
$box-shadow-images: 0px 2px 5px 0 $color-black-transparent;
$box-shadow-overlays: 8px 8px 16px 0 $color-black-transparent-lightest;
