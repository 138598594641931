/* body class and modal */
.modal-opened {
	overflow: hidden;

	&:before
	{
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		background: rgba(0,0,0,.6);
		content: "";
	}

	.nav__button
	{
		display: block;
	}

	.nav__button
	{
		top: 0 !important;
		right: 7px !important;
	}

	.nav__button__icon span:first-child
	{
		transform: translateY(7px) rotate(-45deg);
	}

	.nav__button__icon span:nth-child(2)
	{
		opacity: 0;
	}

	.nav__button__icon span:last-child
	{
		transform: translateY(-7px) rotate(45deg);
	}
}

@include media("<tablet")
{
	.nav__button
	{
		padding: 10px;
	}
	.modal-opened
	{
		.nav__button
		{
			padding-top: 35px;
		}
	}
}

.modal {
	display: none;

	&.modal-open {
		display: block;
	}
	//.modal-content {
	//	background-color: $color-white;
	//}
}

.modal-content {
	display: block;
	position: fixed;
	top: 10px;
	right: 0;
	left: 10px;
	max-height: 100%;
	z-index: 300;
	margin: 0;
	padding: 50px 0 0;
	text-align: left;
	background: $color-white;
	box-shadow: 6px 6px 20px rgba(0,0,0,.18);
	overflow-y: auto;

	&:before
	{
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 6px;
		content: " ";
		background: url(#{$path-images}par-layout-border-top@2x.png) left center;
		background-size: contain;
	}
}

/* search filter buttons */
.mobile-search-buttons {
	text-align: right;

	& > div {
		display: inline-block;

		cursor: pointer;
	}
}

/* search filter modals */
//.modal-mobile-search-filter

@include media(">=tablet") {
	.column .mobile-search-buttons.mobile-search-buttons {
		display: none;
	}
}
