@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);

  or object-fit and object-position:
  @include object-fit(cover, top);
*/
/*
$desktop-wide: 1600px;
$desktop: 1200px;
$tablet-wide: 960px;
$tablet: 768px;
$phone-wide: 480px;
$phone: 320px;
$mobile: 320px;
*/
@media (min-width: 320px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": false}, "phoneWide":{"value": "480px", "active": false}, "tablet":{"value": "768px", "active": false}, "tabletWide":{"value": "960px", "active": false}, "tabletExtraWide":{"value": "1024px", "active": false}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 375px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": false}, "tablet":{"value": "768px", "active": false}, "tabletWide":{"value": "960px", "active": false}, "tabletExtraWide":{"value": "1024px", "active": false}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 480px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": false}, "tabletWide":{"value": "960px", "active": false}, "tabletExtraWide":{"value": "1024px", "active": false}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 768px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": true}, "tabletWide":{"value": "960px", "active": false}, "tabletExtraWide":{"value": "1024px", "active": false}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 960px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": true}, "tabletWide":{"value": "960px", "active": true}, "tabletExtraWide":{"value": "1024px", "active": false}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 1024px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": true}, "tabletWide":{"value": "960px", "active": true}, "tabletExtraWide":{"value": "1024px", "active": true}, "desktop":{"value": "1200px", "active": false}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 1200px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": true}, "tabletWide":{"value": "960px", "active": true}, "tabletExtraWide":{"value": "1024px", "active": true}, "desktop":{"value": "1200px", "active": true}, "desktopWide":{"value": "1600px", "active": false}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

@media (min-width: 1600px) {
  body::after {
    content: '{"phone":{"value": "320px", "active": true}, "phoneBig":{"value": "375px", "active": true}, "phoneWide":{"value": "480px", "active": true}, "tablet":{"value": "768px", "active": true}, "tabletWide":{"value": "960px", "active": true}, "tabletExtraWide":{"value": "1024px", "active": true}, "desktop":{"value": "1200px", "active": true}, "desktopWide":{"value": "1600px", "active": true}}';
    display: block;
    height: 0;
    overflow: hidden;
    width: 0; } }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

:focus {
  outline: 0; }

/*
@font-face
{
	font-family: "Icons";
	src:url(../Fonts/icomoon.eot);
	src:url(../Fonts/icomoon.eot?#iefix) format('embedded-opentype'), url(../Fonts/icomoon.woff) format('woff'), url(../Fonts/icomoon.ttf) format('truetype'), url(../Fonts/icomoon.svg#robotoicomoon) format('svg');
	font-weight: normal;
	font-style: normal;
}*/
@font-face {
  font-family: 'paricons';
  src: url("../Fonts/paricons.eot?66640400");
  src: url("../Fonts/paricons.eot?66640400#iefix") format("embedded-opentype"), url("../Fonts/paricons.woff2?66640400") format("woff2"), url("../Fonts/paricons.woff?66640400") format("woff"), url("../Fonts/paricons.ttf?66640400") format("truetype"), url("../Fonts/paricons.svg?66640400#paricons") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'paricons';
    src: url('../Fonts/paricons.svg?66640400#paricons') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  display: inline-block;
  position: relative;
  top: -0.083333333em;
  margin-right: 0.166666667em;
  font-family: "paricons";
  font-style: normal;
  font-weight: normal;
  speak: none;
  text-decoration: inherit;
  width: auto;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-Attention_1:before {
  content: '\e900'; }

/* '' */
.icon-Attention_2:before {
  content: '\e901'; }

/* '' */
.icon-Calendar:before {
  content: '\e902'; }

/* '' */
.icon-Check_1:before {
  content: '\e903'; }

/* '' */
.icon-Check_2:before {
  content: '\e904'; }

/* '' */
.icon-Close:before {
  content: '\e905'; }

/* '' */
.icon-Contact:before {
  content: '\e906'; }

/* '' */
.icon-Down_1:before {
  content: '\e907'; }

/* '' */
.icon-Edit:before {
  content: '\e908'; }

/* '' */
.icon-Email:before {
  content: '\e909'; }

/* '' */
.icon-Euro:before {
  content: '\e90a'; }

/* '' */
.icon-Facebook:before {
  content: '\e90b'; }

/* '' */
.icon-Filter:before {
  content: '\e90c'; }

/* '' */
.icon-Info_1:before {
  content: '\e91c'; }

/* '' */
.icon-Info_2:before {
  content: '\e91d'; }

/* '' */
.icon-Instagram:before {
  content: '\e91e'; }

/* '' */
.icon-Left_1:before {
  content: '\e91f'; }

/* '' */
.icon-Left_2:before {
  content: '\e920'; }

/* '' */
.icon-Location:before {
  content: '\e921'; }

/* '' */
.icon-Lock:before {
  content: '\e922'; }

/* '' */
.icon-LogIn:before {
  content: '\e923'; }

/* '' */
.icon-Menu:before {
  content: '\e924'; }

/* '' */
.icon-Message:before {
  content: '\e925'; }

/* '' */
.icon-Person:before {
  content: '\e926'; }

/* '' */
.icon-Phone:before {
  content: '\e927'; }

/* '' */
.icon-Plus_1:before {
  content: '\e928'; }

/* '' */
.icon-Plus_2:before {
  content: '\e929'; }

/* '' */
.icon-Plus_3:before {
  content: '\e92a'; }

/* '' */
.icon-Price:before {
  content: '\e92b'; }

/* '' */
.icon-Radius:before {
  content: '\e92c'; }

/* '' */
.icon-Reight_1:before {
  content: '\e92d'; }

/* '' */
.icon-Right_2:before {
  content: '\e92e'; }

/* '' */
.icon-Search:before {
  content: '\e92f'; }

/* '' */
.icon-Share:before {
  content: '\e930'; }

/* '' */
.icon-Shopping:before {
  content: '\e931'; }

/* '' */
.icon-Sort:before {
  content: '\e932'; }

/* '' */
.icon-Tag:before {
  content: '\e933'; }

/* '' */
.icon-Trash:before {
  content: '\e934'; }

/* '' */
.icon-Twitter:before {
  content: '\e935'; }

/* '' */
.icon-Up_1:before {
  content: '\e936'; }

/* '' */
.icon-Warning:before {
  content: '\e937'; }

/* '' */
@font-face {
  font-family: "Open Sans";
  src: url(../Fonts/opensans-bold-webfont.woff2) format("woff2"), url(../Fonts/opensans-bold-webfont.woff) format("woff"), url(../Fonts/opensans-bold-webfont.ttf) format("truetype"), url(../Fonts/opensans-bold-webfont.svg#robotoopensans-bold-webfont) format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url(../Fonts/opensans-semibold-webfont.woff2) format("woff2"), url(../Fonts/opensans-semibold-webfont.woff) format("woff"), url(../Fonts/opensans-semibold-webfont.ttf) format("truetype"), url(../Fonts/opensans-semibold-webfont.svg#robotoopensans-semibold-webfont) format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url(../Fonts/opensans-regular-webfont.woff2) format("woff2"), url(../Fonts/opensans-regular-webfont.woff) format("woff"), url(../Fonts/opensans-regular-webfont.ttf) format("truetype"), url(../Fonts/opensans-regular-webfont.svg#robotoopensans-regular-webfont) format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Open Sans";
  src: url(../Fonts/opensans-light-webfont.woff2) format("woff2"), url(../Fonts/opensans-light-webfont.woff) format("woff"), url(../Fonts/opensans-light-webfont.ttf) format("truetype"), url(../Fonts/opensans-light-webfont.svg#robotoopensans-light-webfont) format("svg");
  font-weight: 100;
  font-style: normal; }

.page {
  position: relative;
  overflow-x: hidden;
  z-index: 1;
  height: 100%; }

main {
  position: relative;
  z-index: 20;
  margin: 0;
  min-height: calc(100vh - 228px);
  background: #FFF; }

body .section-inner {
  position: relative;
  overflow: hidden;
  height: 100%;
  padding: 0; }

.container-outer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0px; }

.container-inner {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0; }

.content-wrap {
  position: relative;
  z-index: 9;
  margin: 0 auto;
  /* prevent childrens vertical margins to affect siblings of their parent */
  padding-top: 0.001rem;
  padding-bottom: 0.001rem; }

.container-inner > .container-inner {
  position: relative;
  overflow: hidden;
  padding-left: 0;
  padding-right: 0; }

.container-inner.headline {
  display: block; }

.container-inner.headline > div {
  flex: 0; }

.container-inner.headline h1, .container-inner.headline h2 {
  padding-bottom: 0; }

.page__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -72px;
  left: 30%;
  z-index: 80;
  width: 145px;
  height: 145px;
  background: #FFF;
  border-radius: 100%; }
  .page__icon svg {
    width: 60%;
    height: 60%;
    fill: #6BBA38; }

.no-header .page__icon {
  display: none; }

@media (max-width: 767px) {
  .page__icon {
    display: none; } }

.nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 130px;
  background: #FFF;
  z-index: 50;
  overflow: visible; }
  .nav a {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    text-decoration: none; }
  .nav ul, .nav li {
    display: flex;
    list-style: none; }
  .nav > .container-inner {
    max-width: 1280px; }

.nav__divider {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 10px;
  background: #6BBA38; }
  .nav__divider:before {
    display: block;
    width: 100%;
    height: 6px;
    content: " ";
    background: url(../Images/par-layout-border-top@2x.png) center;
    background-size: cover; }

/*
@include media(">tablet-wide")
{
	body.page-1
	{
		nav:before
		{
			display: none;
		}
	}
}

@include media(">tablet-wide")
{
	.mainnav ul.left,
	.mainnav ul.right
	{
		display: none;
	}
}
*/
@media (max-width: 1199px) {
  /*
	nav
	{
		overflow: visible;
		.mainnav
		{
			//position: fixed;
			//top: 0;
			//bottom: 0;
			width: 100%;
			z-index: 3;
			overflow: hidden;
			overflow-y: auto;
			display: block;
			margin: 0;
			padding:100px 50px 50px 100px;
			background: #000;
			transform: translateX(-100%);
			transition: transform .25s ease-in-out;

			ul:first-child
			{
				display: none;
			}
		}

		.socialnav
		{
			position: fixed;
			top: 0;
			right: 0;
			z-index: 103;
			padding: 6px;
		}
	}

	.show-mainnav,
	.force-show-mainnav
	{
		nav .mainnav
		{
			transform: translateX(0);
		}
	}

	.page
	{
		.mainnav
		{
			padding-bottom:120px;
		}
	}
	*/ }

@media (max-width: 767px) {
  .nav {
    height: 50px; }
  /*.nav__divider
	{
		position: absolute;
		top: 100%;
	}*/ }

.nav__logo {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 72;
  display: block;
  padding: 26px 10px;
  width: 272px;
  text-align: center; }
  .nav__logo img {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  @media (max-width: 767px) {
    .nav__logo {
      width: 166px;
      padding: 5px; } }

.nav__button {
  display: none; }

@media (max-width: 1199px) {
  .nav__button {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 305;
    display: block;
    padding: 35px 10px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    color: #888; }
    .nav__button .nav__button__icon {
      display: inline-block;
      float: left;
      padding: 2px 0 0 0;
      white-space: nowrap; }
      .nav__button .nav__button__icon span {
        height: 2px;
        width: 24px;
        display: block;
        background: #888;
        margin-bottom: 5px;
        transition: all 0.2s ease-out; }
        .nav__button .nav__button__icon span:last-child {
          margin-bottom: 0; }
    .nav__button .nav__button__label {
      display: none;
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      letter-spacing: 0.15em;
      color: #FFF;
      float: left;
      padding: 0 12px;
      text-transform: uppercase; }
  body.force-show-nav__mobile {
    overflow: hidden; }
  body.show-mainnav__button .nav__button {
    display: block; }
  body.show-mainnav.show-mainnav__button .nav__button {
    top: 0 !important;
    right: 7px !important; }
  body.show-mainnav.show-mainnav__button .nav__button__icon span:first-child {
    transform: translateY(7px) rotate(-45deg); }
  body.show-mainnav.show-mainnav__button .nav__button__icon span:nth-child(2) {
    opacity: 0; }
  body.show-mainnav.show-mainnav__button .nav__button__icon span:last-child {
    transform: translateY(-7px) rotate(45deg); } }

@media (min-width: 1201px) {
  .nav__main {
    position: relative;
    z-index: 70;
    display: block;
    margin: 0;
    padding: 0;
    height: 110px;
    text-align: right; }
    .nav__main a {
      display: inline-block;
      padding: 0 10px; }
    .nav__main > ul {
      justify-content: flex-end; }
    .nav__main ul {
      max-width: none; }
    .nav__main li {
      display: inline-block; }
      .nav__main li.active > a {
        color: #333; }
      .nav__main li:hover > .container-inner {
        position: absolute;
        top: 120px;
        left: 0;
        right: 0;
        display: block;
        padding: 50px 0;
        background: #E3F2FD; }
        .nav__main li:hover > .container-inner:before {
          position: absolute;
          top: 0;
          left: -2000px;
          right: -2000px;
          bottom: 0;
          background: #E3F2FD;
          content: ""; }
      .nav__main li.shopping a span.description {
        display: none; }
    .nav__main ul.l1 > li > a {
      padding: 47px 11px 60px;
      text-transform: uppercase; }
    .nav__main ul.l1 > li:hover > a {
      color: #6BBA38;
      font-weight: 900; }
    .nav__main ul.l1 > li.active > a {
      font-weight: 900; }
    .nav__main ul.l1 > li.sub .nav__main__trigger_l2 {
      position: relative;
      top: -4px;
      display: inline-block; }
      .nav__main ul.l1 > li.sub .nav__main__trigger_l2:after {
        font-family: "paricons";
        content: '\e907';
        font-size: 6px; }
    .nav__main ul.l2 {
      position: relative;
      flex-wrap: wrap;
      justify-content: flex-start;
      margin: 0 -20px; }
      .nav__main ul.l2 li {
        flex: 0 1 25%;
        padding: 0;
        text-align: left;
        max-width: 25%; }
        .nav__main ul.l2 li .texticon,
        .nav__main ul.l2 li .svgicon {
          position: relative;
          overflow: hidden;
          display: flex;
          margin: 10px;
          padding: 0;
          height: 100px;
          align-content: center;
          align-items: center;
          justify-content: center; }
        .nav__main ul.l2 li a {
          position: relative;
          overflow: hidden;
          display: flex;
          margin: 10px;
          padding: 0;
          white-space: normal;
          align-content: center;
          align-items: center;
          justify-content: center; }
        .nav__main ul.l2 li .texticon {
          padding: 0 0px; }
          .nav__main ul.l2 li .texticon a {
            border-radius: 24px;
            background: #6BBA38;
            color: #FFF;
            text-transform: uppercase;
            width: 100%;
            text-align: center;
            font-weight: 900; }
          .nav__main ul.l2 li .texticon .title-wrap {
            padding: 15px;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -webkit-hyphens: auto;
            -moz-hyphens: auto;
            hyphens: auto; }
        .nav__main ul.l2 li .svgicon {
          background: #FFF; }
          .nav__main ul.l2 li .svgicon .title-wrap {
            flex: 1 0;
            padding: 0 0 0 10px; }
          .nav__main ul.l2 li .svgicon .icon-wrap-outer {
            flex: 0 1 30%; }
          .nav__main ul.l2 li .svgicon .icon-wrap-inner {
            display: flex;
            width: 100%;
            height: 100%;
            align-content: center;
            justify-content: center; }
          .nav__main ul.l2 li .svgicon svg {
            display: block;
            max-width: 60%;
            max-height: 60%;
            fill: #6BBA38; }
          .nav__main ul.l2 li .svgicon:hover {
            background: #6BBA38;
            box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.27);
            color: #FFF; }
            .nav__main ul.l2 li .svgicon:hover svg {
              fill: #CBE7B9; }
            .nav__main ul.l2 li .svgicon:hover a {
              color: #FFF;
              font-weight: 900; }
    .nav__main .container-inner {
      display: none; } }

@media (max-width: 1199px) {
  .nav__main {
    display: none; } }

.nav__meta ul {
  display: flex;
  word-spacing: 0.1rem; }

.nav__meta li {
  display: flex;
  padding: 0;
  list-style: none;
  align-content: flex-end; }

.nav__meta a {
  padding: 20px 10px;
  text-transform: uppercase; }
  .nav__meta a:hover {
    color: #6BBA38; }

@media (max-width: 768px) {
  .nav__meta a {
    padding: 2px 10px; } }

@media (max-width: 959px) {
  .nav__meta ul {
    flex-direction: column; } }

.nav__second {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 103;
  padding: 6px;
  background: #F8F8F8; }
  .nav__second a {
    font-size: 12px; }
  @media (max-width: 1199px) {
    .nav__second {
      display: none; } }

.nav .searchform {
  display: none;
  position: absolute;
  top: 13px;
  left: 270px;
  z-index: 200;
  margin: 0 !important;
  padding-top: 15px !important;
  height: 48px; }
  @media (max-width: 1199px) {
    .nav .searchform {
      max-width: 940px; } }
  .nav .searchform .title {
    font-weight: 900; }
  .nav .searchform .details .erweiterte-suche-fake-select-toggle,
  .nav .searchform .details .erweiterte-suche-fake-select-content {
    font-size: .875rem; }
  .nav .searchform .details label {
    padding-right: 0;
    padding-left: 0; }
  .nav .searchform .erweiterte-suche-fake-select-toggle i {
    font-size: 1rem; }
  .nav .searchform .erweiterte-suche-fake-select-toggle i.icon-Down_1 {
    font-size: .4rem; }
  .nav .searchform .erweiterte-suche-fake-select-toggle.active {
    font-weight: 900; }
  .nav .searchform .erweiterte-suche-fake-select {
    min-width: 25ex; }
    .nav .searchform .erweiterte-suche-fake-select.open {
      box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.08); }
    .nav .searchform .erweiterte-suche-fake-select.kw-katkategorielist {
      min-width: 30ex; }
  .nav .searchform .erweiterte-suche-filter {
    border-top: none; }
  .nav .searchform .kw-katexceptkennzlist li {
    flex-direction: row;
    padding-left: 0;
    background-color: #fff; }
    .nav .searchform .kw-katexceptkennzlist li:hover {
      background-color: #fff; }
    .nav .searchform .kw-katexceptkennzlist li input {
      margin-right: .875rem; }
  .nav .searchform .kw-katumkreisfilterlist li {
    display: block;
    padding: 1.1764705882352942rem; }
  .nav .searchform .tooltip {
    display: none; }
  .nav .searchform #katexceptkennzfilter_1 {
    display: none; }
  .nav .searchform:not(.active) {
    width: 232px;
    left: 300px; }
    .nav .searchform:not(.active) div.sword {
      border-width: 1px 0 1px 1px; }
    .nav .searchform:not(.active) div.submit {
      flex: 0 1 40px;
      border-radius: 0 1.5rem 1.5rem 0;
      border: 1px solid #bbb;
      border-left: none; }
      .nav .searchform:not(.active) div.submit button {
        background-color: #fff;
        color: #6BBA38; }
      .nav .searchform:not(.active) div.submit span {
        display: none; }
    .nav .searchform:not(.active) .details {
      display: none; }

@media (max-width: 1024px) {
  .nav__search.searchform,
  nav .searchform {
    display: none !important; } }

.nav__breadcrumps {
  position: absolute;
  top: 130px;
  left: 0;
  right: 0;
  z-index: 51;
  padding: 10px 0 0;
  font-size: 12px;
  font-weight: 400; }
  .nav__breadcrumps > .container-inner {
    max-width: 1280px;
    padding-left: 10px; }
  .nav__breadcrumps a {
    font-size: 12px; }
  .nav__breadcrumps .nav__breadcrumps__label,
  .nav__breadcrumps ul,
  .nav__breadcrumps li,
  .nav__breadcrumps a {
    display: inline-block;
    margin: 0;
    padding: 0; }
  .nav__breadcrumps .nav__breadcrumps__label,
  .nav__breadcrumps a {
    padding-right: 10px; }
  .nav__breadcrumps li:after {
    display: inline-block;
    padding-right: 10px;
    content: " > "; }
  .nav__breadcrumps li:last-child:after {
    display: none; }

header#top + .page__divider .nav__breadcrumps {
  color: #DDD; }
  header#top + .page__divider .nav__breadcrumps a {
    color: #DDD; }

.nav__breadcrumps--dark {
  color: #333 !important; }
  .nav__breadcrumps--dark a {
    color: #333 !important; }

@media (max-width: 1199px) {
  .nav__breadcrumps {
    display: none; } }

.nav__mobile {
  display: none;
  overflow-y: auto; }

@media (max-width: 1199px) {
  .force-show-nav__mobile .nav__mobile {
    display: block; }
  .force-show-nav__mobile:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    content: ""; }
  .nav__mobile {
    position: fixed;
    top: 10px;
    right: 0;
    max-height: 100%;
    max-width: 50ex;
    z-index: 300;
    margin: 0;
    padding: 50px 0 0;
    text-align: left;
    background: #FFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.18); }
    .nav__mobile:before {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      height: 6px;
      content: " ";
      background: url(../Images/par-layout-border-top@2x.png) left center;
      background-size: contain; }
    .nav__mobile a {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 11px 0;
      font-size: 13px;
      font-weight: 400;
      text-transform: uppercase; }
    .nav__mobile ul {
      display: block;
      max-width: none; }
    .nav__mobile ul.l2 {
      display: none; }
    .nav__mobile .container-inner {
      max-width: none; }
    .nav__mobile .nav__main,
    .nav__mobile .nav__second,
    .nav__mobile .nav__meta,
    .nav__mobile .footer__social,
    .nav__mobile .footer__copyright {
      position: static;
      display: block; }
    .nav__mobile .nav__main,
    .nav__mobile .nav__second {
      display: block;
      padding: 20px; }
      .nav__mobile .nav__main a,
      .nav__mobile .nav__second a {
        color: #333; }
      .nav__mobile .nav__main li.active > a,
      .nav__mobile .nav__second li.active > a {
        color: #333;
        font-weight: 900; }
      .nav__mobile .nav__main a:hover,
      .nav__mobile .nav__second a:hover {
        color: #6BBA38; }
      .nav__mobile .nav__main li,
      .nav__mobile .nav__second li {
        display: block;
        list-style: none;
        border-bottom: 1px solid #DDD; }
      .nav__mobile .nav__main li.shopping,
      .nav__mobile .nav__second li.shopping {
        margin-top: 40px;
        border-top: 1px solid #DDD; }
        .nav__mobile .nav__main li.shopping i,
        .nav__mobile .nav__second li.shopping i {
          width: 2em;
          display: inline-block; }
    .nav__mobile .nav__main {
      padding-bottom: 0; }
    .nav__mobile .nav__second {
      padding-top: 0; }
    .nav__mobile .nav__main ul li:first-child {
      border-top: 1px solid #DDD; }
    .nav__mobile .nav__second {
      background: transparent; }
      .nav__mobile .nav__second a:before {
        font-family: 'paricons';
        content: '\e923 ';
        width: 2em;
        display: inline-block; }
    .nav__mobile .nav__meta {
      padding: 10px 20px 20px; }
      .nav__mobile .nav__meta li {
        display: block;
        height: auto;
        list-style: none;
        border-bottom: 1px solid #444; }
    .nav__mobile .nav__meta,
    .nav__mobile .footer__social {
      display: block;
      background: #333;
      color: #DDD; }
      .nav__mobile .nav__meta a,
      .nav__mobile .footer__social a {
        color: #FFF; }
    .nav__mobile .footer__social ul {
      padding-bottom: 20px;
      justify-content: flex-start; }
      .nav__mobile .footer__social ul li {
        align-items: center; }
      .nav__mobile .footer__social ul li:first-child {
        display: none; }
    .nav__mobile .footer__social .footer__social__icon {
      justify-content: center; }
    .nav__mobile .footer__social .footer__social__label span {
      display: inline-block; }
    .nav__mobile .footer__copyright {
      display: block;
      background: #6BBA38;
      text-align: left; }
      .nav__mobile .footer__copyright a {
        color: #FFF; } }
      @media (max-width: 1199px) and (max-width: 1024px) {
        .nav__mobile .footer__copyright a {
          padding-top: 0;
          padding-bottom: 0; } }

@media (max-width: 1199px) {
    .nav__mobile ul.l1 > li.sub {
      position: relative; }
      .nav__mobile ul.l1 > li.sub .nav__main__trigger_l2 {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center; }
        .nav__mobile ul.l1 > li.sub .nav__main__trigger_l2:after {
          display: inline-block;
          font-family: "paricons";
          content: '\e907';
          font-size: 6px; }
      .nav__mobile ul.l1 > li.sub.hover > a {
        font-weight: 900; }
      .nav__mobile ul.l1 > li.sub.hover .nav__main__trigger_l2:after {
        transform: rotate(180deg); }
      .nav__mobile ul.l1 > li.sub.hover ~ li a {
        font-weight: 400 !important; }
    .nav__mobile ul.l2 {
      position: relative; }
      .nav__mobile ul.l2 li .svgicon {
        display: block;
        overflow: hidden; }
        .nav__mobile ul.l2 li .svgicon .icon-wrap-outer,
        .nav__mobile ul.l2 li .svgicon .title-wrap {
          display: inline-block; }
        .nav__mobile ul.l2 li .svgicon .icon-wrap-outer {
          width: 25px;
          margin-right: 10px; }
          .nav__mobile ul.l2 li .svgicon .icon-wrap-outer svg {
            width: 25px;
            max-height: 25px;
            fill: #6BBA38; }
          .nav__mobile ul.l2 li .svgicon .icon-wrap-outer .icon-wrap-inner {
            display: flex;
            flex-direction: row;
            align-items: center; }
      .nav__mobile ul.l2 li:last-child {
        border-bottom: 0; }
    .nav__mobile ul.l1 > li.sub.hover ul.l2 {
      display: block; } }

header#top:before,
header#top + .page__divider:before {
  display: block;
  padding-top: 35%;
  content: ''; }

header#top {
  position: fixed;
  top: 120px;
  left: 0;
  right: 0;
  z-index: 1;
  overflow: hidden;
  background: #F3FFE5; }
  header#top .container-inner {
    width: 100%;
    height: 100%;
    max-width: none;
    position: relative; }
  header#top h1,
  header#top h2,
  header#top h3 {
    font-size: 36px;
    font-weight: 900; }

.page__divider {
  position: static;
  margin-top: 120px; }

header#top + .page__divider {
  display: block; }

.header__inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  text-align: center; }
  .header__inner .content-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: row;
    align-content: center; }
    .header__inner .content-wrap .frame {
      position: relative;
      width: 100%; }

@media (max-width: 767px) {
  header#top:before,
  header#top + .page__divider:before {
    padding-top: 75%; }
  .page__divider {
    margin-top: 50px; }
  header#top {
    position: fixed;
    top: 50px; }
    header#top .content-wrap {
      padding: 0 20px; }
    header#top h1,
    header#top h2,
    header#top h3 {
      font-size: 21px; }
  .header__inner .content-wrap .searchform {
    margin: 0;
    width: auto; } }

@media (max-width: 960px) {
  header#top .frame {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

footer {
  position: relative;
  z-index: 20;
  background: #333;
  color: #FFF;
  font-size: 14px;
  line-height: 42px;
  font-weight: normal; }
  footer .container-inner {
    max-width: 1280px; }
  footer a {
    color: #FFF;
    text-decoration: none; }
  footer .skiplink {
    position: absolute;
    top: -70px;
    right: 10px;
    z-index: 99; }
  footer .footer__nav .container-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    padding: 0;
    font-size: 15px; }
    footer .footer__nav .container-inner > a,
    footer .footer__nav .container-inner > div {
      flex: 1 1; }
  footer .footer__copyright {
    padding: 0;
    background: #6BBA38;
    text-align: right;
    font-size: 12px; }
    footer .footer__copyright .container-inner {
      padding: 0 20px; }

.footer__social ul {
  display: flex;
  justify-content: flex-end;
  align-content: center; }

.footer__social li {
  display: flex;
  padding: 20px 0;
  list-style: none;
  align-content: center; }
  @media (max-width: 767px) {
    .footer__social li {
      padding: 10px 0; } }

.footer__social li a img,
.footer__social li a svg {
  width: 20px;
  height: 20px;
  max-width: inherit;
  max-height: inherit; }

.footer__social__icon {
  display: inline-block;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  color: #FFF;
  text-align: center;
  font-size: 14px; }

.toggle-box.active .footer__social__icon,
.footer__social__icon:hover {
  color: #FFF;
  background: #6BBA38;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }

.footer__social label {
  position: relative; }

.footer__social__label {
  padding-left: 20px; }
  .footer__social__label span {
    display: inline-block;
    padding-right: 10px; }
    @media (max-width: 767px) {
      .footer__social__label span {
        display: none; } }
    @media (max-width: 1024px) {
      .footer__social__label span {
        color: #999; } }
  @media (max-width: 1024px) {
    .footer__social__label {
      padding-left: 0; } }

.footer__social__share {
  position: absolute;
  display: none; }

.footer__social input {
  position: absolute;
  left: -9999px; }

.footer__social__share {
  z-index: 100;
  top: -90px;
  right: -4px;
  display: none;
  padding: 5px 10px;
  width: 154px;
  background: #FFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  text-align: center;
  line-height: 42px;
  white-space: nowrap; }
  .footer__social__share:before {
    position: absolute;
    right: 15px;
    bottom: -5px;
    z-index: 90;
    display: block;
    width: 20px;
    height: 20px;
    background: #FFF;
    transform: rotate(45deg);
    content: ""; }
  .footer__social__share a.button-icon {
    position: relative;
    z-index: 91;
    color: #BBB;
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    font-size: 14px;
    font-weight: normal; }
    .footer__social__share a.button-icon:hover {
      color: #FFF;
      background: #6BBA38;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }

.footer__social input:checked + .footer__social__share {
  display: block; }

.footer__social input:checked {
  background: #6BBA38;
  border-color: #6BBA38; }

.frame-type-textpic .ce-outer,
.frame-type-textpic .ce-inner,
.frame-type-textpic .ce-column {
  float: none;
  right: auto; }

.frame-type-textpic .ce-outer,
.frame-type-textpic .ce-row {
  display: flex;
  justify-content: flex-start; }

.frame-type-textpic .ce-center .ce-outer {
  justify-content: center; }

.frame-type-textpic .ce-right .ce-outer,
.frame-type-textpic .ce-right .ce-row {
  justify-content: flex-end; }

.frame-type-textpic .ce-border figure {
  padding: .5rem; }

.frame-type-textpic .ce-border img {
  border: none;
  box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.27); }

@media (min-width: 480px) {
  .frame-type-textpic .ce-intext.ce-right .ce-gallery, .frame-type-textpic .ce-intext.ce-left .ce-gallery {
    display: inline-block; }
  .frame-type-textpic .ce-intext.ce-left .ce-gallery {
    float: left !important; }
  .frame-type-textpic .ce-intext.ce-right .ce-gallery {
    float: right !important; } }

img.portrait {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%; }

.container {
  position: relative;
  padding: 100px 0; }
  .container:before {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    content: ""; }
  .container.bg-light-gray:before {
    background: #f8f8f8; }
  .container.bg-light-blue:before {
    background: #e5f2f8; }
  .container.bg-light-green:before {
    background: #F3FFE5; }
  .container.padding-top-less {
    padding-top: 30px; }
    @media (max-width: 479px) {
      .container.padding-top-less {
        padding-top: 15px; } }
  .container.padding-top-0 {
    padding-top: 0; }
  .container.padding-bottom-less {
    padding-bottom: 30px; }
    @media (max-width: 479px) {
      .container.padding-bottom-less {
        padding-bottom: 15px; } }
  .container.padding-bottom-0 {
    padding-bottom: 0; }
  .container.padding-lr-less {
    padding-left: 30px;
    padding-right: 30px; }
    @media (max-width: 479px) {
      .container.padding-lr-less {
        padding-left: 15px;
        padding-right: 15px; } }
  .container.padding-lr-0 {
    padding-left: 0;
    padding-right: 0; }
  .container.container--text-color-white {
    color: #fff; }

.container__background,
.container__background__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .container__background img,
  .container__background__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover"; }

.container--background {
  color: #FFF; }

.container__background__img:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
  background: rgba(0, 0, 0, 0.49); }

.container.container--text-align-center:last-of-type {
  text-align: center; }
  .container.container--text-align-center:last-of-type p, .container.container--text-align-center:last-of-type ul, .container.container--text-align-center:last-of-type ol, .container.container--text-align-center:last-of-type table {
    text-align: center; }

.container.container--text-align-left:last-of-type {
  text-align: left; }
  .container.container--text-align-left:last-of-type p, .container.container--text-align-left:last-of-type ul, .container.container--text-align-left:last-of-type ol, .container.container--text-align-left:last-of-type table {
    text-align: left; }

.container.container--inner-1280px .content-wrap {
  max-width: 1280px; }

.container.container--inner-960px .content-wrap {
  max-width: 960px; }

.container.container--inner-780px .content-wrap {
  max-width: 780px; }

.container-inner {
  max-width: 960px; }

@media (max-width: 1199px) {
  .container {
    padding: 60px 30px; }
    .container .container {
      padding-left: 0;
      padding-right: 0; } }

@media (max-width: 479px) {
  .container {
    padding: 30px 10px; }
    .container .container {
      padding-left: 0;
      padding-right: 0; } }

body {
  font-family: "Open Sans","Helvetica Neue",Helvetiva,Arial,sans-serif;
  font-weight: 400;
  color: #333;
  font-size: 17px;
  line-height: 27px; }

h1,
h2,
h3,
p,
table,
ul,
ol {
  margin: 0; }

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  padding-bottom: .5em;
  font-size: 16px;
  line-height: 1.1;
  font-weight: 900;
  text-transform: uppercase; }

h1 {
  padding-bottom: 1rem;
  font-size: 36px; }
  h1.color-green-inverted {
    text-transform: uppercase; }

h2 {
  font-size: 26px; }

h2,
h3 {
  margin-top: 1rem;
  padding: 0 0 1rem;
  text-transform: none; }

p {
  padding: 0 0 1rem;
  font-size: 17px;
  line-height: 1.6rem; }
  p.teaser {
    padding-bottom: 1.5rem; }
  p.left-align {
    margin: 0; }

div.text-large p {
  font-size: 22px; }

div.text-small p {
  font-size: 14px; }

.ce-textpic {
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column; }
  .ce-textpic > div {
    float: none  !important;
    flex: 1; }

.ce-gallery + .ce-bodytext {
  padding-top: 20px; }

hr {
  display: block;
  margin: 20px 0 60px;
  padding: 0;
  width: 50px;
  height: 6px;
  border: 0;
  outline: 0;
  background: #6BBA38; }

strong, b {
  font-weight: 600; }

.align-left, .text-left {
  text-align: left !important; }

.align-center, .text-center {
  text-align: center !important; }

.align-right, .text-right {
  text-align: right !important; }

.color-green {
  color: #6BBA38; }

.color-blue {
  color: #005DAF; }

.color-green-inverted {
  color: #FFF !important;
  padding: 10px !important;
  line-height: 1.55 !important;
  display: inline-block;
  width: auto;
  text-align: center;
  text-transform: none; }
  .color-green-inverted span {
    background: #6BBA38 !important;
    padding: 0.45rem;
    box-decoration-break: clone;
    -webkit-box-decoration-break: clone; }

.content-wrap ol,
.content-wrap ul:not([class]) {
  margin-bottom: 1rem;
  padding-left: 2rem; }

table {
  margin: 0 0 1.7rem;
  width: 100%;
  table-layout: fixed;
  border: 0;
  border-collapse: collapse;
  font-size: 1.2rem;
  word-wrap: break-word; }
  @media (max-width: 767px) {
    table {
      font-size: 0.8rem; } }
  table th,
  table td {
    padding: 6px;
    border-bottom: 1px solid #000;
    vertical-align: top; }
    @media (max-width: 767px) {
      table th,
      table td {
        padding-left: 0;
        padding-right: 0; } }
  table thead th,
  table thead td {
    text-align: right;
    color: #097DBD;
    font-weight: 400; }
    table thead th:first-child,
    table thead td:first-child {
      font-weight: 600;
      text-align: left;
      text-transform: uppercase; }
  table tbody th,
  table tbody td {
    text-align: right;
    font-weight: 600; }
    table tbody th:first-child,
    table tbody td:first-child {
      text-align: left; }
  table tfoot th,
  table tfoot td {
    text-align: right;
    font-weight: 600;
    border-bottom: 0; }

@media (max-width: 1199px) {
  body {
    font-size: 16px;
    line-height: 26px; } }

@media (max-width: 767px) {
  body,
  p {
    font-size: 15px;
    line-height: 25px; }
  h1,
  h3,
  h4,
  h5,
  h6 {
    font-size: 13px;
    line-height: 21px; }
  h2 {
    font-size: 21px; } }

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none; }

p a {
  text-decoration: underline; }
  p a:hover {
    color: #6BBA38; }

a.link--green {
  display: inline-block;
  padding: 0 10px;
  color: #6BBA38; }

a.button,
a.download,
button.button,
input.button,
button.btn-primary {
  display: inline-block;
  margin: 0;
  padding: 0 24px;
  width: auto;
  height: 42px;
  background: #6BBA38;
  border-radius: 24px;
  border: none;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  line-height: 42px;
  font-size: 13px;
  font-weight: 800;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer; }
  a.button:hover,
  a.download:hover,
  button.button:hover,
  input.button:hover,
  button.btn-primary:hover {
    color: #fff;
    background: #89C860; }

a.button--outlined {
  display: inline-block;
  width: auto;
  border: 1px solid #6BBA38;
  border-radius: 24px;
  background: transparent;
  font-weight: 600;
  color: #6BBA38; }
  a.button--outlined:hover {
    border-color: #89C860;
    background: #89C860;
    color: #fff; }
  a.button--outlined.disabled {
    border-color: rgba(107, 186, 56, 0.66);
    background: transparent;
    color: rgba(107, 186, 56, 0.66);
    user-select: none;
    /* CSS Basic User Interface Module Level 4 */ }
    a.button--outlined.disabled:hover {
      cursor: default; }

a.button--blue,
button.button--blue {
  background: #005DAF; }
  a.button--blue:hover,
  button.button--blue:hover {
    color: #fff;
    background: #2776B6; }

.link-target {
  color: #6BBA38;
  text-transform: uppercase; }

@media (max-width: 960px) {
  a[href^="tel"] {
    text-decoration: underline; } }

.ratio {
  position: relative; }
  .ratio:before {
    display: block;
    padding-top: 100%;
    content: ''; }
  .ratio.ratio--outer {
    height: 100%; }
  .ratio.ratio--outer:before {
    display: none; }
  .ratio.ratio--1-2:before {
    padding-top: 200%; }
  .ratio.ratio--2-1:before {
    padding-top: 50%; }
  .ratio.ratio--2-3:before {
    padding-top: 56.522%; }
  .ratio.ratio--2-3:before {
    padding-top: 66.66%; }
  .ratio.ratio--4-3:before {
    padding-top: 75%; }
  .ratio.ratio--3-4:before {
    padding-top: 133.33%; }
  .ratio.ratio--16-9:before {
    padding-top: 56.25%; }

.ratio__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.slider-wrap {
  position: relative;
  margin: 0 auto 20px;
  padding: 0;
  height: 100%;
  width: 100%;
  max-width: 960px; }
  .slider-wrap .parakm-ce-image {
    margin: 0 !important; }
  .slider-wrap .slick-slider {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
  .slider-wrap .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%; }
  .slider-wrap .slick-slide {
    position: relative;
    overflow: hidden;
    display: none;
    float: left;
    margin: 0 !important;
    height: 100%;
    min-height: 1px; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  height: 100%; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slick-dots {
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 99;
  list-style: none !important;
  display: inline-block;
  text-align: center;
  padding: 0 !important;
  margin: 0 !important; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    margin: 0;
    padding: 8px 8px;
    cursor: pointer;
    color: #FFF; }
    .slick-dots li:before {
      display: none; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      outline: none;
      color: transparent;
      padding: 0;
      cursor: pointer;
      line-height: 0; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
      .slick-dots li button:before {
        display: block;
        content: " ";
        width: 12px;
        height: 12px;
        background: #FFF;
        border-radius: 100%;
        transition: all ease-in; }
    .slick-dots li.slick-active button:before {
      background: #6BBA38; }

.slick-arrow {
  position: absolute;
  top: 50%;
  z-index: 12;
  border: 0;
  background: transparent;
  display: block;
  width: 0px;
  height: 0px;
  outline: none;
  color: #999;
  padding: 0;
  opacity: 0.8;
  cursor: pointer;
  font-size: 19px;
  line-height: 25px; }
  @media (max-width: 1200px) {
    .slick-arrow {
      width: 24px;
      height: 44px; } }
  @media (max-width: 768px) {
    .slick-arrow {
      color: #FFF; } }
  .slick-arrow.slick-prev {
    left: -61px; }
    @media (max-width: 1200px) {
      .slick-arrow.slick-prev {
        left: -25px; } }
    @media (max-width: 768px) {
      .slick-arrow.slick-prev {
        left: 20px; } }
  .slick-arrow.slick-next {
    right: -50px; }
    @media (max-width: 1200px) {
      .slick-arrow.slick-next {
        right: -36px; } }
    @media (max-width: 768px) {
      .slick-arrow.slick-next {
        right: 8px; } }
  .slick-arrow:hover {
    opacity: 1; }

.link {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.link__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  background-color: transparent;
  text-align: center;
  color: #999;
  font-weight: 900;
  border: 1px solid #999; }
  .link__icon:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    background-color: #6BBA38;
    color: #fff; }
  .link__icon:hover, .link__icon:focus {
    fill: #fff;
    border: 1px solid #6BBA38; }
  .link__icon svg {
    display: block;
    width: 1rem;
    height: 1rem; }

.link__icon + .link__label {
  margin-left: 10px; }

.link__label + .link__icon {
  margin-left: 10px; }

.carousel-wrap {
  position: relative;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  max-width: 960px;
  display: block; }
  .carousel-wrap .carousel {
    margin: 0 -20px 40px -20px !important;
    padding: 20px 0 20px 0 !important;
    display: flex;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent; }
    @media (max-width: 767px) {
      .carousel-wrap .carousel {
        padding: 0 !important; } }
  .carousel-wrap .slick-list {
    position: relative;
    overflow: hidden;
    margin: 0 20px 0 0 !important;
    padding: 20px 10px 20px 10px !important;
    min-width: 100%; }
  .carousel-wrap .slick-track {
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: row; }
  .carousel-wrap .slick-slide {
    position: relative;
    flex: 1 1;
    min-height: 1px;
    opacity: .7; }
    .carousel-wrap .slick-slide.slick-active, .carousel-wrap .slick-slide:hover {
      opacity: 1; }
    .carousel-wrap .slick-slide .slide__inner {
      margin: 0 10px;
      height: 100%;
      background: #F8F8F8; }
    .carousel-wrap .slick-slide .slide__inner .slide__inner__text {
      padding: 0 20px; }
    .carousel-wrap .slick-slide .slide__inner .tiles {
      position: relative;
      margin: 0; }
    .carousel-wrap .slick-slide .slide__inner .tiles__tile .tiles__tile__content {
      background: #F8F8F8; }
      .carousel-wrap .slick-slide .slide__inner .tiles__tile .tiles__tile__content:hover {
        background: #F3FFE5; }
  .carousel-wrap .carousel--image-text h2 {
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    color: #6BBA38; }
  .carousel-wrap .carousel--image-text h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; }
  .carousel-wrap .carousel--image-text .slick-slide .slide__inner:hover {
    background: #FFF;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.18); }

.bg-light-blue .carousel-wrap .carousel--image-text .slick-slide .slide__inner:hover,
.bg-light-blue .carousel-wrap .carousel--image-text .slick-slide .slide__inner:hover .tiles__tile__content {
  background: #f3ffe5; }

/*
@include media("<desktop")
{
	.carousel-wrap .carousel
	{
		//margin: 0 -20px 40px -20px !important;
		//padding: 20px 0px 20px 0px !important;
	}
}
*/
@media (max-width: 479px) {
  .carousel-wrap .slick-list {
    margin: 0 20px 0 0 !important;
    padding: 20px 40px 20px 40px !important; }
  .carousel-wrap .carousel--image-text h2,
  .carousel-wrap .carousel--image-text h3,
  .carousel-wrap .carousel--image-text p {
    font-size: 13px;
    line-height: 21px; } }

.row {
  display: table;
  display: flex;
  margin: 0 -10px;
  padding: 0 0;
  table-layout: fixed; }
  .row.row--75-25 .column:first-child {
    flex: 1 1 75%; }
  .row.row--75-25 .column:last-child {
    flex: 1 1 25%; }
  .row.row--68-32 .column:first-child {
    flex: 1 1 68.75%; }
  .row.row--68-32 .column:last-child {
    flex: 1 1 31.25%; }
  .row.row--25-75 .column:first-child {
    flex: 1 1 25%; }
  .row.row--25-75 .column:last-child {
    flex: 1 1 75%; }
  .row.row--50-25-25 .column:first-child {
    flex: 1 1 50%;
    padding-right: 25px; }
  .row.row--50-25-25 .column:nth-child(2n) {
    padding-left: 0px;
    padding-right: 20px;
    flex: 1 1 25%; }
  .row.row--50-25-25 .column:last-child {
    padding-left: 5px;
    flex: 1 1 25%; }
  .row.row--25-50-25 .column:first-child {
    flex: 1 1 25%;
    padding-right: 5px; }
  .row.row--25-50-25 .column:nth-child(2n) {
    flex: 1 1 50%;
    padding-left: 20px;
    padding-right: 20px; }
  .row.row--25-50-25 .column:last-child {
    padding-left: 5px;
    flex: 1 1 25%; }
  .row.row--25-25-50 .column:first-child {
    flex: 1 1 25%;
    padding-right: 5px; }
  .row.row--25-25-50 .column:nth-child(2n) {
    flex: 1 1 25%;
    padding-left: 20px;
    padding-right: 0px; }
  .row.row--25-25-50 .column:last-child {
    flex: 1 1 50%;
    padding-left: 25px; }
  .row.row--75 > .column {
    flex: 0 0 75%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 5px; }
    @media (max-width: 768px) {
      .row.row--75 > .column {
        flex: 0 0 100%; } }

.column {
  position: relative;
  flex: 1 1 50%;
  padding: 0 10px; }
  .column > .container-inner {
    height: 100%; }

.column > div:not(.row) {
  display: block;
  width: 100%;
  flex: 0 0 0; }

@media (max-width: 767px) {
  .row.breakOnMobile, .row.breakOnMobile .col, .row.breakOnMobile .column {
    display: block;
    flex: 0;
    width: 100%;
    margin: 0;
    padding: 0; }
  .row.breakOnMobile2 {
    flex-wrap: wrap; }
    .row.breakOnMobile2 .col, .row.breakOnMobile2 .column {
      flex: 0 1 50%; } }

.row.gutter-5px {
  margin: 0 -5px; }
  .row.gutter-5px > .column {
    padding: 10px 5px; }
  @media (max-width: 767px) {
    .row.gutter-5px {
      margin: 0; }
      .row.gutter-5px > .column {
        padding: 5px 0px; } }

.row.no-gutter {
  margin: 0; }
  .row.no-gutter > .column {
    padding: 0; }

/*
	Spacing

	The .frame-space* classes are from Typo3
*/
.m0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0 !important; }

.mr0 {
  margin-right: 0 !important; }

.mb0 {
  margin-bottom: 0 !important; }

.ml0 {
  margin-left: 0 !important; }

.m1 {
  margin: 1em !important; }

.mt1,
.frame-space-before-extra-small {
  margin-top: 1em !important; }

.mr1 {
  margin-right: 1em !important; }

.mb1,
.frame-space-after-extra-small {
  margin-bottom: 1em !important; }

.ml1 {
  margin-left: 1em !important; }

.m2 {
  margin: 2em !important; }

.mt2, form#kontaktformular button,
.frame-space-before-small {
  margin-top: 2em !important; }

.mr2 {
  margin-right: 2em !important; }

.mb2,
.frame-space-after-small {
  margin-bottom: 2em !important; }

.ml2 {
  margin-left: 2em !important; }

.m3 {
  margin: 3em !important; }

.mt3,
.frame-space-before-medium {
  margin-top: 3em !important; }

.mr3 {
  margin-right: 3em !important; }

.mb3,
.frame-space-after-medium {
  margin-bottom: 3em !important; }

.ml3 {
  margin-left: 3em !important; }

.m4 {
  margin: 4em !important; }

.mt4,
.frame-space-before-large {
  margin-top: 4em !important; }

.mr4 {
  margin-right: 4em !important; }

.mb4,
.frame-space-after-large {
  margin-bottom: 4em !important; }

.ml4 {
  margin-left: 4em !important; }

.m5 {
  margin: 5em !important; }

.mt5,
.frame-space-before-extra-large {
  margin-top: 5em !important; }

.mr5 {
  margin-right: 5em !important; }

.mb5,
.frame-space-after-extra-large {
  margin-bottom: 5em !important; }

.ml5 {
  margin-left: 5em !important; }

.p0 {
  padding: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pr0 {
  padding-right: 0 !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pl0 {
  padding-left: 0 !important; }

.p1 {
  padding: 1em !important; }

.pt1 {
  padding-top: 1em !important; }

.pr1 {
  padding-right: 1em !important; }

.pb1 {
  padding-bottom: 1em !important; }

.pl1 {
  padding-left: 1em !important; }

.p2 {
  padding: 2em !important; }

.pt2 {
  padding-top: 2em !important; }

.pr2,
.padding-lr-less {
  padding-right: 2em !important; }

.pb2 {
  padding-bottom: 2em !important; }

.pl2,
.padding-lr-less {
  padding-left: 2em !important; }

.p3 {
  padding: 3em !important; }

.pt3 {
  padding-top: 3em !important; }

.pr3 {
  padding-right: 3em !important; }

.pb3 {
  padding-bottom: 3em !important; }

.pl3 {
  padding-left: 3em !important; }

.p4 {
  padding: 4em !important; }

.pt4 {
  padding-top: 4em !important; }

.pr4 {
  padding-right: 4em !important; }

.pb4 {
  padding-bottom: 4em !important; }

.pl4 {
  padding-left: 4em !important; }

.p5 {
  padding: 5em !important; }

.pt5 {
  padding-top: 5em !important; }

.pr5 {
  padding-right: 5em !important; }

.pb5 {
  padding-bottom: 5em !important; }

.pl5 {
  padding-left: 5em !important; }

@media (max-width: 480px) {
  .m2 {
    margin: .5em !important; }
  .mt2, form#kontaktformular button,
  .frame-space-before-small {
    margin-top: .5em !important; }
  .mr2 {
    margin-right: .5em !important; }
  .mb2,
  .frame-space-after-small {
    margin-bottom: .5em !important; }
  .ml2 {
    margin-left: .5em !important; }
  .m3 {
    margin: 1em !important; }
  .mt3,
  .frame-space-before-medium {
    margin-top: 1em !important; }
  .mr3 {
    margin-right: 1em !important; }
  .mb3,
  .frame-space-after-medium {
    margin-bottom: 1em !important; }
  .ml3 {
    margin-left: 1em !important; }
  .mt4,
  .frame-space-before-large {
    margin-top: 1.5em !important; }
  .mb4,
  .frame-space-after-large {
    margin-bottom: 1.5em !important; }
  .pt2 {
    padding-top: .5em !important; }
  .pr2 {
    padding-right: .5em !important; }
  .pb2 {
    padding-bottom: .5em !important; }
  .pl2 {
    padding-left: .5em !important; }
  .p3 {
    padding: 1em !important; }
  .pt3 {
    padding-top: 1em !important; }
  .pr3 {
    padding-right: 1em !important; }
  .pb3 {
    padding-bottom: 1em !important; }
  .pl3 {
    padding-left: 1em !important; }
  .pt4 {
    padding-top: 1.5em !important; }
  .pb4 {
    padding-bottom: 1.5em !important; } }

@media (max-width: 768px) {
  .m2 {
    margin: 1em !important; }
  .mt2, form#kontaktformular button,
  .frame-space-before-small {
    margin-top: 1em !important; }
  .mr2 {
    margin-right: 1em !important; }
  .mb2,
  .frame-space-after-small {
    margin-bottom: 1em !important; }
  .ml2 {
    margin-left: 1em !important; }
  .m3 {
    margin: 1.5em !important; }
  .mt3,
  .frame-space-before-medium {
    margin-top: 1.5em !important; }
  .mr3 {
    margin-right: 1.5em !important; }
  .mb3 {
    margin-bottom: 1.5em !important; }
  .ml3 {
    margin-left: 1.5em !important; }
  .mt4,
  .frame-space-before-large {
    margin-top: 2em !important; }
  .mb4,
  .frame-space-after-large {
    margin-bottom: 2em !important; }
  .pt2 {
    padding-top: 1em !important; }
  .pr2 {
    padding-right: 1em !important; }
  .pb2 {
    padding-bottom: 1em !important; }
  .pl2 {
    padding-left: 1em !important; }
  .p3 {
    padding: 1.5em !important; }
  .pt3 {
    padding-top: 1.5em !important; }
  .pr3 {
    padding-right: 1.5em !important; }
  .pb3 {
    padding-bottom: 1.5em !important; }
  .pl3 {
    padding-left: 1.5em !important; }
  .pt4 {
    padding-top: 2em !important; }
  .pb4 {
    padding-bottom: 2em !important; } }

img,
video {
  display: block;
  max-width: 100%;
  height: auto; }

.map img {
  display: inherit;
  width: inherit;
  max-width: inherit;
  height: inherit;
  max-height: inherit; }

.parakm-ce-image + div {
  margin-top: 20px; }

.parakm-ce-image img {
  width: 100%; }

.parakm-ce-image .parakm-ce-image__background.ratio__content {
  transition: all .25s; }

.parakm-ce-image__background img {
  object-fit: cover;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover";
  height: 100%; }

.parakm-ce-image__background.parakm-ce-image__image--cover img {
  object-fit: cover;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover"; }

.parakm-ce-image__background.parakm-ce-image__image--contain img {
  object-fit: contain;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover"; }

.parakm-ce-image__text {
  color: #FFF;
  display: flex;
  padding: 30px;
  align-items: flex-end;
  justify-content: center; }
  .parakm-ce-image__text .parakm-ce-image__text__wrap {
    position: relative; }
  @media (max-width: 767px) {
    .parakm-ce-image__text {
      padding: 10px; } }

.parakm-ce-image--gradient .parakm-ce-image__text:before {
  position: absolute;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 55%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9));
  content: " "; }

.parakm-ce-image__caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  right: 20px;
  padding: 5px 0 4px;
  font-size: 13px;
  font-weight: 600;
  border-left: 20px solid #6BBA38;
  color: #FFF; }
  .parakm-ce-image__caption strong {
    background: #6BBA38;
    display: inline;
    width: auto;
    padding: 6px 0; }
  .parakm-ce-image__caption span {
    position: relative;
    left: -9px;
    white-space: normal;
    width: auto;
    word-break: normal;
    font-size: 13px;
    line-height: 21px; }

.parakm-ce-image__text__wrap {
  width: auto; }
  .parakm-ce-image__text__wrap h1,
  .parakm-ce-image__text__wrap h2,
  .parakm-ce-image__text__wrap h3,
  .parakm-ce-image__text__wrap p {
    margin: 0;
    padding: 0; }

.parakm-ce-image__text--left {
  justify-content: flex-start; }
  .parakm-ce-image__text--left h1, .parakm-ce-image__text--left h2, .parakm-ce-image__text--left h3, .parakm-ce-image__text--left p {
    text-align: left; }

.parakm-ce-image__text--right {
  justify-content: flex-end; }
  .parakm-ce-image__text--right h1, .parakm-ce-image__text--right h2, .parakm-ce-image__text--right h3, .parakm-ce-image__text--right p {
    text-align: right; }

.parakm-ce-image__text__wrap {
  border: 1px solid #FFF;
  border-width: 1px 0;
  padding: 10px 0; }

.parakm-ce-image__link {
  z-index: 9; }

.video-youtube,
.video-vimeo {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden; }
  .video-youtube iframe,
  .video-vimeo iframe {
    border: 0;
    outline: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.video-player__mobile {
  display: none; }

@media (orientation: portrait) and (max-width: 600px) {
  .video-player--mobile-optimized .video-player__default {
    display: none !important; }
  .video-player--mobile-optimized .video-player__mobile {
    display: block !important; } }

iframe {
  border: 0;
  outline: 0;
  width: 100%;
  height: 100%; }

.section-inner .video-player {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px; }
  .section-inner .video-player:before {
    display: block;
    padding-top: 56.25%;
    content: ''; }

.section-inner .video-player__default {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }

.compat-object-fit {
  background-size: cover;
  background-position: center; }

.compat-object-fit img {
  opacity: 0; }

::-webkit-input-placeholder {
  text-transform: none;
  font-size: 13px; }

::-moz-placeholder {
  text-transform: none;
  font-size: 13px; }

:-ms-input-placeholder {
  text-transform: none;
  font-size: 13px; }

:-moz-placeholder {
  text-transform: none;
  font-size: 13px; }

input,
textarea {
  color: #777; }

input[type='text'],
input[type='email'],
textarea {
  display: block;
  margin: 0 0 2rem;
  padding: .5rem;
  width: 100%;
  background: #fff;
  border: 1px solid #AAA;
  border-top-color: #fff;
  font-size: 14px;
  font-family: "Open Sans","Helvetica Neue",Helvetiva,Arial,sans-serif; }
  input[type='text']:hover, input[type='text']:focus,
  input[type='email']:hover,
  input[type='email']:focus,
  textarea:hover,
  textarea:focus {
    border: 1px solid #333; }

textarea {
  height: 10rem; }

input[type='submit'] {
  outline: 0;
  display: inline-block;
  margin: 0 auto;
  width: auto;
  cursor: pointer;
  padding: 10px;
  background: #6BBA38;
  border: 1px solid #6BBA38;
  border-radius: 4px;
  color: #fff;
  text-transform: uppercase;
  transition: all .5s; }
  input[type='submit']:hover {
    background: #666;
    border: 1px solid #666; }

fieldset {
  border: 0;
  outline: 0;
  margin-bottom: 20px; }

input[type='text'],
input[type='email'],
textarea {
  margin: 0;
  padding: 1.285714286em 1.785714286em; }
  input[type='text']:first-of-type,
  input[type='email']:first-of-type,
  textarea:first-of-type {
    border-top-color: #AAA;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
    input[type='text']:first-of-type:hover, input[type='text']:first-of-type:focus,
    input[type='email']:first-of-type:hover,
    input[type='email']:first-of-type:focus,
    textarea:first-of-type:hover,
    textarea:first-of-type:focus {
      border-top-color: #333; }
  input[type='text']:last-of-type,
  input[type='email']:last-of-type,
  textarea:last-of-type {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px; }

fieldset.submit {
  text-align: center; }
  fieldset.submit input[type='text'],
  fieldset.submit input[type='email'] {
    position: absolute;
    top: -9876px;
    left: -9876px; }

.pflicht input[type='text'],
.pflicht input[type='email'],
.pflicht input[type='text']:first-of-type,
.pflicht input[type='email']:first-of-type,
.pflicht input[type='text']:first-of-type:focus,
.pflicht input[type='email']:first-of-type:hover,
.pflicht textarea {
  border-color: #CD1316;
  color: #CD1316; }

.warnungpflichtfelder {
  display: none; }

h3.warnungpflichtfelder {
  display: block; }

div.errors {
  padding: 0 0 2rem; }

div.errors ul {
  margin: 0 0 0 1rem; }

img.tx-srfreecap-pi1-image {
  width: auto; }

fieldset.anrede input[type='radio'] {
  display: inline-block;
  width: auto; }

.ui-datepicker-calendar thead th:first-child {
  text-align: center; }

select {
  padding: .5rem; }

.erweiterte-suche-fake-select {
  position: relative;
  min-width: 30ex; }
  .erweiterte-suche-fake-select.rounded .erweiterte-suche-fake-select-content.open {
    top: 2.76471rem; }

.erweiterte-suche-fake-select-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 1.64706rem;
  border-bottom: 0.05882rem solid #bbb;
  padding-right: 0.5em;
  padding-bottom: 0;
  padding-left: 0.5em;
  color: #666;
  cursor: pointer; }
  .erweiterte-suche-fake-select-toggle i {
    margin-left: 2ex;
    font-size: 0.5625rem; }
  .erweiterte-suche-fake-select-toggle.fake-select-toggle-rounded {
    height: 2.76471rem;
    border: 0.05882rem solid #bbb;
    border-radius: 30px;
    margin-bottom: 0;
    padding: 0.5rem 20px;
    background: #fff;
    color: #bbb; }

.erweiterte-suche-fake-select-content {
  display: none !important;
  width: 100%; }
  .erweiterte-suche-fake-select-content.open {
    display: block !important;
    position: absolute;
    z-index: 999;
    top: 1.70588rem;
    min-width: 30ex;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 8px 8px 16px 0 rgba(0, 0, 0, 0.08);
    background: #fff; }
  .erweiterte-suche-fake-select-content ul {
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    max-height: 324px;
    overflow-y: auto; }
    .erweiterte-suche-fake-select-content ul li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 3.75rem;
      border-bottom: 1px solid #ddd;
      padding-right: 1.1764705882352942rem;
      padding-left: 1.1764705882352942rem; }
      .erweiterte-suche-fake-select-content ul li:last-of-type {
        border-bottom: 0; }
    .erweiterte-suche-fake-select-content ul li a {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1; }
      .erweiterte-suche-fake-select-content ul li a i {
        margin-left: 2ex;
        font-size: .5625rem; }

.kw-hideable {
  flex-direction: row-reverse;
  justify-content: space-between; }
  .kw-hideable,
  .kw-hideable input,
  .kw-hideable label {
    cursor: pointer; }
  .kw-hideable input {
    margin-left: .5rem; }

.tiles {
  position: relative;
  margin: 0 0 60px; }
  .tiles .tiles__tile__content {
    position: relative; }

.tiles__tile {
  position: relative;
  z-index: 1;
  display: flex;
  height: 100%; }
  .tiles__tile .tiles__tile__content {
    width: 100%;
    height: 100%;
    background: #FFF; }
    .tiles__tile .tiles__tile__content:hover {
      z-index: 2;
      box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.18); }
  .tiles__tile .tiles__tile__image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-fit: cover;
    font-family: "object-fit: cover"; }
  .tiles__tile p a {
    text-decoration: none; }
  .tiles__tile:hover p a {
    text-decoration: underline; }

.tiles__tile__target {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 2;
  text-decoration: none; }

.tiles__tile__subtitle {
  display: inline-block;
  padding-right: 20px; }

.tiles__tile__link {
  text-transform: uppercase;
  color: #6BBA38;
  font-size: 13px;
  font-weight: 600;
  display: inline-block;
  padding-left: 0; }

.tiles--icon svg {
  display: block;
  max-width: 100%;
  max-height: 80%;
  margin: 0 auto;
  fill: #6BBA38; }
  @media (max-width: 480px) {
    .tiles--icon svg {
      max-height: 35px; } }

.tiles--icon .row,
.tiles--icon .row.breakOnMobile2 {
  flex-wrap: wrap;
  margin: 0; }
  .tiles--icon .row .column,
  .tiles--icon .row.breakOnMobile2 .column {
    flex: 1 1 auto;
    max-width: 31.333333333333332%;
    margin: 0 1% 1.25rem;
    padding: 0;
    background-color: #fff; }
    .tiles--icon .row .column:before,
    .tiles--icon .row.breakOnMobile2 .column:before {
      content: "";
      display: block;
      float: left;
      padding: 66% 0 0 0; }
    @media (max-width: 480px) {
      .tiles--icon .row .column,
      .tiles--icon .row.breakOnMobile2 .column {
        flex: 1 1 48%;
        max-width: 48%;
        margin-bottom: .3125rem; } }
    .tiles--icon .row .column .ratio,
    .tiles--icon .row.breakOnMobile2 .column .ratio {
      display: flex;
      height: 100%;
      padding-top: 0; }
      .tiles--icon .row .column .ratio:before,
      .tiles--icon .row.breakOnMobile2 .column .ratio:before {
        display: none; }
      .tiles--icon .row .column .ratio .ratio__content,
      .tiles--icon .row.breakOnMobile2 .column .ratio .ratio__content {
        position: relative; }

.tiles--icon .tiles__tile {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  width: 100%;
  height: 100%;
  color: #333; }
  .tiles--icon .tiles__tile .tiles__tile__icon {
    display: block;
    height: 60%;
    padding: 20% 15% 20px 15%; }
    @media (max-width: 480px) {
      .tiles--icon .tiles__tile .tiles__tile__icon {
        height: auto;
        padding: 20px 13px 13px; } }
  .tiles--icon .tiles__tile .tiles__tile__label {
    height: 40%;
    display: block;
    font-weight: 400;
    padding: 7.5% 20px; }
    @media (max-width: 480px) {
      .tiles--icon .tiles__tile .tiles__tile__label {
        height: 5em;
        padding: 0 13px 13px;
        line-height: 1.5em;
        font-size: .8125rem; } }
  .tiles--icon .tiles__tile .tiles__tile__content {
    align-items: center;
    justify-content: center;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    padding: 0 10px; }
    @media (max-width: 480px) {
      .tiles--icon .tiles__tile .tiles__tile__content {
        justify-content: flex-start; } }
    @media (max-width: 767px) {
      .tiles--icon .tiles__tile .tiles__tile__content {
        padding: 0 5px !important; } }
  .tiles--icon .tiles__tile:hover .tiles__tile__content {
    background: #6BBA38;
    font-weight: 900;
    color: #FFF; }
    .tiles--icon .tiles__tile:hover .tiles__tile__content svg {
      fill: #c4e3b0; }

@media (max-width: 767px) {
  .tiles--icon .row {
    margin: 0 -5px !important; } }

.tiles--image--text {
  display: flex; }
  .tiles--image--text.tiles--image--text--cols {
    flex-wrap: wrap;
    justify-items: flex-start;
    margin: 0 -10px; }
    .tiles--image--text.tiles--image--text--cols .tiles__tile {
      flex: 0 1 33.33%;
      margin: 0 0 20px;
      padding: 0 10px;
      height: auto;
      background: transparent;
      min-width: 33.33%; }
      @media (max-width: 959px) {
        .tiles--image--text.tiles--image--text--cols .tiles__tile {
          flex: 0 1 50% !important; } }
      @media (max-width: 479px) {
        .tiles--image--text.tiles--image--text--cols .tiles__tile {
          flex: 0 1 100% !important; } }
    .tiles--image--text.tiles--image--text--cols[data-items='2'] .tiles__tile {
      flex: 0 1 50%; }
    .tiles--image--text.tiles--image--text--cols h2 {
      font-size: 16px;
      font-weight: 900;
      line-height: 24px;
      color: #6BBA38; }
    .tiles--image--text.tiles--image--text--cols h3 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px; }
    .tiles--image--text.tiles--image--text--cols p {
      padding-bottom: 0; }
    .tiles--image--text.tiles--image--text--cols.tiles--image--text--news {
      flex-wrap: wrap; }
      .tiles--image--text.tiles--image--text--cols.tiles--image--text--news .tiles__tile {
        flex: 1 0 50%;
        margin: 0 0 20px; }
        .tiles--image--text.tiles--image--text--cols.tiles--image--text--news .tiles__tile h2 {
          padding-bottom: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #333; }
        .tiles--image--text.tiles--image--text--cols.tiles--image--text--news .tiles__tile h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 27px; }
  .tiles--image--text.tiles--image--text--rows {
    flex-direction: column; }
    .tiles--image--text.tiles--image--text--rows .tiles__tile {
      flex: 0 1 100%;
      display: flex;
      margin: 0 0 20px; }
      .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content {
        display: flex;
        flex-wrap: wrap;
        padding: 0; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content h2,
        .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content h3 {
          text-transform: none;
          padding: 0;
          font-size: 18px; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content h2 {
          color: #6BBA38;
          padding-bottom: 3px; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content h3 {
          padding-bottom: 0px;
          font-weight: 600; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__content p {
          padding: 20px 0 0;
          font-size: 14px;
          line-height: 23px; }
      .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__image {
        flex: 0 1 33.33%; }
      .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__text {
        flex: 1 1;
        padding: 0; }
      .tiles--image--text.tiles--image--text--rows .tiles__tile .tiles__tile__text__inner {
        padding: 20px 20px 15px 20px; }
      .tiles--image--text.tiles--image--text--rows .tiles__tile:hover {
        background: #f3ffe5; }
    @media (max-width: 767px) {
      .tiles--image--text.tiles--image--text--rows {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        width: 100%;
        height: 100%;
        color: #333; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile__image {
          display: block;
          flex: 1 1 100% !important;
          padding: 0;
          width: 100%; }
        .tiles--image--text.tiles--image--text--rows .tiles__tile__content {
          align-items: center;
          justify-content: center;
          flex: 1 1;
          display: flex;
          flex-direction: column; }
          .tiles--image--text.tiles--image--text--rows .tiles__tile__content .tiles__tile__text__inner {
            padding: 10px 10px 5px 10px; } }
  .tiles--image--text .tiles__tile__content {
    flex: 1; }
    .tiles--image--text .tiles__tile__content h2,
    .tiles--image--text .tiles__tile__content h3 {
      text-transform: none;
      padding: 0;
      font-size: 16px; }
    .tiles--image--text .tiles__tile__content h2 {
      color: #6BBA38;
      font-weight: 900; }
    .tiles--image--text .tiles__tile__content p {
      font-size: 14px;
      line-height: 23px; }
  .tiles--image--text .tiles__tile__text {
    padding: 20px; }
    .tiles--image--text .tiles__tile__text p a {
      text-decoration: underline; }
    @media (max-width: 767px) {
      .tiles--image--text .tiles__tile__text {
        padding: 10px; } }

.bg-light-green .tiles--image--text--rows .tiles__tile,
.bg-light-green .tiles--image--text--rows .tiles__tile:hover {
  background: #FFF; }

.tiles--image-hover .row {
  flex-wrap: wrap;
  justify-items: flex-start; }

.tiles--image-hover .column {
  flex: 1 1 50%;
  max-width: 50%;
  margin: 0 0 30px 0; }
  @media (max-width: 767px) {
    .tiles--image-hover .column {
      flex: 1 1 100%; } }

.tiles--image-hover .tiles__tile {
  height: 100%;
  color: #FFF; }
  .tiles--image-hover .tiles__tile .tiles__tile__image,
  .tiles--image-hover .tiles__tile .tiles__tile__text {
    position: absolute;
    width: 100%;
    height: 100%; }
  .tiles--image-hover .tiles__tile .tiles__tile__image {
    overflow: hidden; }
    .tiles--image-hover .tiles__tile .tiles__tile__image img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover"; }
    .tiles--image-hover .tiles__tile .tiles__tile__image .tiles__tile__label {
      position: absolute;
      left: 10px;
      bottom: 10px;
      max-width: 80%;
      max-height: 80%;
      border-left: 35px solid #6BBA38;
      padding: 5px 0 7px; }
      .tiles--image-hover .tiles__tile .tiles__tile__image .tiles__tile__label strong {
        background: #6BBA38;
        display: inline;
        width: auto;
        padding: 10px 0; }
      .tiles--image-hover .tiles__tile .tiles__tile__image .tiles__tile__label span {
        position: relative;
        left: -20px;
        white-space: normal;
        width: auto;
        word-break: normal;
        font-size: 18px;
        line-height: 30px; }
        @media (max-width: 480px) {
          .tiles--image-hover .tiles__tile .tiles__tile__image .tiles__tile__label span {
            font-size: .8125rem; } }
  .tiles--image-hover .tiles__tile .tiles__tile__text {
    max-height: 100%;
    overflow: hidden; }
  .tiles--image-hover .tiles__tile .tiles__tile__text {
    position: relative;
    display: none;
    padding: 30px;
    background: #6BBA38;
    font-size: 17px;
    line-height: 26px;
    box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.18); }
    .tiles--image-hover .tiles__tile .tiles__tile__text h2 {
      padding-bottom: 20px;
      font-size: 18px;
      font-weight: 900;
      text-transform: none; }
    .tiles--image-hover .tiles__tile .tiles__tile__text .tiles__tile__link {
      color: #FFF; }
  .tiles--image-hover .tiles__tile .tiles__tile__text__inner {
    position: relative;
    display: block;
    overflow: hidden;
    max-height: 87%; }
  .tiles--image-hover .tiles__tile .tiles__tile__link {
    position: absolute;
    right: 30px;
    bottom: 30px; }
  .tiles--image-hover .tiles__tile:hover .tiles__tile__text {
    display: block; }
    @media (max-width: 1024px) {
      .tiles--image-hover .tiles__tile:hover .tiles__tile__text {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0; }
        .tiles--image-hover .tiles__tile:hover .tiles__tile__text h2,
        .tiles--image-hover .tiles__tile:hover .tiles__tile__text p {
          display: none; }
        .tiles--image-hover .tiles__tile:hover .tiles__tile__text .tiles__tile__link {
          position: static; } }
    .tiles--image-hover .tiles__tile:hover .tiles__tile__text i {
      display: inline-block;
      padding: 0 0 0 10px;
      color: #c4e3b0; }

.slide__inner .tiles.tiles--image-hover .tiles__tile__image {
  border: 3px solid white; }

.tiles--text {
  display: flex;
  flex-direction: column; }
  .tiles--text .tiles__tile .tiles__tile__content {
    flex-direction: column;
    margin: 0 0 60px;
    padding: 20px; }
  .tiles--text .tiles__tile h2,
  .tiles--text .tiles__tile h3 {
    text-transform: none;
    padding: 0;
    font-size: 18px;
    font-weight: 600; }
  .tiles--text .tiles__tile h2 {
    color: #6BBA38;
    padding-bottom: 10px; }
  .tiles--text .tiles__tile p {
    padding: 20px 0 0;
    font-size: 17px; }

@media (max-width: 767px) {
  .tiles__tile h2,
  .tiles__tile h3,
  .tiles__tile p {
    font-size: 13px !important;
    line-height: 21px !important; }
  .tiles--text .tiles__tile .tiles__tile__content {
    margin-bottom: 20px; }
  .tiles--image--text.tiles--image--text--cols {
    margin: 0 -5px; }
  .tiles--image--text.tiles--image--text--cols .tiles__tile {
    margin: 0 0 10px;
    padding: 0 5px; }
  .tiles--image--text.tiles--image--text--cols .tiles__tile {
    flex: 0 1 50%; } }

.share {
  margin: 0 auto; }
  .share .row {
    justify-content: space-between;
    margin: 0;
    border-top: 1px solid #ccc;
    padding-top: 20px; }
  .share .sharelink,
  .share .backlink {
    flex-basis: auto;
    padding: 0; }
  .share .column.sharelink .link {
    text-align: right;
    justify-content: flex-end; }
  .share .link--icon .link__icon {
    border-color: #bbb;
    fill: #999; }
  .share .link--icon .link__label {
    font-size: 0.875em;
    color: #999; }
  .share .link--icon:hover, .share .link--icon:focus {
    cursor: pointer; }
    .share .link--icon:hover .link__label, .share .link--icon:focus .link__label {
      color: #6BBA38; }
    .share .link--icon:hover .link__icon, .share .link--icon:focus .link__icon {
      background: #6BBA38;
      fill: #FFF;
      border: 1px solid #6BBA38;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }
  .share.light .row {
    border-top: none;
    padding-top: 0; }

.share__actions {
  z-index: 90;
  position: absolute;
  top: -90px;
  right: 13px;
  display: none;
  padding: 5px 10px;
  width: 154px;
  background: #FFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.18);
  border-radius: 10px;
  line-height: 42px;
  text-align: center;
  white-space: nowrap; }
  .share__actions:before {
    position: absolute;
    right: 15px;
    bottom: -5px;
    z-index: 90;
    display: block;
    width: 20px;
    height: 20px;
    background: #FFF;
    transform: rotate(45deg);
    content: ""; }
  .share__actions a.button-icon {
    position: relative;
    z-index: 91;
    color: #BBB;
    display: inline-block;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    font-size: 14px;
    font-weight: normal; }
    .share__actions a.button-icon:hover {
      color: #FFF;
      background: #6BBA38;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16); }

.share input:checked + .share__actions {
  display: block; }

.share input:checked {
  background: #6BBA38;
  border-color: #6BBA38; }

.share input {
  position: absolute;
  left: -9999px; }

@media (max-width: 479px) {
  .share .link__label {
    display: none; } }

.container-teaser {
  display: flex;
  align-items: center; }
  .container-teaser .row {
    margin: 0 auto;
    max-width: 900px; }
  .container-teaser .row--reverse {
    flex-direction: row-reverse; }
    .container-teaser .row--reverse .teaser__image img {
      transform: rotate(5deg); }
  .container-teaser .column {
    flex: 1 1; }
  .container-teaser .teaser__image {
    position: absolute;
    top: -40%;
    right: 0;
    bottom: -40%;
    z-index: 9999;
    width: 150%;
    text-align: right; }
    .container-teaser .teaser__image img {
      display: inline-block;
      width: auto;
      max-height: 100%;
      transform: rotate(-5deg);
      background: none;
      box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.18); }
  .container-teaser .column.text {
    padding: 1rem 20px 0 40px; }
    .container-teaser .column.text h2, .container-teaser .column.text p {
      padding-bottom: .15rem; }
  .container-teaser .column.text,
  .container-teaser .column.target {
    display: flex;
    flex-direction: column;
    justify-content: center; }
  .container-teaser .column.target {
    flex: 0 1 auto;
    align-items: flex-end; }
  .container-teaser.container-teaser--programm {
    padding: 0 10px 10px; }
    @media (max-width: 767px) {
      .container-teaser.container-teaser--programm {
        display: block; }
        .container-teaser.container-teaser--programm .row {
          display: block; }
          .container-teaser.container-teaser--programm .row:after {
            content: ".";
            clear: both;
            display: block;
            visibility: hidden;
            height: 0; }
        .container-teaser.container-teaser--programm .column.image {
          display: block;
          float: left;
          flex: 0 !important;
          width: 50%; }
          .container-teaser.container-teaser--programm .column.image .teaser__image {
            position: relative;
            top: inherit;
            left: inherit;
            right: inherit;
            bottom: inherit;
            width: 100%;
            text-align: center; }
            .container-teaser.container-teaser--programm .column.image .teaser__image img {
              width: 70%; }
        .container-teaser.container-teaser--programm .column.text,
        .container-teaser.container-teaser--programm .column.target {
          flex: 1;
          padding: 0; }
        .container-teaser.container-teaser--programm .column.text {
          float: right;
          padding: 1rem 0 0;
          width: 50%; }
        .container-teaser.container-teaser--programm .column.target {
          float: right;
          width: 50%;
          padding: 1rem 0 1rem;
          text-align: right;
          align-items: flex-start; } }
  .container-teaser.container-teaser--blog .container-inner {
    max-width: 700px; }
  .container-teaser.container-teaser--blog .teaser__image {
    bottom: 0; }
  .container-teaser.container-teaser--blog .column.text p {
    font-size: 15px; }
    @media (max-width: 767px) {
      .container-teaser.container-teaser--blog .column.text p {
        font-size: 13px; } }
  .container-teaser.container-teaser--blog .column.text p a {
    color: #6BBA38;
    font-size: 13px;
    font-weight: 600;
    text-decoration: none; }
    .container-teaser.container-teaser--blog .column.text p a:after {
      display: inline-block;
      padding: 0 0 0 10px;
      font-family: "paricons";
      content: '\e92e'; }
  @media (max-width: 767px) {
    .container-teaser.container-teaser--blog {
      display: block; }
      .container-teaser.container-teaser--blog .teaser__image {
        top: -20%; }
      .container-teaser.container-teaser--blog .column.text {
        padding: 0 40px 0 10px; } }
    @media (max-width: 767px) and (max-width: 767px) {
      .container-teaser.container-teaser--blog .column.text {
        padding-right: 80px; } }

.topic h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: none; }

.topic .topic__tiles__tile {
  padding: 20px 10px;
  border-bottom: 1px solid #CCC; }
  .topic .topic__tiles__tile .row > .column:first-child {
    flex: 0 0 55px; }
    .topic .topic__tiles__tile .row > .column:first-child img {
      position: relative;
      top: 5px; }
  .topic .topic__tiles__tile .row > .column:last-child {
    flex: 1 1 auto;
    padding-left: 0; }
  .topic .topic__tiles__tile h3,
  .topic .topic__tiles__tile h4,
  .topic .topic__tiles__tile p {
    margin: 0;
    padding: 0;
    font-size: 18px;
    line-height: 27px; }
  .topic .topic__tiles__tile h3,
  .topic .topic__tiles__tile h4 {
    font-weight: 600;
    text-transform: none; }
  .topic .topic__tiles__tile h3 {
    color: #6BBA38; }
  .topic .topic__tiles__tile h4 {
    color: #333; }
  .topic .topic__tiles__tile p {
    font-weight: 400; }

.searchform {
  position: relative;
  width: 100%;
  height: 60px; }
  .searchform form {
    margin: 0;
    padding: 0; }
  .searchform input,
  .searchform button {
    margin: 0;
    padding: 0;
    border: 0 !important;
    outline: 0 !important;
    background: none; }
    .searchform input i,
    .searchform button i {
      display: inline-block;
      margin-right: 10px; }
  .searchform div {
    position: relative; }
  .searchform div.short {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 48px; }
  .searchform div.sword,
  .searchform div.submit {
    display: flex;
    flex: 1;
    align-items: center; }
  .searchform div.sword {
    flex: 1 1;
    background: #fff;
    border-radius: 1.5rem 0 0 1.5rem;
    border: 1px solid #bbb;
    padding: 0 20px; }
  .searchform div.submit {
    flex: 0 1 130px;
    text-align: center; }
    @media (max-width: 767px) {
      .searchform div.submit {
        flex: 0 1 3rem; }
        .searchform div.submit span {
          display: none; } }
    .searchform div.submit button {
      width: 100%;
      height: 100%;
      margin: 0 auto;
      border-radius: 0 1.5rem 1.5rem 0;
      border-color: #6BBA38;
      background: #6BBA38;
      font-weight: 900;
      font-size: 13px;
      text-transform: uppercase;
      color: #fff; }
  .searchform div.details {
    display: none;
    position: absolute;
    top: 100%;
    left: -20px;
    right: -20px;
    background: #fff;
    color: #666; }
    .searchform div.details label {
      display: block;
      padding: 20px;
      text-align: left; }
      .searchform div.details label input[type='checkbox'] {
        display: inline-block;
        width: auto; }
  @media (min-width: 960px) {
    .searchform.extended, .searchform.active {
      height: auto;
      padding: 30px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.16); } }
  @media (min-width: 960px) {
    .searchform.extended .details, .searchform.active .details {
      display: block;
      position: static;
      margin-top: 20px; }
      .searchform.extended .details select, .searchform.active .details select {
        width: 100%; } }
  .searchform.extended {
    width: 100%; }
  .searchform.active {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.48); }
  .searchform.startpage {
    width: 640px; }

@media (max-width: 480px) {
  header#top .searchform {
    margin-bottom: 0.5rem; } }

.tooltip {
  display: inline-block;
  position: relative;
  top: -10px;
  z-index: 10; }

.tooltip__trigger {
  color: #999; }

.tooltip__content {
  position: absolute !important;
  top: 0;
  left: 40px;
  padding: 10px;
  max-width: 200px;
  height: auto;
  transform: translateY(-50%);
  background: #fff;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.18);
  display: none; }
  .tooltip__content:before {
    position: absolute;
    left: -10px;
    top: 50%;
    z-index: -1;
    display: block;
    margin-top: -10px;
    width: 20px;
    height: 20px;
    background: #fff;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
    content: ""; }

.tooltip.active .tooltip__trigger,
.tooltip:hover .tooltip__trigger {
  color: #6BBA38; }

.tooltip.active .tooltip__content,
.tooltip:hover .tooltip__content {
  display: block; }

@media (max-width: 767px) {
  .tooltip__content {
    transform: translate(-50%, 100%);
    top: auto;
    bottom: -10px;
    left: auto; }
    .tooltip__content:before {
      transform: rotate(135deg);
      top: 0;
      left: 50%; } }

.accordion {
  /* multi element accordion */ }
  .accordion .accordion-toggle {
    display: inline-flex;
    align-items: center;
    width: auto;
    cursor: pointer; }
    .accordion .accordion-toggle:hover .icon_circle {
      background-color: #89C860; }
    .accordion .accordion-toggle .icon_circle {
      width: 1em;
      height: 1em;
      margin-left: 1ex; }
      .accordion .accordion-toggle .icon_circle:before {
        font-size: 0.5em; }
  .accordion .accordion-content {
    display: none; }
  .accordion .accordion-content.default {
    display: block; }
  .accordion .accordion-entry {
    margin-bottom: 10px; }
    .accordion .accordion-entry:hover {
      box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.27); }
    .accordion .accordion-entry.entry-open {
      margin-bottom: 20px; }
    .accordion .accordion-entry dt,
    .accordion .accordion-entry dd {
      padding: 20px; }
      @media (max-width: 768px) {
        .accordion .accordion-entry dt,
        .accordion .accordion-entry dd {
          padding: .8461538461538461em 1.1538461538461537em; } }
    .accordion .accordion-entry dt {
      position: relative;
      cursor: pointer;
      color: #fff;
      font-size: 1.1em;
      text-align: left; }
      .accordion .accordion-entry dt i {
        display: inline-block;
        position: absolute;
        top: 2em;
        right: 2.4em;
        font-size: 0.5625rem; }
        @media (max-width: 768px) {
          .accordion .accordion-entry dt i {
            top: 1.5em;
            right: 2em; } }
      .accordion .accordion-entry dt h3 {
        font-size: inherit;
        margin: 0 1.8em 0 0;
        padding: 0; }
      .accordion .accordion-entry dt.bg-green {
        background-color: #6BBA38; }
      .accordion .accordion-entry dt.bg-yellow {
        background-color: #F8AA00; }
      .accordion .accordion-entry dt.bg-blue-light {
        background-color: #31B4E4; }
      .accordion .accordion-entry dt.bg-orange {
        background-color: #EB6414; }
      .accordion .accordion-entry dt.bg-purple {
        background-color: #B17BAF; }
      .accordion .accordion-entry dt.bg-red-dark {
        background-color: #AE1C41; }
      .accordion .accordion-entry dt.bg-red-light {
        background-color: #EE868E; }
      .accordion .accordion-entry dt.bg-blue-dark {
        background-color: #0070A0; }
    .accordion .accordion-entry dt i.icon-Up_1 {
      display: none; }
    .accordion .accordion-entry.entry-open dt i.icon-Up_1 {
      display: inline-block; }
    .accordion .accordion-entry.entry-open dt i.icon-Down_1 {
      display: none; }
    .accordion .accordion-entry dd {
      background-color: #F8F8F8; }
      .accordion .accordion-entry dd p:last-child {
        padding-bottom: 0; }
      .bg-light-gray .accordion .accordion-entry dd,
      .bg-light-green .accordion .accordion-entry dd,
      .bg-light-blue .accordion .accordion-entry dd {
        background-color: #fff; }

.cc-window {
  background-color: #005DAF;
  color: #fff; }
  .cc-window span.cc-message {
    flex: 1 1 auto; }
  .cc-window .cc-floating.cc-type-info.cc-theme-classic .cc-compliance {
    flex: 1 1 auto; }
  .cc-window a.button {
    height: auto; }
  .cc-window .cc-btn {
    border-width: 0;
    white-space: nowrap; }

.icon_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 2.625rem;
  height: 2.625rem;
  border-radius: 50%;
  background-color: #6BBA38;
  text-align: center;
  color: #fff;
  font-weight: 900; }
  .icon_circle:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
    background-color: #89C860;
    color: rgba(255, 255, 255, 0.45); }
  .icon_circle svg {
    display: block;
    width: 1rem;
    height: 1rem;
    fill: #fff; }
  span.icon_circle {
    margin-right: 0.625rem; }

.nav__main .shopping > a {
  color: #888; }

.nav__main .shopping:hover > a {
  color: #89C860; }

@media (min-width: 1200px) {
  .nav__main .shopping a:first-of-type {
    position: relative; }
    .nav__main .shopping a:first-of-type span {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 50%;
      background-color: #6BBA38;
      text-align: center;
      color: #fff;
      font-weight: 900;
      display: inline-block;
      position: relative;
      top: -1.55em;
      left: -1.8em;
      width: 1.6em;
      height: 1.6em;
      padding: 0.4425em 0.22175em;
      line-height: 0.715em;
      font-size: 0.715em; }
      .nav__main .shopping a:first-of-type span:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        background-color: #89C860;
        color: rgba(255, 255, 255, 0.45); }
    .nav__main .shopping a:first-of-type:hover span {
      background: #89C860; }
    .nav__main .shopping a:first-of-type > i::before {
      top: auto;
      margin-right: 0; } }

@media (max-width: 960px) {
  .nav__main .shopping .counter {
    display: none; } }

.menue,
.page_access,
.graytop,
.darkgraytop,
.hauptseite_clickpfad,
#veranstkalender,
.hauptseite .kontakt {
  display: none; }

.course-list-item {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  background-color: #fff; }
  @media (max-width: 768px) {
    .course-list-item {
      flex-direction: column; } }
  .course-list-item:hover {
    box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.27); }
    @media (min-width: 1200px) {
      .course-list-item:hover .course-list-item-content.course-list-item-content {
        background-color: rgba(178, 255, 89, 0.15); } }
    .course-list-item:hover .course-list-item-info .course-list-item-info-overlay {
      display: flex; }
    .course-list-item:hover .course-list-item-badge {
      box-shadow: 4px 4px 12px 0 rgba(0, 0, 0, 0.27); }
  .course-list-item .course-list-item-content {
    flex: 1 1 68%;
    padding: 20px;
    background-color: #fff; }
    @media (max-width: 768px) {
      .course-list-item .course-list-item-content {
        padding: .8461538461538461em 3.5em 0 1.1538461538461537em; } }
    .course-list-item .course-list-item-content .course-list-item-title {
      margin-bottom: 14px;
      line-height: 1.6875em;
      font-weight: 400; }
      @media (max-width: 768px) {
        .course-list-item .course-list-item-content .course-list-item-title {
          margin-bottom: 0.35em;
          font-size: 0.8125rem;
          font-weight: bold;
          line-height: 1.35em; } }
      .course-list-item .course-list-item-content .course-list-item-title span.title {
        font-size: 1.15em; }
        @media (max-width: 768px) {
          .course-list-item .course-list-item-content .course-list-item-title span.title {
            font-size: 1em; } }
      @media (max-width: 768px) {
        .course-list-item .course-list-item-content .course-list-item-title span.subtitle {
          display: none; } }
  .course-list-item .course-list-item-info {
    display: flex;
    flex: 1 1 32%;
    flex-direction: column;
    justify-content: start;
    padding: 20px;
    font-size: 14px; }
    @media (min-width: 769px) {
      .course-list-item .course-list-item-info {
        position: relative;
        background-color: #6BBA38; } }
    @media (max-width: 768px) {
      .course-list-item .course-list-item-info {
        padding: 0 3.5em .8461538461538461em 1.1538461538461537em;
        font-size: .8125rem;
        line-height: 1.6153846153846154em; } }
    .course-list-item .course-list-item-info .course-list-item-info-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      text-decoration: none;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0;
      background-color: #89C860; }
      @media (min-width: 769px) {
        .course-list-item .course-list-item-info .course-list-item-info-overlay {
          display: none; } }
      @media (max-width: 768px) {
        .course-list-item .course-list-item-info .course-list-item-info-overlay {
          left: auto;
          width: 3.075em;
          background-color: #6BBA38; } }
      .course-list-item .course-list-item-info .course-list-item-info-overlay p {
        padding: 0;
        text-align: center;
        text-transform: uppercase;
        color: #fff;
        font-size: 13px;
        font-weight: 900; }
        @media (max-width: 768px) {
          .course-list-item .course-list-item-info .course-list-item-info-overlay p span {
            display: none; } }
        .course-list-item .course-list-item-info .course-list-item-info-overlay p i[class^="icon-"] {
          color: rgba(255, 255, 255, 0.45); }
          .course-list-item .course-list-item-info .course-list-item-info-overlay p i[class^="icon-"]:before {
            left: 1ex; }
          @media (max-width: 768px) {
            .course-list-item .course-list-item-info .course-list-item-info-overlay p i[class^="icon-"]:before {
              left: 0; } }
    @media (min-width: 769px) {
      .course-list-item .course-list-item-info .course-list-item-time,
      .course-list-item .course-list-item-info .course-list-item-location {
        font-weight: 900;
        color: #fff; } }
    .course-list-item .course-list-item-info .course-list-item-time span,
    .course-list-item .course-list-item-info .course-list-item-location span {
      font-weight: 400; }
    @media (min-width: 769px) {
      .course-list-item .course-list-item-info .course-list-item-price {
        font-weight: 900; } }
    .course-list-item .course-list-item-info .course-list-item-price span {
      font-weight: 400; }
    .course-list-item .course-list-item-info > div {
      position: relative;
      padding-left: 1.714285714em; }
      .course-list-item .course-list-item-info > div > i {
        position: absolute;
        left: 0;
        width: 1.142857143em;
        font-size: 1.142857143em;
        text-align: center;
        color: rgba(255, 255, 255, 0.45); }
        @media (max-width: 768px) {
          .course-list-item .course-list-item-info > div > i {
            color: #bbb; } }
  .course-list-item .course-list-item-badge {
    position: absolute;
    top: -5px;
    right: -5px;
    z-index: 3; }
    @media (max-width: 768px) {
      .course-list-item .course-list-item-badge {
        top: auto;
        bottom: .8461538461538461em; } }
    .course-list-item .course-list-item-badge .link-wrapper {
      position: relative; }
  .course-list-item.danger .course-list-item-content {
    background-color: #E3E3E3;
    color: #555555; }
  .course-list-item.danger .course-list-item-info {
    background-color: #C6C6C6; }
    @media (max-width: 768px) {
      .course-list-item.danger .course-list-item-info {
        background-color: #e3e3e3; } }
    .course-list-item.danger .course-list-item-info .course-list-item-time,
    .course-list-item.danger .course-list-item-info .course-list-item-location,
    .course-list-item.danger .course-list-item-info .course-list-item-price,
    .course-list-item.danger .course-list-item-info .course-list-item-duration,
    .course-list-item.danger .course-list-item-info .course-list-item-discount {
      color: #777; }
      .course-list-item.danger .course-list-item-info .course-list-item-time i,
      .course-list-item.danger .course-list-item-info .course-list-item-location i,
      .course-list-item.danger .course-list-item-info .course-list-item-price i,
      .course-list-item.danger .course-list-item-info .course-list-item-duration i,
      .course-list-item.danger .course-list-item-info .course-list-item-discount i {
        color: #999; }
  .course-list-item > a {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    text-decoration: none; }

.course-list-item-categories {
  display: flex;
  flex-wrap: wrap; }
  .course-list-item-categories .course-list-item-category {
    z-index: 3;
    display: inline-block;
    margin-right: 20px;
    color: #6BBA38;
    font-size: 14px; }
    @media (max-width: 768px) {
      .course-list-item-categories .course-list-item-category {
        display: none; } }
    .course-list-item-categories .course-list-item-category .icon-Tag {
      color: #999; }

.course-list-item-badge {
  display: inline-block !important;
  width: auto !important;
  padding: 6px 9px;
  background-color: #F8AA00;
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  line-height: 1em;
  text-transform: uppercase; }

@media (max-width: 768px) {
  .danger .course-list-item-info .course-list-item-info-overlay {
    background-color: #c6c6c6; } }

.danger .course-list-item-info .course-list-item-info-overlay p i[class*=icon-] {
  color: #999; }
  @media (max-width: 768px) {
    .danger .course-list-item-info .course-list-item-info-overlay p i[class*=icon-] {
      color: #777; } }

.danger .course-list-item-badge {
  background-color: #CD1316; }

.course-list-item .course-list-item-badge {
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff; }
  @media (max-width: 768px) {
    .course-list-item .course-list-item-badge {
      font-size: .625rem; } }

.tag-cooperation {
  padding: 0;
  font-size: .75em;
  font-weight: 900;
  text-transform: uppercase;
  color: #005DAF; }

#seminarangebote_details .statusmeldung {
  display: none; }

#seminarangebote_details .page-header {
  z-index: 10; }
  #seminarangebote_details .page-header .row > .column {
    padding-right: 3.4375em; }

#seminarangebote_details h2 {
  text-transform: uppercase; }

#seminarangebote_details .course-details h2 i {
  position: absolute;
  left: 0;
  width: 1.142857143em;
  font-size: 1.142857143em;
  text-align: center;
  margin-right: .625em;
  color: #bbb; }

#seminarangebote_details .course-details .row {
  margin: 0; }
  #seminarangebote_details .course-details .row > .column {
    padding: 0; }

#seminarangebote_details .course-details .course-overview-similar {
  display: none; }

#seminarangebote_details .course-details .column-main {
  flex: auto; }

#seminarangebote_details .course-details .column-side {
  flex-shrink: 0;
  flex-grow: 0;
  min-width: 27ex; }

#seminarangebote_details .container-outer {
  padding: 0; }
  #seminarangebote_details .container-outer.danger {
    background-color: #e3e3e3; }
  #seminarangebote_details .container-outer.disabled .basic-information .tutors {
    margin-bottom: 0;
    border-bottom: 0;
    padding-bottom: 0; }
    #seminarangebote_details .container-outer.disabled .basic-information .tutors a {
      font-size: 0.823529412em; }

#seminarangebote_details .container--header .container-inner {
  padding: 0; }

#seminarangebote_details .container--header h1 {
  font-weight: 800;
  text-transform: none; }

#seminarangebote_details .container--header h2 {
  font-weight: 300;
  text-transform: none; }

#seminarangebote_details .container--header h3 {
  font-size: 1em; }

#seminarangebote_details .container--content.container-outer {
  padding-top: 50px; }
  #seminarangebote_details .container--content.container-outer:before {
    background-color: #fff; }

#seminarangebote_details .container--content.container-inner {
  padding: 0; }

#seminarangebote_details .course-list-header .tag-cooperation {
  margin-bottom: .875em; }

#seminarangebote_details .course-list-header .course-list-item-categories {
  margin-bottom: 2em; }

#seminarangebote_details a.regular {
  color: #6BBA38;
  text-decoration: none; }

#seminarangebote_details a.button.checkout {
  margin-bottom: 1.25em; }

#seminarangebote_details a[href^="mailto"] {
  text-decoration: underline; }

#seminarangebote_details .basic-information h2 {
  margin-top: 2.15625em;
  border-top: 1px solid #ccc;
  padding-top: 2.28125em;
  font-size: 1.1em; }

#seminarangebote_details .basic-information .tutors {
  margin-bottom: 2.28125em;
  border-bottom: 1px solid #ccc;
  padding-bottom: 2.15625em; }
  #seminarangebote_details .basic-information .tutors h3 {
    padding: 0;
    font-weight: 400; }
  #seminarangebote_details .basic-information .tutors a {
    font-size: 0.823529412em; }

#seminarangebote_details .basic-information .actions {
  display: inline-flex;
  flex-direction: column; }

#seminarangebote_details .detailed-information-column {
  position: absolute; }

#seminarangebote_details .detailed-information {
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.18);
  padding: 1px 0 0; }
  #seminarangebote_details .detailed-information a {
    word-break: break-all;
    /* Non standard for WebKit */
    word-break: break-word; }
  #seminarangebote_details .detailed-information div,
  #seminarangebote_details .detailed-information h1, #seminarangebote_details .detailed-information h2, #seminarangebote_details .detailed-information h3, #seminarangebote_details .detailed-information h4, #seminarangebote_details .detailed-information h5, #seminarangebote_details .detailed-information h6 {
    line-height: 1.642857143em; }
  #seminarangebote_details .detailed-information > div {
    border-top: 1px solid #ddd; }
    #seminarangebote_details .detailed-information > div:first-of-type {
      border-top-size: 0; }
  #seminarangebote_details .detailed-information h2 {
    padding-left: 1.857142857em;
    font-size: 0.875em;
    font-weight: 900;
    text-transform: uppercase; }
    #seminarangebote_details .detailed-information h2 span {
      font-weight: 400;
      text-transform: none; }
  #seminarangebote_details .detailed-information .content {
    padding: 0 1.857142857em;
    font-size: 0.875em; }
  #seminarangebote_details .detailed-information .dates h2 {
    position: relative; }
    #seminarangebote_details .detailed-information .dates h2 span.deadline {
      display: inline-block;
      position: absolute;
      top: 0;
      right: -2.25em;
      border: 1px white solid;
      padding: 0.5em 0.75em;
      background-color: #005da9;
      line-height: 1.2em;
      font-size: 0.857142857em;
      font-weight: 900;
      text-transform: uppercase;
      color: #fff; }
  #seminarangebote_details .detailed-information .dates .content > a {
    display: inline-block;
    margin-top: 1.142857143em; }
  #seminarangebote_details .detailed-information .contacts a {
    display: inline-block; }
  #seminarangebote_details .detailed-information .contacts .contact-person {
    margin-bottom: 1.571428571em; }
    #seminarangebote_details .detailed-information .contacts .contact-person img.portrait {
      margin-bottom: 1.25ex; }
    #seminarangebote_details .detailed-information .contacts .contact-person:last-child {
      margin-bottom: 0; }
  #seminarangebote_details .detailed-information .contacts .name {
    font-weight: 900;
    color: #6BBA38; }
  #seminarangebote_details .detailed-information .location a {
    display: inline-block; }
    #seminarangebote_details .detailed-information .location a.regular {
      text-decoration: underline;
      color: #333; }
  #seminarangebote_details .detailed-information .actions .button {
    display: inline-block;
    width: 100%; }
  #seminarangebote_details .detailed-information .accordion-toggle .more {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    background-color: #fff;
    text-align: center;
    color: #fff;
    font-weight: 900;
    display: inline-block;
    width: 1.285714286em;
    height: 1.285714286em;
    margin-left: 0.5rem;
    border: 0.07143em solid #6BBA38;
    padding: .071428571em;
    line-height: 1em;
    color: #6BBA38; }
    #seminarangebote_details .detailed-information .accordion-toggle .more:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      background-color: #fff;
      color: rgba(255, 255, 255, 0.45); }
    #seminarangebote_details .detailed-information .accordion-toggle .more:hover {
      background-color: #6BBA38;
      color: #fff; }
  #seminarangebote_details .detailed-information .accordion-toggle.accordion-open .label {
    text-decoration: underline; }
  #seminarangebote_details .detailed-information .accordion-toggle.accordion-open .more {
    background-color: #6BBA38;
    color: #fff; }
    #seminarangebote_details .detailed-information .accordion-toggle.accordion-open .more:hover {
      border-color: #89C860;
      color: #89C860; }

#seminarangebote_details .course-number {
  margin-top: 1.375em;
  text-align: center;
  font-size: 0.75em;
  color: #777; }

#seminarangebote_details .similar-courses .page-header,
#seminarangebote_details .similar-courses .page-content,
#seminarangebote_details .similar-courses .course-not-available {
  display: none; }

#seminarangebote_details .detailed-dates ul {
  margin: 1.428571429em -2.857142857em 0;
  list-style: none; }
  #seminarangebote_details .detailed-dates ul .alt1,
  #seminarangebote_details .detailed-dates ul .alt2 {
    padding: .571428571em 3.357142857em .642857143em; }
  #seminarangebote_details .detailed-dates ul .alt1 {
    background-color: #fbfbfb; }

#seminarangebote_details .column.mobile .detailed-information-column {
  position: static; }
  #seminarangebote_details .column.mobile .detailed-information-column .detailed-information {
    box-shadow: none; }
    #seminarangebote_details .column.mobile .detailed-information-column .detailed-information > div:first-of-type {
      border-top-width: 1px; }

@media (max-width: 375px) {
  #seminarangebote_details .basic-information .actions {
    flex-wrap: wrap; }
  #seminarangebote_details .basic-information a.button,
  #seminarangebote_details .basic-information a.download,
  #seminarangebote_details .basic-information button.button,
  #seminarangebote_details .basic-information input.button {
    width: 100%; } }

@media (min-width: 376px) {
  #seminarangebote_details .basic-information .actions .button.checkout {
    margin-right: 1rem; } }

@media (max-width: 480px) {
  #seminarangebote_details .basic-information .actions {
    justify-content: space-between; }
    #seminarangebote_details .basic-information .actions .button.checkout,
    #seminarangebote_details .basic-information .actions .button.cart {
      flex: 1 0 auto; } }

@media (min-width: 481px) {
  #seminarangebote_details .basic-information .actions {
    justify-content: flex-start; }
    #seminarangebote_details .basic-information .actions .button.checkout,
    #seminarangebote_details .basic-information .actions .button.cart {
      flex: 0 0 auto; } }

@media (max-width: 767px) {
  #seminarangebote_details .page-header h1 {
    font-size: 21px; }
  #seminarangebote_details .page-header h2 {
    font-size: 16px; }
  #seminarangebote_details .page-content .row {
    flex-direction: column-reverse; }
  #seminarangebote_details .column-side {
    display: none; }
  #seminarangebote_details .basic-information .actions {
    display: flex;
    flex-direction: row; } }

@media (min-width: 768px) {
  .course-list-header {
    padding-right: 3rem; }
  #seminarangebote_details .basic-information .actions .button.checkout {
    margin-right: 0; }
  #seminarangebote_details .column.mobile .detailed-information-column {
    display: none; } }

.checkout h1, .checkout h2, .checkout h3, .checkout h4, .checkout h5, .checkout h6,
.checkout div.checkout-subtitle.checkout-subtitle {
  text-align: center; }

.checkout .subtitle.subtitle.subtitle {
  text-align: center; }

.checkout p .link {
  display: inline-flex; }

.checkout div.alternative-address {
  margin-bottom: 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  padding: 1.285714286em 0; }
  .checkout div.alternative-address p,
  .checkout div.alternative-address fieldset {
    margin: 0;
    padding: 0; }
  .checkout div.alternative-address p {
    padding: 0 1.785714286em; }
  .checkout div.alternative-address fieldset {
    padding: 1.285714286em 0 0 0; }

.checkout .steps {
  width: 80%;
  margin: auto;
  font-size: 16px; }

.checkout .steps-top {
  display: flex;
  justify-content: space-around;
  position: relative; }

.checkout .steps-bottom {
  display: flex; }

.checkout .line {
  position: absolute;
  top: 49%;
  left: 15%;
  right: 15%;
  border-top: 3px rgba(51, 51, 51, 0.13) solid; }

.checkout .step {
  display: flex;
  flex: 1 1 33.33%;
  align-items: center;
  z-index: 1; }

.checkout .graphic,
.checkout .text {
  display: flex; }

.checkout .graphic {
  z-index: 10;
  height: 2.875rem;
  width: 2.875rem;
  margin: auto;
  border-radius: 50%;
  background-color: #e5f2f8; }

.checkout .graphic .graphic {
  height: .375rem;
  width: .375rem;
  margin: auto;
  border-radius: 50%;
  background-color: rgba(51, 51, 51, 0.13); }

.checkout .text {
  flex: 1 1 33.33%;
  align-items: top;
  font-weight: bold;
  text-transform: uppercase;
  color: #777; }
  .checkout .text.current-step {
    color: #333; }
  .checkout .text.text.text p {
    width: 100%;
    font-size: .875rem;
    text-align: center; }

.checkout .current-step > .graphic {
  height: 6.5rem;
  width: 6.5rem;
  background-color: #fff;
  border: 1.25rem #e5f2f8 solid; }
  .checkout .current-step > .graphic .graphic {
    background-color: #6BBA38; }

.checkout .step-completed > .graphic {
  height: 6.5rem;
  width: 6.5rem;
  border: 1.25rem #e5f2f8 solid;
  background-color: #6BBA38; }
  .checkout .step-completed > .graphic .graphic {
    background-color: #fff; }

.checkout fieldset.geschlecht {
  margin-left: 25px; }
  .checkout fieldset.geschlecht label {
    display: inline-block;
    margin-right: 10px; }

.checkout section.shopping-cart-course-list {
  margin-top: 20px;
  margin-bottom: 20px; }

.checkout .warnungpflichtfelder-header {
  color: #CD1316; }

.anmeldeformular_but {
  display: inline-block;
  margin-right: 20px; }

@media (max-width: 767px) {
  .checkout .row--50-50 {
    flex-direction: column; }
  .checkout .steps {
    width: 90%; }
  .checkout .current-step > .graphic {
    height: 5.25rem;
    width: 5.25rem;
    border-width: .75rem; }
  .checkout .step-completed > .graphic {
    height: 5.25rem;
    width: 5.25rem;
    border: 0.75rem #e5f2f8 solid;
    background-color: #6BBA38; }
  .checkout .step-number {
    display: none; } }

.page-33 .statusmeldung {
  display: none; }

.page-33 h1 {
  margin-bottom: 2.555555556em;
  padding-bottom: 0; }

.page-33 .course-list {
  margin-bottom: 3.5625em; }

.page-33 .course {
  margin-bottom: 1.6875em; }
  .page-33 .course .actions {
    padding-left: 1.25em;
    line-height: 1em; }
    .page-33 .course .actions .remove {
      font-size: 0.75em;
      color: #777; }
      .page-33 .course .actions .remove:hover {
        color: #E23F30; }

.page-33 .summary {
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 2.0625em;
  text-align: right; }
  .page-33 .summary .actions {
    margin-bottom: 5.625em; }
    .page-33 .summary .actions .button {
      padding-right: 5.230769231em;
      padding-left: 5.230769231em; }

.page-33 .sums {
  margin-bottom: 1.875em;
  padding: .8125em; }
  .page-33 .sums .total {
    font-size: 1.125em;
    font-weight: 900; }
  .page-33 .sums .total-discounted {
    font-size: .875em; }

.page-33 .share {
  border: none;
  margin: 0; }

.tutor-details .tutor-name {
  text-transform: uppercase !important; }

.tutor-details .column.back_link {
  display: flex;
  flex-direction: row;
  justify-content: flex-end; }

.tutor-details .column.portrait {
  display: flex;
  flex-direction: row;
  justify-content: center; }

section.shopping-cart-course-list {
  margin-top: 100px;
  margin-bottom: 100px;
  background-color: #fff;
  font-size: .875rem;
  line-height: 1.5em; }
  section.shopping-cart-course-list > * {
    padding: 1.5625rem 1.3125rem; }
  section.shopping-cart-course-list header {
    margin: 0;
    background-color: #6BBA38;
    color: #fff; }
    section.shopping-cart-course-list header h1, section.shopping-cart-course-list header h2, section.shopping-cart-course-list header h3, section.shopping-cart-course-list header h4, section.shopping-cart-course-list header h5, section.shopping-cart-course-list header h6 {
      margin: 0;
      text-align: left;
      font-weight: 900; }
    section.shopping-cart-course-list header h1 {
      padding-bottom: 0;
      font-size: .875rem;
      line-height: 1.5em; }
  section.shopping-cart-course-list .shopping-cart-course-list {
    padding-top: 0;
    padding-bottom: 0; }
    section.shopping-cart-course-list .shopping-cart-course-list div:last-child .shopping-cart-course-list-item-content {
      border-bottom: 0;
      margin-bottom: 0; }
  section.shopping-cart-course-list .shopping-cart-course-list-item {
    padding-top: 0;
    padding-bottom: 0; }
    section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1.5625rem;
      margin-bottom: 1.5625rem;
      border-bottom: 1px #bbb solid;
      padding-bottom: 1.5625rem; }
      section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-title {
        flex: 2 1 66.66%; }
        section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-title h1, section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-title h2, section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-title h3 {
          padding-bottom: 0;
          font-size: .875rem;
          line-height: 1.5em;
          text-align: left; }
        section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-title h3 {
          font-weight: normal; }
      section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-prices {
        flex: 1 1 33.34%; }
        @media (min-width: 769px) {
          section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-prices {
            text-align: right; } }
        section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-prices b {
          font-weight: bold; }
      section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-info {
        flex: 1 1 100%;
        margin-top: 1rem;
        background-color: transparent; }
        @media (max-width: 767px) {
          section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-info {
            display: flex;
            flex-direction: column; } }
        section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-info > * {
          display: inline-block;
          padding-right: 2.85714285714em; }
        section.shopping-cart-course-list .shopping-cart-course-list-item .shopping-cart-course-list-item-content .shopping-cart-course-list-item-info i {
          color: #bbb; }
  section.shopping-cart-course-list footer {
    margin: 0;
    background-color: #fbfbfb;
    color: #000;
    line-height: 1.6875rem; }
    section.shopping-cart-course-list footer .summary {
      margin-bottom: 0;
      border-top: none;
      text-align: right; }
    section.shopping-cart-course-list footer .sums {
      margin-bottom: 0;
      padding: 0; }
      section.shopping-cart-course-list footer .sums .total {
        font-size: 1.25rem;
        font-weight: bold; }

.page-386 {
  background-color: #E3F2FD; }
  @media (min-width: 1200px) {
    .page-386 {
      padding-top: 60px; } }
  .page-386 form#schnellsuche {
    display: block; }
    @media (max-width: 767px) {
      .page-386 form#schnellsuche > h1 {
        text-align: left !important;
        padding-left: 20px; } }

.anzahlbereich {
  padding-left: 20px; }
  @media (max-width: 479px) {
    .anzahlbereich .separator {
      display: none; } }

.column .sortierungsbereich.sortierungsbereich {
  display: inline-block;
  float: right;
  width: auto;
  padding-right: .625rem;
  padding-left: .625rem; }
  @media (max-width: 767px) {
    .column .sortierungsbereich.sortierungsbereich {
      display: none; } }

span.kursanzahl {
  color: #6BBA38; }

.column.column-erweiterte-suche {
  min-width: 17.25rem; }
  @media (max-width: 767px) {
    .column.column-erweiterte-suche {
      display: none; } }

.column-erweiterte-suche-section {
  background: #fbfbfb; }

.erweiterte-suche-header h2 {
  padding: 0.9375rem;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase; }

.erweiterte-suche-filter {
  padding: 15px;
  position: relative;
  border-top: 1px solid #ddd;
  font-size: 0.875rem; }
  .erweiterte-suche-filter ul:first-of-type {
    list-style: none; }
  .erweiterte-suche-filter.borderunten {
    border-top: 0 none;
    border-bottom: 1px solid #ddd;
    padding-top: 0; }
  .erweiterte-suche-filter.noborder {
    border-top: 0 none;
    padding-top: 0; }
  .erweiterte-suche-filter input[type="text"] {
    border-radius: 30px;
    border-color: #bbb;
    margin-bottom: 0;
    padding-top: .5rem;
    padding-right: 20px;
    padding-bottom: .5rem;
    padding-left: 20px;
    color: #bbb; }
    .erweiterte-suche-filter input[type="text"]:hover {
      border-color: #bbb; }
  .erweiterte-suche-filter.kategs {
    padding: 0; }
  .erweiterte-suche-filter.kennz {
    padding: 0; }
  .erweiterte-suche-filter.veranart {
    padding-right: 0; }

.kw-katexceptkennzlist li,
.kw-katkategorielist li,
.kw-katveranartlist li {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.9375rem; }
  .kw-katexceptkennzlist li.selected,
  .kw-katkategorielist li.selected,
  .kw-katveranartlist li.selected {
    background-color: #F3FFE5; }
  .kw-katexceptkennzlist li label,
  .kw-katkategorielist li label,
  .kw-katveranartlist li label {
    display: flex;
    align-items: center; }
  .kw-katexceptkennzlist li svg,
  .kw-katkategorielist li svg,
  .kw-katveranartlist li svg {
    flex: 1 1 100%;
    max-width: 1.875rem;
    max-height: 1.875rem;
    margin-right: 0.9375rem;
    fill: #6BBA38; }

.kw-katexceptkennzlist li {
  padding: 0.9375rem; }

.kw-katkategorielist li {
  min-height: 3.75rem;
  border-bottom: 1px solid #ddd;
  padding-left: 0.9375rem; }
  .kw-katkategorielist li:last-child {
    border-bottom: none; }

.kw-katkategorielist label {
  width: 100%; }

.kw-katkategorielist input {
  margin-left: 3em; }

.kw-katveranartlist li.selected {
  background-color: transparent; }

#schnellsuchemobil {
  padding: 0 !important; }

.searchform-sort-mobile h2 {
  padding-left: 1.1764705882352942rem;
  font-size: 12px;
  line-height: 17px; }

.searchform-sort-mobile ul {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none; }
  .searchform-sort-mobile ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.75rem;
    border-bottom: 1px solid #ddd;
    padding-right: 1.1764705882352942rem;
    padding-left: 1.1764705882352942rem; }
    .searchform-sort-mobile ul li:last-of-type {
      border-bottom: 0; }
  .searchform-sort-mobile ul li a {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    font-size: 13px;
    line-height: 23px; }
    .searchform-sort-mobile ul li a i {
      margin-left: 2ex;
      font-size: .5625rem; }

.pager {
  display: flex !important;
  flex-direction: row;
  margin-top: 2rem; }
  .pager .pagination {
    display: inline-flex;
    flex-direction: row;
    list-style: none;
    margin-left: 0 !important; }
    .pager .pagination li {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 50%;
      background-color: #6BBA38;
      text-align: center;
      color: #fff;
      font-weight: normal;
      margin-right: 0.5rem; }
      .pager .pagination li:hover {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
        background-color: #89C860;
        color: rgba(255, 255, 255, 0.45); }
      .pager .pagination li.active {
        background: #000;
        font-weight: bold; }
        .pager .pagination li.active a {
          cursor: default; }
    .pager .pagination .blaetternindex {
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      line-height: 2.625rem; }
  .pager .paginatbutton {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 2.625rem;
    height: 2.625rem;
    border-radius: 50%;
    background-color: #6BBA38;
    text-align: center;
    color: #fff;
    font-weight: 900; }
    .pager .paginatbutton:hover {
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      background-color: #89C860;
      color: rgba(255, 255, 255, 0.45); }
    .pager .paginatbutton i::before {
      position: static;
      margin-right: 0; }
  .pager .blaetterntasten_rueckwaerts,
  .pager .blaetterntasten_vorwaerts,
  .pager .blaetterntasten_first {
    margin-right: 10px; }
  @media (min-width: 480px) {
    .pager .blaetterntasten_rueckwaerts i,
    .pager .blaetterntasten_vorwaerts i {
      display: none; } }
  @media (max-width: 479px) {
    .pager .blaetterntasten_rueckwaerts a,
    .pager .blaetterntasten_vorwaerts a {
      width: 2.625rem;
      height: 2.625rem;
      border-radius: 50%;
      padding: 0; }
      .pager .blaetterntasten_rueckwaerts a i,
      .pager .blaetterntasten_vorwaerts a i {
        margin-left: .25em; }
    .pager .blaetterntasten_rueckwaerts span,
    .pager .blaetterntasten_vorwaerts span {
      display: none; } }

/* body class and modal */
.modal-opened {
  overflow: hidden; }
  .modal-opened:before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: rgba(0, 0, 0, 0.6);
    content: ""; }
  .modal-opened .nav__button {
    display: block; }
  .modal-opened .nav__button {
    top: 0 !important;
    right: 7px !important; }
  .modal-opened .nav__button__icon span:first-child {
    transform: translateY(7px) rotate(-45deg); }
  .modal-opened .nav__button__icon span:nth-child(2) {
    opacity: 0; }
  .modal-opened .nav__button__icon span:last-child {
    transform: translateY(-7px) rotate(45deg); }

@media (max-width: 767px) {
  .nav__button {
    padding: 10px; }
  .modal-opened .nav__button {
    padding-top: 35px; } }

.modal {
  display: none; }
  .modal.modal-open {
    display: block; }

.modal-content {
  display: block;
  position: fixed;
  top: 10px;
  right: 0;
  left: 10px;
  max-height: 100%;
  z-index: 300;
  margin: 0;
  padding: 50px 0 0;
  text-align: left;
  background: #fff;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.18);
  overflow-y: auto; }
  .modal-content:before {
    position: absolute;
    top: 0;
    display: block;
    width: 100%;
    height: 6px;
    content: " ";
    background: url(../Images/par-layout-border-top@2x.png) left center;
    background-size: contain; }

/* search filter buttons */
.mobile-search-buttons {
  text-align: right; }
  .mobile-search-buttons > div {
    display: inline-block;
    cursor: pointer; }

/* search filter modals */
@media (min-width: 768px) {
  .column .mobile-search-buttons.mobile-search-buttons {
    display: none; } }
