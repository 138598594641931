.nav .searchform
{
	display: none;

	position: absolute;
	top: 13px;
	left: 270px;
	z-index: 200;

	@include media("<desktop") {
		max-width: 940px;
	}

	margin: 0 !important;
	padding-top: 15px !important;
	height: 48px;

	.title {
		font-weight: $font-weight-bold;
	}

	.details {
		.erweiterte-suche-fake-select-toggle,
		.erweiterte-suche-fake-select-content
		{
			font-size: .875rem;
		}

		label {
			padding-right: 0;
			padding-left: 0;
		}
	}

	.erweiterte-suche-fake-select-toggle {
		i {
			font-size: 1rem;
		}

		i.icon-Down_1 {
			font-size: .4rem;
		}

		&.active {
			font-weight: $font-weight-bold;
		}
	}

	.erweiterte-suche-fake-select {
		min-width: 25ex;

		&.open {
			box-shadow: $box-shadow-overlays;
		}

		&.kw-katkategorielist {
			min-width: 30ex;
		}
	}

	.erweiterte-suche-filter {
		border-top: none;
	}

	.kw-katexceptkennzlist {
		li {
			flex-direction: row;

			padding-left: 0;

			background-color: $color-white;

			&:hover {
				background-color: $color-white;
			}

			input {
				margin-right: .875rem;
			}
		}
	}
	.kw-katumkreisfilterlist {
		li {
			display: block;

			padding: 1.1764705882352942rem;
		}
	}

	.tooltip {
		display: none;
	}

	// where does this come from?
	#katexceptkennzfilter_1 {
		display: none;
	}

	&:not(.active)
	{
		width: 232px;

		left: 300px;

		div.sword
		{
			border-width: 1px 0 1px 1px;
		}

		div.submit
		{
			flex: 0 1 40px;

			@include borderRoundedRight();

			border: 1px solid $color-lighter-gray;
			border-left: none;

			button
			{
				background-color: $color-white;
				color: $color-green;
			}
			span
			{
				display: none;
			}
		}
		.details {
			display: none;
		}
	}
}

@include media("<=tabletExtraWide")
{
	.nav__search.searchform,
	nav .searchform
	{
		display: none !important;
	}
}
